import ContactTask from '../interfaces/ContactTask'
import ContactTaskGrouping from '../interfaces/ContactTaskGrouping'
import { MutationTree } from 'vuex'
import Task from '../interfaces/Task'
import { TaskState } from './types'
import TaskType from '../interfaces/TaskType'

export const mutations: MutationTree<TaskState> = {
  setContactTasks(state, tasks: ContactTaskGrouping) {
    state.contactTasks = tasks
  },
  setCurrentTask(state, task: Task) {
    state.currentTask = task
  },

  resetContactTasks(state) {
    state.contactTasks = null
  },
  setTasks(state, tasks: Task[]) {
    state.tasks = tasks
  },
  setTaskTypes(state, taskTypes: TaskType[]) {
    state.taskTypes = taskTypes
  },
}
