import { ActionTree } from 'vuex'
import SettingsService from '@/Settings/services/SettingsService'
import { SettingsState } from './types'
import { RootState } from '@/Common/store/types'

export const actions: ActionTree<SettingsState, RootState> = {
  reset({ commit }): any {
    commit('resetSettings')
  },
  setTheme({ getters }) {
    const root = document.documentElement

    root.style.setProperty('--activity-bkg-url', `url('/img/bkg--activity.jpg')`)
    root.style.setProperty('--v-error-base', '#dc143c')

    if (getters.themeSettings.buttons) {
      root.style.setProperty('--v-primary-base', getters.theme.buttons.primary.backgroundColor)
      root.style.setProperty('--v-primary-btn-txt-color', getters.theme.buttons.primary.color)
      root.style.setProperty('--v-secondary-base', getters.theme.buttons.secondary.backgroundColor)
      root.style.setProperty('--v-secondary-btn-txt-color', getters.theme.buttons.secondary.color)
    }

    if (getters.theme.footer) {
      root.style.setProperty('--footer-bkg-color', `${getters.theme.footer.backgroundColor}`)
      root.style.setProperty('--footer-bkg-url', `url('${getters.theme.footer.footerBkgImageUrl}')`)
      root.style.setProperty('--footer-bkg-opacity', `${getters.theme.footer.footerBkgImageOpacity}`)
      root.style.setProperty('--footer-border-color', `${getters.theme.footer.borderColor}`)
      root.style.setProperty('--footer-text-color', `${getters.theme.footer.textColor}`)
    }

    if (getters.theme.sidebar) {
      root.style.setProperty('--sidebar-bkg-color', `${getters.theme.sidebar.backgroundColor}`)
      root.style.setProperty('--sidebar-bkg-url', `url('${getters.theme.sidebar.sidebarBkgImageUrl}')`)
      root.style.setProperty('--sidebar-bkg-opacity', `${getters.theme.sidebar.sidebarBkgImageOpacity}`)
    }

    if (getters.theme.general) {
      root.style.setProperty('--logo-url', `url('${getters.theme.general.logoUrl}')`)
      root.style.setProperty('--arrivalTitleImageWidth', `${getters.theme.general.arrivalTitleImageWidth}px`)
      root.style.setProperty('--loader-primary-color', `${getters.theme.general.loaderPrimaryColor}`)
      root.style.setProperty('--loader-secondary-color', `${getters.theme.general.loaderSecondaryColor}`)
    }
  },
}
