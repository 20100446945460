import { MutationTree } from 'vuex'
import AdminEmailAccess from '../interfaces/AdminEmailAccess'
import AdminSetting from '../interfaces/AdminSetting'
import { AdminState } from './types'

export const mutations: MutationTree<AdminState> = {
  addAdminSettings(state, settings: AdminSetting) {
    state.adminSettings = settings
  },
  setAdminSettings(state, setting: AdminSetting) {
    state.adminSettings = setting
  },
  setAdminEmailAccess(state, emailAccess: AdminEmailAccess[]) {
    state.emailAccess = emailAccess
  },
}
