<template>
  <div id="accessDenied">
    <v-container max-width="600">
      <v-row>
        <v-spacer />
        <v-col md="8">
          <img src="/img/access-denied.svg" alt="Access Denied" />
          <h1>You do not have access to this resource</h1>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AccessDenied extends Vue {}
</script>
<style lang="scss" scoped>
#accessDenied {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
h1 {
  color: white;
  text-align: center;
}
img {
  margin: 3rem 0;
}
</style>
