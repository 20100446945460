import Activity from '../interfaces/Activity'
import type ActivityContact from '../interfaces/ActivityContact'
import ActivityProduct from '../interfaces/ActivityProduct'
import ActivityTask from '../interfaces/ActivityTask'
import ArrivalService from '@/Common/services/ArrivalService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import Task from '../../Task/interfaces/Task'
import store from '@/Common/store'

class ActivityService extends ArrivalService {
  public static async FetchActivities(): Promise<Activity[] | any> {
    let response: Activity[] | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async FetchActivityContacts(locationId: number): Promise<ActivityContact[] | any> {
    let response: ActivityContact[] | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/locations/${locationId}/contacts`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async AddActivity(activity: Activity): Promise<Activity | any> {
    let response: Activity | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'POST',
        data: activity,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async UpdateActivity(activity: Activity): Promise<Activity | any> {
    let response: Activity | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activity.id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'PUT',
        data: activity,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async AddActivityProduct(activityProduct: ActivityProduct): Promise<GenericResponse | any> {
    let response: GenericResponse | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityProduct.activityId}/products`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'POST',
        data: activityProduct,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async FetchActivityTasks(activityId: number): Promise<Task[] | any> {
    let response: Task[] | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityId}/tasks`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  // tslint:disable-next-line:max-line-length
  public static async RemoveActivityProduct(
    activityId: number,
    activityProductId: number
  ): Promise<GenericResponse | any> {
    let response: GenericResponse | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityId}/products/${activityProductId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'DELETE',
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async RemoveActivity(activityId: number): Promise<GenericResponse | any> {
    let response: GenericResponse | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'DELETE',
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async AddActivityTask(activityTask: ActivityTask): Promise<GenericResponse | any> {
    let response: GenericResponse | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityTask.activityId}/tasks`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'POST',
        data: activityTask,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async RemoveActivityTask(activityId: number, activityTaskId): Promise<GenericResponse | any> {
    let response: GenericResponse | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/activities/${activityId}/tasks/${activityTaskId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        method: 'DELETE',
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }
}

export default ActivityService
