import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const options = {
  theme: {
    options: {
      customProperties: true,
      cspNonce: 'dQw4w9WgXcQ',
      variations: false,
    },
  },
}

export default new Vuetify(options)
