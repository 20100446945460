import store from '@/Common/store'
import { Settings } from '../interfaces/Settings'
import ArrivalService from '@/Common/services/ArrivalService'

class SettingsService extends ArrivalService {
  public static async FetchSettings(): Promise<Settings | any> {
    let response: Settings | any
    try {
      const { data, status } = await this.http({
        url: `/api/settings/client`,
      })

      if (data && status === 200) {
        response = data
      }

      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static ApplyTheme(themeSettings) {
    const root = document.documentElement
    const theme = themeSettings.theme

    root.style.setProperty('--activity-bkg-url', `url('/img/bkg--activity.jpg')`)
    root.style.setProperty('--v-error-base', '#dc143c')

    if (theme.buttons) {
      root.style.setProperty('--v-primary-base', theme.buttons.primary.backgroundColor)
      root.style.setProperty('--v-primary-btn-txt-color', theme.buttons.primary.color)
      root.style.setProperty('--v-secondary-base', theme.buttons.secondary.backgroundColor)
      root.style.setProperty('--v-secondary-btn-txt-color', theme.buttons.secondary.color)
    }

    if (theme.footer) {
      root.style.setProperty('--footer-bkg-color', `${theme.footer.backgroundColor}`)
      root.style.setProperty('--footer-bkg-url', `url('${theme.footer.footerBkgImageUrl}')`)
      root.style.setProperty('--footer-bkg-opacity', `${theme.footer.footerBkgImageOpacity}`)
      root.style.setProperty('--footer-border-color', `${theme.footer.borderColor}`)
      root.style.setProperty('--footer-text-color', `${theme.footer.textColor}`)
    }

    if (theme.sidebar) {
      root.style.setProperty('--sidebar-bkg-color', `${theme.sidebar.backgroundColor}`)
      root.style.setProperty('--sidebar-bkg-url', `url('${theme.sidebar.sidebarBkgImageUrl}')`)
      root.style.setProperty('--sidebar-bkg-opacity', `${theme.sidebar.sidebarBkgImageOpacity}`)
    }

    if (theme.general) {
      root.style.setProperty('--logo-url', `url('${theme.general.logoUrl}')`)
      root.style.setProperty('--arrivalTitleImageWidth', `${theme.general.arrivalTitleImageWidth}px`)
      root.style.setProperty('--loader-primary-color', `${theme.general.loaderPrimaryColor}`)
      root.style.setProperty('--loader-secondary-color', `${theme.general.loaderSecondaryColor}`)
    }
  }

  public static ApplyCookieConsent(scripts, styles){
    const head = document.head
    const metaTag = head.getElementsByTagName('meta');
    const tagToAdd = metaTag[metaTag.length -1];
    if(styles && styles != ""){   
      const style = document.createElement('link');
      style.setAttribute('href',styles)
      style.type = 'text/css';
      style.rel = 'stylesheet';
      head.insertBefore(style, tagToAdd);
    }
    if(scripts && scripts != ""){
      const script = document.createElement('script');
      script.setAttribute('src',scripts)
      script.type='application/javascript';
      head.insertBefore(script, tagToAdd);
    }
    
   
    

  }
}



export default SettingsService
