import ArrivalService from '@/Common/services/ArrivalService'
import CheckPassRequest from '../interfaces/CheckPassRequest'
import Contact from '@/Common/interfaces/Contact'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import GetBLEDataRequest from '../interfaces/getBLEDataRequest'
import PassResult from '../interfaces/passResult'
import Profile from '../interfaces/profile'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import store from '@/Common/store'

class ProfileService extends ArrivalService {
  public static async FetchProfile(): Promise<Profile | any> {
    let response: Profile | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/profile`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async AddFamilyMember(request: Contact): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/family/addmember`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: request,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchAppleWalletPassFile(
    ipCode: string,
    firstName: string,
    lastName: string,
    externalOrderId: string | null
  ): Promise<any> {
    let response: any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/profile/passes/applewallet?ipCode=${ipCode}&firstName=${firstName}&lastName=${lastName}&externalOrderId=${externalOrderId}`,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async FetchGoogleWalletPassLink(
    ipCode: string,
    firstName: string,
    lastName: string,
    externalOrderId: string | null
  ): Promise<any> {
    let response: any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/profile/passes/googlewallet?ipCode=${ipCode}&firstName=${firstName}&lastName=${lastName}&externalOrderId=${externalOrderId}`,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }

  public static async CheckPass(request: CheckPassRequest): Promise<PassResult | any> {
    let response: PassResult | any
    try {
      const baseUrl = store.getters['settings/baseUrl']

      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/profile/passes/check`,
        data: request,
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }
}

export default ProfileService
