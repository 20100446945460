<template>
  <v-sheet id="photoTask" elevation="0" class="pa-4">
    <v-card class="contact-photo" :id="ct.contact.contactId" v-for="ct in contactTasks" :key="ct.contact.contactId">
      <v-card-title class="name">{{ ct.contact.firstName }} {{ ct.contact.lastName }}</v-card-title>
      <v-card-text class="contact-photo-content" :class="{ 'contact-photo-content__complete': ct.isComplete }">
        <div class="photo-wrapper">
          <slim-cropper
            :id="`slim_${ct.contact.contactId}`"
            :options="slimOptions(ct)"
            :contactTask="ct"
            :editPhotoHash="ct.contact.photoHash"
          />
        </div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn v-if="ct.isComplete" color="primary" @click="editPhoto(ct)" rounded outlined>
          Update Photo <v-icon title="Update Photo">mdi-camera-outline</v-icon>
        </v-btn>
        <v-btn v-else @click="editPhoto(ct)" color="primary" rounded>
          Add Photo <v-icon title="Add Photo">mdi-camera-plus-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import SlimCropper from './SlimCropper.vue'
import TaskBase from './TaskBase'

@Component({
  components: {
    SlimCropper,
  },
})
class PhotoTask extends TaskBase {
  // Store items
  @Action('trackEvent') trackEvent
  @Getter('oidcStore/oidcUser') user: any
  @Getter('task/currentTask') currentTask

  // Data Values
  public photoUploading: boolean = false
  private currentContactTask: ContactTask | null = null

  // Methods

  public slimOptions(contactTask) {
    return {
      ratio: '1:1',
      forceSize: '400,400',
      service: `/api/photo/Contact/Upload?contactId=${contactTask.contact.contactId}`,
      download: false,
      remove: false,
      upload: false,
      edit: false,
      instantEdit: true,
      push: true,
      didConfirm: this.onPhotoConfirmed,
      didUpload: this.onPhotoUpload,
      initialImage: ``,
      label: '',
    }
  }

  private addPhotoEvent() {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Add Photo',
      noninteraction: false,
      // TODO: Add a label/value attribute to indicate new or photo update
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  public onPhotoConfirmed() {
    this.photoUploading = true
  }

  public onPhotoUpload() {
    if (this.currentContactTask) {
      this.currentContactTask.isComplete = true
      this.addPhotoEvent()
      Vue.set(this.currentContactTask, 'isComplete', true)
    }
    this.photoUploading = false
  }

  private photoStyle(contact) {
    return `background-image: url(data:image/jpeg;base64,${contact.photo})`
  }

  private initials(contact) {
    let initials = ''
    initials += contact.firstName.charAt(0)
    initials += contact.lastName.charAt(0)
    return initials
  }

  private generateHash() {
    // This random hash value is used to trigger an refresh of the token when requested
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  private editPhoto(contactTask) {
    const newHash = this.generateHash()
    Vue.set(contactTask.contact, 'photoHash', newHash)
    this.currentContactTask = contactTask
  }
}

export default PhotoTask
</script>
<style lang="scss">
.photo-wrapper {
  pointer-events: none;
}

.v-card + .v-card {
  margin-top: 2rem;
}
.contact-photo-content {
  min-height: 300px;
  border: none;
  width: 100%;
  background: #999;
  background-image: url('@public/img/add-photo.svg');
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &__complete {
    background: #3a98d5;
  }
}
.photo-wrapper {
  width: 200px;
  height: 200px;
  position: relative;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  border: 1px solid transparent;

  input[type='file'] {
    visibility: hidden;
  }
}
</style>
