import BLEDevice from './BLEDevice'
import ETicketDeliveryMode from '../enums/ETicketDeliveryMode'

export default class ETicketDownload {
  constructor(
    // Mobile app version, ex. JHInsider app
    public appVersion: string | null,
    // Device which the BLE ticket was downloaded to
    public device: BLEDevice | null,
    // DateTime when the BLE ticket was downloaded to the Device
    public downloadDate: Date | null,
    // BLE ticket delivery mode, ex. email or sms
    public deliveryMode: ETicketDeliveryMode
  ) {}
}
