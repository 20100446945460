import axios, { AxiosStatic } from 'axios'
import store from '../store/index'

export default class ArrivalService {
  public static http: AxiosStatic = ArrivalService.setupAxios()

  static setupAxios(): AxiosStatic {
    // Create Axios Instance
    const axiosStatic = axios

    // Create interceptor for 401 requests
    axiosStatic.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
          // Get new refresh token
          // store.dispatch('authenticateOidcSilent')
          // if (store.getters['oidcStore/oidcIsAuthenticated']) {
          //   error.config.headers.Authorization = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`
          //   error.config.baseURL = store.getters['settings/baseUrl']
          //   return axiosStatic.request(error.config)
          // } else{
          //   // Redirect user to welcome page
          //   window.location.href = '/';
          // }
        }
        return Promise.reject(error)
      }
    )

    return axiosStatic
  }
}
