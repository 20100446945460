<template>
  <footer class="app-footer">
    <div class="logo"></div>
    <div class="content">
      <h2 v-text="language.application.title"></h2>
      <p v-text="language.application.subtitle"></p>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class AppFooter extends Vue {
  @Getter('settings/languageSettings') language
}
</script>

<style lang="scss" scoped>
$footer-padding: 14px;

footer {
  padding: 1rem;
  border: none;
  height: 100px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  transition: margin-top 150ms;
  background: var(--footer-border-color, black);

  @include breakpoint('tablet') {
    display: none;
  }

  .logo {
    height: calc(100px - 2 * #{$footer-padding});
    width: 120px;
    background: var(--logo-url, url('@public/img/logo-bigsky--white.png'));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;
    z-index: 100;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    color: var(--footer-text-color, '#FEFEFE');
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 4px;
    padding: $footer-padding;
    border-width: 0px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    > * {
      z-index: 100;
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      background-color: var(--footer-bkg-color, #000000);
      background-image: var(--footer-bkg-url, none);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: var(--footer-bkg-opacity, 0.25);
    }

    h2 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2;
      letter-spacing: 0.04em;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.2em;
      opacity: 0.65;
    }

    h2,
    p {
      max-width: 180px;
      text-align: right;
    }
  }
}
</style>
