const schema = {
  type: 'object',
  properties: {
    bleConnect: {
      type: 'object',
      title: 'BLE Connect',
      properties: {
        enableBleHomeScreen: {
          title: 'Show the BLE Connect button on the home screen',
          type: 'boolean',
          default: false,
          'x-display': 'switch',
        },
        desktopLogoImageUrl: {
          type: 'string',
          title: 'BLE Connect Page Logo (Desktop)',
          description: 'URL for the logo image on the BLE Connect page for non-mobile screen sizes',
        },
        mobileLogoImageUrl: {
          type: 'string',
          title: 'BLE Connect Page Logo (Mobile)',
          description: 'URL for the logo image on the BLE Connect page for mobile screen sizes',
        },
      },
    },
  },
}

export default schema
