<template>
  <v-card id="admin-language-settings" class="pa-2">
    <v-card-title class="pt-2">Theme</v-card-title>
    <v-card-text>
      <v-form v-model="valid" v-if="adminSettings">
        <v-jsf v-model="adminSettings.themeSettings" :schema="combinedSettingsSchema" :options="formOptions" />
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-4 align-start" v-if="adminSettings && adminSettings.themeSettings">
      <v-dialog v-model="confirmUpdate" persistent max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">Update Theme <v-icon>mdi-upload</v-icon></v-btn>
        </template>
        <v-card>
          <v-card-title class="headline"> Confirm Update Theme </v-card-title>
          <v-card-text>Are you sure you want to update your current theme settings?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmUpdate = false"> No </v-btn>
            <v-btn color="secondary" text @click="updateSettings"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer />
      <div class="d-flex flex-column justify-center">
        <v-btn @click="fetchDefaultSettings" :loading="fetching" large
          >Fetch Defaults <v-icon>mdi-cloud-download-outline</v-icon></v-btn
        >
        <v-checkbox v-model="overwriteValues" label="Overwrite current values"></v-checkbox>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'
import ThemeSetting from '../interfaces/ThemeSetting'
import ThemeSettingsSchema from '../schemas/themeSettings'
import BLESettingsSchema from '../schemas/bleThemeSettings'
import merge from 'lodash.merge'

@Component
export default class AdminTheme extends Vue {
  @Getter('admin/settings') settings
  @Action('admin/fetchAdminSettings')
  private fetchAdminSettings: any

  // Data Elements
  public adminSettings: AdminSetting | null = null

  public overwriteValues: boolean = false

  public confirmUpdate: boolean = false

  public valid: any = false
  public updating: boolean = false
  public fetching: boolean = false

  public formOptions: any = {
    rootDisplay: 'expansion-panels',
  }

  private schema: any = ThemeSettingsSchema
  private bleSchema: any = BLESettingsSchema

  get combinedSettingsSchema(): any {
    const hasBLEConnect = this.$store.getters.hasFeature('BLEConnect')

    if (hasBLEConnect) {
      const combinedSchema = {
        ...this.schema,
        properties: {
          theme: {
            properties: {
              ...this.schema.properties.theme.properties,
              ...this.bleSchema.properties,
            },
          },
        },
      }
      return combinedSchema
    }

    return this.schema
  }

  // Computed Elements
  get themeSettings() {
    if (this.adminSettings) {
      return this.adminSettings.themeSettings
    } else {
      return null
    }
  }

  // Life Cycle
  public async mounted() {
    if (!this.settings) {
      await this.getSettings()
    }
    this.adminSettings = {
      ...(this.settings as AdminSetting),
    }
    this.adminSettings = {
      ...(this.adminSettings as AdminSetting),
      themeSettings: JSON.parse(this.settings.themeSettings),
    }
  }

  public async fetchDefaultSettings() {
    const fetchHeaders = new Headers()
    fetchHeaders.append('pragma', 'no-cache')
    fetchHeaders.append('cache-control', 'no-cache')

    const fetchInit = {
      methood: 'GET',
      headers: fetchHeaders,
    }

    const fetchRequest = new Request('/defaults/default-theme.json')

    this.fetching = true
    const response = await fetch(fetchRequest, fetchInit)
    if (response.ok) {
      const defaultThemeSettings: ThemeSetting = await response.json()
      const currentThemeSettings = this.adminSettings?.themeSettings as {}
      const newThemeSettings = this.overwriteValues
        ? { ...currentThemeSettings, ...defaultThemeSettings }
        : merge(defaultThemeSettings, currentThemeSettings)
      this.adminSettings = {
        ...(this.adminSettings as AdminSetting),
        themeSettings: newThemeSettings,
      }
    }
    this.fetching = false
    this.overwriteValues = false
  }

  public async getSettings() {
    const settings = await this.fetchAdminSettings()
    const currentThemeSettings = settings.themeSettings ? JSON.parse(settings.themeSettings) : {}
    console.log('RETRIEVED:', currentThemeSettings)
    const newSettings = {
      ...settings,
      themeSettings: currentThemeSettings,
    }
    this.adminSettings = newSettings
  }

  public async updateSettings() {
    this.confirmUpdate = false
    if (this.valid && this.adminSettings) {
      this.updating = true
      const newThemeSettings = {
        ...this.adminSettings,
        themeSettings: JSON.stringify(this.adminSettings.themeSettings),
      }
      await AdminService.UpdateAdminSettings(newThemeSettings)
      this.getSettings()
      this.updating = false
    }
  }
}
</script>

<!--Scoped Styles to target elements in thi template-->
<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

<!--Unscoped Styles to target elements controlled by Vuetify-->
<style lang="scss">
.v-expansion-panel-header--active {
  background-color: #f2f2f2;
}

/*The div that holds thing like the color picker needs to have a higher z-ndex than anything else on the main page*/
.menuable__content__active {
  z-index: 200 !important;
}
</style>
