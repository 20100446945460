import Logout from './views/Logout.vue'
import OidcCallback from './views/OidcCallback.vue'
import OidcSilentRenew from './views/OidcSilentRenew.vue'
import store from '@/Common/store'
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

const oidcSettings = store.getters['settings/oidcSettings']
const routes: any[] = [
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      isPublic: true,
      gtm: 'Logout',
    },
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-callback-error',
    name: 'oidcCallbackError',
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidcSilentRenew',
    component: OidcSilentRenew,
    meta: {
      isPublic: true,
    },
  },
]

export default routes
