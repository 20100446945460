<template>
  <div id="admin-collections">
    <div id="collection-list" v-if="collections">
      <v-card class="pa-2">
        <v-card-title class="pt-2">Collections</v-card-title>
        <v-card-subtitle class="description">Click the box to view or edit a collection.</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="collectionsTableHeaders"
            :items="collections"
            :items-per-page="10"
            @item-selected="onCollectionSelected"
            show-select
            single-select
          ></v-data-table>
        </v-card-text>
      </v-card>
      <v-card id="collection-items" v-if="currentCollection" class="mt-8">
        <v-card-title class="pb-0">{{ currentCollection.name }} Collection</v-card-title>
        <v-card-text>
          <v-container :fluid="true" class="pa-0">
            <v-row>
              <v-col cols="12" md="6" class="pa-0">
                <v-card elevation="0" class="pa-0">
                  <v-card-text>
                    <v-form>
                      <v-jsf v-model="currentCollection" :schema="collectionSchema" />
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pt-2 pl-4 pr-4">
                    <v-btn color="primary" @click="updateCollection" :loading="updatingCollection">
                      Update Collection <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <v-card elevation="0" class="pa-0">
                  <v-card-title class="pb-0">Collection Items</v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="collectionItemsTableHeaders"
                      :items="currentCollection.collectionItems"
                      :items-per-page="10"
                      @item-selected="onCollectionItemSelected"
                      show-select
                      single-select
                    ></v-data-table>
                  </v-card-text>
                </v-card>
                <v-card class="pa-2 mt-8" v-if="!currentCollectionItem">
                  <v-card-title class="pt-2">Add New Collection Item</v-card-title>
                  <v-card-subtitle>Complete the following information to add a new collection.</v-card-subtitle>
                  <v-card-text>
                    <v-form ref="newCollectionForm">
                      <v-jsf
                        v-model="newCollectionItem"
                        :schema="collectionItemSchema"
                        :options="newCollectionOptions"
                      />
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="pa-4">
                    <v-btn @click="addCollectionItem" color="primary"
                      >Add Collection Item<v-icon>mdi-upload</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-card>
                <v-card class="pa-2 mt-8" v-if="currentCollectionItem">
                  <v-card-title class="pt-2">Update Collection Item</v-card-title>
                  <v-card-subtitle>Complete the following information to add a new collection.</v-card-subtitle>
                  <v-card-text>
                    <v-form ref="editCollectionItemForm">
                      <v-jsf
                        v-model="currentCollectionItem"
                        :schema="collectionItemSchema"
                        :options="newCollectionOptions"
                      />
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="pa-4">
                    <v-btn @click="updateCollectionItem" color="primary"
                      >Update Collection Item<v-icon>mdi-upload</v-icon></v-btn
                    >
                    <v-btn color="error" @click="removeCollectionItem" :loading="deletingCollectionItem"
                      >Delete Collection Item<v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card class="pa-2 mt-8" v-if="!currentCollection">
      <v-card-title class="pt-2">Add New Collection</v-card-title>
      <v-card-subtitle>Complete the following information to add a new collection.</v-card-subtitle>
      <v-card-text>
        <v-form ref="newCollectionForm">
          <v-jsf v-model="newCollection" :schema="collectionSchema" :options="newCollectionOptions" />
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn @click="addCollection" color="primary" large>Add Collection <v-icon>mdi-upload</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import ActivityProduct from '../../Activity/interfaces/ActivityProduct'
import ActivityTask from '../../Activity/interfaces/ActivityTask'
import ActivityService from '../../Activity/services/ActivityService'
import { activity } from '../../Activity/store'
import Collection from '@/Common/interfaces/Collection'
import CollectionItem from '@/Common/interfaces/CollectionItem'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'
import CollectionService from '@/Common/services/CollectionService'
import Task from '../../Task/interfaces/Task'

@Component
export default class AdminCollections extends Vue {
  private collections: Collection[] = []
  private currentCollection: Collection | null = null
  private currentCollectionItem: CollectionItem | null = null
  private addingCollection: boolean = false
  private updatingCollection: boolean = false
  private deletingCollection: boolean = false
  private addingCollectionItem: boolean = false

  $refs: {
    newCollectionForm: HTMLFormElement
  }

  onCollectionSelected({ item: selectedCollection, value: selected }) {
    if (selectedCollection && selected) {
      const editCollection = {
        ...selectedCollection,
      }
      Vue.nextTick(() => {
        this.currentCollection = editCollection
      })
    } else {
      this.currentCollection = null
    }
  }

  onCollectionItemSelected({ item: selectedCollectionItem, value: selected }) {
    if (selectedCollectionItem && selected) {
      const editCollectionItem = {
        ...selectedCollectionItem,
      }
      Vue.nextTick(() => {
        this.currentCollectionItem = editCollectionItem
      })
    } else {
      this.currentCollectionItem = null
    }
  }

  private newCollectionOptions = {
    initialValidation: 'none',
  }

  private collectionSchema = {
    required: ['name'],
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
        default: '',
      },
      collectionItems: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
        items: {},
      },
    },
  }

  private collectionItemSchema = {
    properties: {
      id: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
        default: '',
      },
      value: {
        title: 'Value',
        type: 'string',
        default: '',
      },
    },
  }

  public newCollection: Collection = {
    id: 0,
    name: '',
    collectionItems: [],
    createdDateUtc: new Date(),
  }

  public clearedCollection = { ...this.newCollection }
  public newCollectionItem: CollectionItem = {
    id: 0,
    value: '',
    createdDateUtc: new Date(),
    name: '',
    collectionId: 0,
  }

  public async created() {
    await this.getCollections()
  }

  public async getCollections() {
    this.collections = await CollectionService.FetchCollections()

    const hasPassChecker = this.$store.getters.hasFeature('PassChecker')

    if (!hasPassChecker) {
      this.collections = this.collections.filter((c) => c.id > 2)
    }

    if (this.currentCollection && this.collections) {
      const thisCollection = this.collections.find((l: Collection) => l.id == this.currentCollection!.id)
      if (thisCollection) {
        this.currentCollection = thisCollection
      }
    }
  }

  public viewCollectionDetails(collection) {
    this.currentCollection = collection
  }

  private async addCollection() {
    this.addingCollection = true

    this.$refs.newCollectionForm.validate()
    await CollectionService.AddCollection(this.newCollection)
    await this.getCollections()
    this.newCollection = { ...this.clearedCollection }
    this.$refs.newCollectionForm.reset()
    this.addingCollection = false
  }

  private async updateCollection() {
    if (this.currentCollection) {
      this.updatingCollection = true
      await CollectionService.UpdateCollection(this.currentCollection)
      await this.getCollections()
      this.updatingCollection = false
    }
  }

  private async removeCollection() {
    if (this.currentCollection) {
      this.deletingCollection = true
      await CollectionService.DeleteCollection(this.currentCollection.id)
      await this.getCollections()
      this.deletingCollection = false
      this.currentCollection = null
    }
  }

  private async removeCollectionItem() {
    await CollectionService.DeleteCollectionItem(this.currentCollectionItem!.id)
    this.currentCollectionItem = null
    this.getCollections()
  }

  private async addCollectionItem() {
    if (this.currentCollection) {
      this.addingCollectionItem = true
      this.newCollectionItem.collectionId = this.currentCollection.id
      await CollectionService.AddCollectionItem(this.newCollectionItem)
      await this.getCollections()
      this.addingCollectionItem = false
    }
  }

  private async updateCollectionItem() {
    if (this.currentCollection && this.currentCollectionItem) {
      await CollectionService.UpdateCollectionItem(this.currentCollectionItem)
      this.currentCollectionItem = null
      await this.getCollections()
    }
  }

  get collectionsTableHeaders() {
    return [{ text: 'Name', value: 'name' }]
  }

  get collectionItemsTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: 'Value', value: 'value' },
    ]
  }
}
</script>
<style lang="scss" scoped>
.activity-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 100px;
  border: 1px solid white;
}
.v-btn + .v-btn {
  margin-left: 2rem;
}
.status {
  display: flex;
  justify-content: center;
}
.link-task-btn {
  margin-left: 1rem;
}
.link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .v-input {
    flex-basis: 75%;
  }
}
</style>
