<template>
  <div id="admin-tasks">
    <div v-if="taskTypes" id="admin-task-type">
      <v-card class="pa-2">
        <v-card-title class="pt-2">Task Type List</v-card-title>
        <v-card-subtitle class="description">Click the box to view or edit Task Type details.</v-card-subtitle>
        <v-card-text>
          <v-data-table
            v-if="taskTypes"
            :headers="taskTypesTableHeaders"
            :items="taskTypes"
            v-model="selectedTaskTypes"
            hide-default-footer
            show-select
            single-select
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-card id="task-type-details" v-if="currentTaskType" class="mt-8">
      <v-card-title class="pt-2 pb-0">{{ currentTaskType.name }}</v-card-title>
      <v-card-text>
        <v-form>
          <v-jsf v-model="currentTaskType" :schema="taskTypeSchema" />
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-4 pb-4 pl-4 pt-0">
        <v-btn color="primary" @click="updateTaskType" large>Update Task Type <v-icon>mdi-upload</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Task from '../../Task/interfaces/Task'
import TaskService from '../../Task/services/TaskService'
import TaskType from '../../Task/interfaces/TaskType'

@Component
export default class AdminTaskTypes extends Vue {
  @Action('task/fetchTaskTypes')
  private fetchTaskTypes: any
  @Getter('task/taskTypes')
  private taskTypes?: []

  // Life Cycle
  public created() {
    if (!this.taskTypes || this.taskTypes.length == 0) {
      this.getTaskTypes()
    }
  }

  // Data Elements
  private selectedTaskTypes = [];

  private taskTypeSchema = {
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      isDeleted: {
        type: 'boolean',
        readOnly: true,
        'x-display': 'hidden',
      },
      enableShoeSize: {
        title: 'Enable Shoe Size',
        type: 'boolean',
        'x-display': 'switch',
      },
      enableStance: {
        title: 'Enable Stance',
        type: 'boolean',
        'x-display': 'switch',
      },
      effectiveDays: {
        title: 'Effective Days',
        type: 'number',
      },
    },
  }

  // Computed Values
  get currentTaskType() {
    return this.selectedTaskTypes.length > 0 ? this.selectedTaskTypes[0] : null;
  }
  get taskTypesTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: '', value: '' },
    ]
  }

  // Methods
  public getTaskTypes() {
    this.fetchTaskTypes()
  }

  public async updateTaskType() {
    if (this.currentTaskType) {
      await TaskService.UpdateTaskType(this.currentTaskType)
      this.getTaskTypes()
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-row {
  background-color: khaki !important;
}

h1 {
  font-size: 16pt;
}

.add-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 12pt;
  font-weight: bold;
  color: white;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-color: #4286ab;
  border-color: #4286ab;
  margin-right: 10px;
}

.delete-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 12pt;
  font-weight: bold;
  color: white;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-color: red;
  border-color: red;
  margin-right: 10px;
}

.float-right {
  float: right;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

#activity-list {
  border-right: 4px solid navy;
}

.red {
  color: red;
}

.btn {
  cursor: pointer;
}

.float-container {
  border: 3px solid #fff;
  padding: 20px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

table thead {
  background-color: #4286ab;
  color: #fff;
  font-size: 14pt;
}

table th {
  border: 2px solid #ddd;
  padding: 14px 12px;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 5;
}

table tbody td {
  border: 2px solid #ddd;
  padding: 15px 12px;
  font-size: 18px;
}

table tr:nth-child(even) {
  background-color: rgba(237, 237, 237, 0.5);
}

table tbody td a {
  display: block;
  padding: 10px;
  /*IMPORTANT: padding just for a
    so we fill all the A:link cell
    (delete the padding for td)*/
  text-decoration: none;
  color: #fff;
}

table tbody td a:hover {
  background-color: aqua;
}

* {
  box-sizing: border-box;
}

input[type='text'],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type='submit'] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type='submit']:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type='submit'] {
    width: 100%;
    margin-top: 0;
  }
}
</style>
