<template>
  <div id="child-registration-contact-task" v-if="!isHidden && newChildRegProfile">
    <v-card v-if="isReadOnly">
      <v-card-title> {{ contactTask.contact.firstName }} {{ contactTask.contact.lastName }} </v-card-title>
      <v-card-text v-if="!hasProfile" class="readonly-body incomplete-body">
        <div class="readonly-item readonly-item-text">Not Completed</div>
        <div class="button-wrapper">
          <v-btn @click="edit" color="primary" rounded>Update</v-btn>
        </div>
      </v-card-text>
      <v-card-text class="readonly-body" v-else>
        <div class="readonly-item">
          <span class="readonly-item-title">Primary Contact</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="person" color="#517994" />
            </div>
            <span>{{ childRegProfile.primaryEmergencyContact }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Primary Phone #</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="phone" color="#517994" />
            </div>
            <span>{{ childRegProfile.primaryEmergencyPhone }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Alternate Contact</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="person" color="#517994" />
            </div>
            <span>{{ childRegProfile.alternateEmergencyContact1 }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Secondary Phone #</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="phone" color="#517994" />
            </div>
            <span>{{ childRegProfile.alternateEmergencyPhone1 }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Drug Allergy</span>
          <div class="readonly-item-text">
            <span>{{ childRegProfile.drugAllergy }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Food Allergy</span>
          <div class="readonly-item-text">
            <span>{{ childRegProfile.foodAllergy }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Medication</span>
          <div class="readonly-item-text">
            <span>{{ childRegProfile.medication }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Special Condition</span>
          <div class="readonly-item-text">
            <span>{{ childRegProfile.specialCondition }}</span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn button-type="secondary" @click="edit" v-if="hasProfile" rounded outlined>Update</v-btn>
        <v-btn button-type="secondary" @click="edit" v-else>Create</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="child-registration-form" rounded>
      <v-card-title id="contact-information">
        {{ contactTask.contact.firstName }} {{ contactTask.contact.lastName }}
      </v-card-title>
      <v-card-text>
        <v-form ref="childRegistrationProfile">
          <!-- Primary Emergency Contact -->
          <!-- prettier-ignore-attribute -->
          <v-text-field
            type="text"
            label="Primary Emergency Contact"
            placeholder="Primary Emergency Contact"
            v-model="newChildRegProfile.primaryEmergencyContact"
            :error-messages="getErrorMessages($v.newChildRegProfile.primaryEmergencyContact, 'Primary contact name is required')"
            outlined
            required
            v-focus
          />

          <!-- Primary Emergency Contact Phone -->
          <div class="v-tel-input">
            <label>Primary Emergency Contact Phone</label>
            <vue-tel-input
              v-model="newChildRegProfile.primaryEmergencyPhone"
              :dynamic-placeholder="true"
              :enabled-country-code="false"
              :enabled-flags="false"
              :dropdown-options="telephoneDropdownOptions"
              :input-options="telephoneInputOptions"
              :class="{ 'error--text': $v.newChildRegProfile.primaryEmergencyPhone.$error }"
            ></vue-tel-input>
            <div class="v-text-field__details">
              <v-messages
                color="error"
                :value="$v.newChildRegProfile.primaryEmergencyPhone.$error ? ['Primary contact phone required'] : []"
              >
              </v-messages>
            </div>
          </div>

          <!-- Primary Emergency Contact -->
          <!-- prettier-ignore-attribute -->
          <v-text-field
            type="text"
            label="Alternate Emergency Contact"
            placeholder="Alternate Emergency Contact"
            v-model="newChildRegProfile.alternateEmergencyContact1"
            :error-messages="getErrorMessages($v.newChildRegProfile.alternateEmergencyContact1, 'Alternate contact name is required')"
            outlined
            required
            v-focus
          />

          <!-- Alternate Emergency Contact Phone -->
          <div class="v-tel-input">
            <label>Alternate Emergency Contact Phone</label>
            <vue-tel-input
              v-model="newChildRegProfile.alternateEmergencyPhone1"
              :dynamic-placeholder="true"
              :enabled-country-code="false"
              :enabled-flags="false"
              :dropdown-options="telephoneDropdownOptions"
              :input-options="telephoneInputOptions"
              :class="{ 'error--text': $v.newChildRegProfile.alternateEmergencyPhone1.$error }"
            ></vue-tel-input>
            <div class="v-text-field__details">
              <v-messages
                color="error"
                :value="
                  $v.newChildRegProfile.alternateEmergencyPhone1.$error ? ['Alternate contact phone required'] : []
                "
              >
              </v-messages>
            </div>
          </div>

          <!-- Drug Allergy -->
          <label>Drug Allergy</label>
          <v-textarea
            v-model="newChildRegProfile.drugAllergy"
            @focus="clearNone('drugAllergy')"
            @blur="addNone('drugAllergy')"
            outlined
          ></v-textarea>

          <!-- Food Allergy -->
          <label>Food Allergy</label>
          <v-textarea
            v-model="newChildRegProfile.foodAllergy"
            @focus="clearNone('foodAllergy')"
            @blur="addNone('foodAllergy')"
            outlined
          ></v-textarea>

          <!-- Medication -->
          <label>Medication</label>
          <v-textarea
            v-model="newChildRegProfile.medication"
            @focus="clearNone('medication')"
            @blur="addNone('medication')"
            outlined
          ></v-textarea>

          <!-- Special Condition -->
          <label>Special Condition</label>
          <v-textarea
            type="text"
            v-model="newChildRegProfile.specialCondition"
            @focus="clearNone('specialCondition')"
            @blur="addNone('specialCondition')"
            outlined
          ></v-textarea>
        </v-form>
        <p class="error--text" v-if="this.$v.$error">Missing child registration information. See above.</p>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn @click="cancel" v-if="showSkipButton || showCancelButton" rounded outlined>{{ cancelLabel }}</v-btn>
        <v-spacer />
        <v-btn
          @click="completeRegistration"
          color="secondary"
          :disabled="isUpdating"
          :show-loader="isUpdating"
          :class="{ 'only-button': !showCancelButton && !showSkipButton }"
          :block="!showSkipButton && !showCancelButton"
          rounded
          >{{ updateLabel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import ContactTaskBase from './ContactTaskBase'
import ContactTask from '../interfaces/ContactTask'
import TaskService from '../services/TaskService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import Contact from '@/Common/interfaces/Contact'
import ChildRegProfile from '../models/ChildRegProfile'
import ChildRegProfileInterface from '../interfaces/ChildRegProfile'
import Icon from '@/Common/components/Icon.vue'
import { required } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'

@Component({
  components: {
    Icon,
    VueTelInput,
  },
})
class ChildRegistrationForm extends ContactTaskBase {
  @Prop() childRegProfile: ChildRegProfileInterface | null
  @Prop() copyFromContactTask: ContactTask | null
  @Prop({ required: true, default: false }) isHidden: boolean
  @Prop({ required: true, default: false }) isReadOnly: boolean
  @Prop({ required: true, default: false }) isUpdating: boolean

  @Getter('oidcStore/oidcUser') user

  // Data Values
  private newChildRegProfile: ChildRegProfile | null = null
  private defaultTextValue = 'None'

  public created() {
    const initialChildRegProfile = {
      ...this.childRegProfile,
      primaryEmergencyContact: `${this.user.given_name} ${this.user.family_name}`,
    }
    this.newChildRegProfile = new ChildRegProfile(initialChildRegProfile)
  }

  validations() {
    return {
      newChildRegProfile: {
        primaryEmergencyContact: { required },
        primaryEmergencyPhone: { required },
        alternateEmergencyContact1: { required },
        alternateEmergencyPhone1: { required },
      },
    }
  }

  get hasProfile() {
    return this.childRegProfile && this.childRegProfile.primaryEmergencyContact
  }

  get telephoneDropdownOptions() {
    return { disabledDialCode: false, tabindex: -1 }
  }

  get telephoneInputOptions() {
    return { showDialCode: false, tabindex: 0 }
  }

  // Computed Values
  get showSkipButton() {
    if (!this.hasProfile && this.contactTask.isOptional) {
      return true
    }
    return false
  }

  get showCancelButton() {
    return this.hasProfile
  }

  get cancelLabel() {
    return this.showSkipButton ? 'Skip' : 'Cancel'
  }

  get updateLabel() {
    return !this.showCancelButton && !this.showSkipButton ? 'Continue' : 'Update'
  }

  // Methods
  @Emit('onCompleted')
  async completeRegistration() {
    if (!this.newChildRegProfile!.drugAllergy) {
      this.newChildRegProfile!.drugAllergy = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.foodAllergy) {
      this.newChildRegProfile!.foodAllergy = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.medication) {
      this.newChildRegProfile!.medication = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.specialCondition) {
      this.newChildRegProfile!.specialCondition = this.defaultTextValue
    }

    this.$v!.$touch()

    // No Errors
    if (!this.$v!.$error) {
      // Update the related object with the information on the form
      if (this.contactTask) {
        const newRelatedObject = {
          ...this.contactTask.relatedObject,
          ...this.newChildRegProfile,
        }
        this.contactTask.relatedObject = newRelatedObject

        return this.contactTask
      }
    }

    return null
  }

  @Emit('onSkipped')
  async skip() {
    this.newChildRegProfile = new ChildRegProfile(this.childRegProfile)
    return this.contactTask
  }

  @Emit('onEdit')
  async edit() {
    return this.contactTask
  }

  @Emit('onCancelEdit')
  async cancel() {
    this.newChildRegProfile = new ChildRegProfile(this.childRegProfile)
    return this.contactTask
  }

  private getErrorMessages(vuelidateObject, message) {
    return vuelidateObject.$error ? [`${message}`] : []
  }

  private clearNone(key) {
    if (!this.newChildRegProfile![key] || this.newChildRegProfile![key].toUpperCase() === 'NONE') {
      Vue.set(this.newChildRegProfile!, key, '')
    }
  }

  private addNone(key) {
    if (!this.newChildRegProfile![key]) {
      Vue.set(this.newChildRegProfile!, key, 'None')
    }
  }

  private dataChanged() {
    // If any of the data on the screen was populated, then
    if (
      this.newChildRegProfile!.primaryEmergencyContact ||
      this.newChildRegProfile!.primaryEmergencyPhone ||
      this.newChildRegProfile!.alternateEmergencyContact1 ||
      this.newChildRegProfile!.alternateEmergencyPhone1 ||
      this.newChildRegProfile!.drugAllergy ||
      this.newChildRegProfile!.foodAllergy ||
      this.newChildRegProfile!.medication ||
      this.newChildRegProfile!.specialCondition
    ) {
      return true
    }
    return false
  }
}

export default ChildRegistrationForm
</script>
<style lang="scss" scoped>
#child-registration-contact-task {
  background: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0;
  margin: 1rem 0;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.child-registration-form {
  padding: 1rem;
}

label {
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.5rem;
  display: block;
  font-size: 0.875rem;
}

input,
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 3px;
  border: 1px solid #6a6a6a;
  margin-bottom: 1rem;
}

.vue-tel-input {
  font-size: 1rem;
  padding: 0.25rem 0;
  border: 1px solid #6a6a6a;
  margin-bottom: 8px;
  border-radius: 3px;
}

textarea {
  height: 5em;
}

input,
select,
textarea,
.vue-tel-input {
  &.error--text {
    border-color: red;
    border-width: 2px;
  }
}

.error-msg {
  font-size: 0.75em;
  color: red;
  text-transform: uppercase;
}

.v-tel-input {
  margin-bottom: 12px;
  .v-text-field__details {
    margin-bottom: 8px;
    padding: 0 12px;
  }
  .v-text-field__message {
    color: red;
  }
}

input,
textarea + label {
  margin-top: 0.5rem;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0 0;
}

.ax--btn {
  &.only-button {
    width: 100%;
    justify-content: center;
  }

  &.ax--btn--secondary {
    border-radius: 50px;
  }
}

.readonly-item > span {
  display: block;
}

.readonly-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .contact-name {
    font-size: 1.125rem;
  }

  .ax--btn {
    text-transform: uppercase;
    font-size: 0.75rem;
    height: 32px;
    line-height: 32px;
    &:hover {
      background: rgba(81, 121, 148, 0.1);
    }
  }
}

.readonly-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0.15) 45.6%);
  .contact-name {
    font-size: 1.125rem;
  }
}

.readonly-body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  width: 100%;
  padding: 1rem 0.8rem;
  box-shadow: 0px -1px 0px #e8e8ec;

  @include breakpoint('tablet') {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }

  .button-wrapper {
    position: static;
    margin: 1rem 0 0 0;
  }

  .ax--btn {
    text-transform: uppercase;
    font-size: 0.75rem;
    height: 32px;
    line-height: 32px;
    padding: 0 1.25em;
    &:hover {
      background: rgba(81, 121, 148, 0.1);
    }
  }
}

.incomplete-body {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-wrapper {
    position: static;
    margin: 0;
  }

  .readonly-item-text {
    font-size: 1.2rem;
    color: crimson;
    margin: 0;
  }
}

.readonly-item {
  margin: 0 5px 15px 0;
  &-title {
    font-size: 0.75rem;
    opacity: 0.65;
    margin-bottom: 0.5em;
  }

  &-text {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon {
      height: 20px;
      width: 20px;
      flex-basis: 26px;
    }

    span {
      flex: 1;
    }
  }
}
</style>
