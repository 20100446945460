import ContactBLEModel from '../models/ContactBLE'
import ContactModel from '../models/Contact'
import { GetterTree } from 'vuex'
import { ProfileState } from './types'
import { RootState } from '@/Common/store/types'

export const getters: GetterTree<ProfileState, RootState> = {
  contacts(state): ContactModel[] | null {
    return state.contacts
  },
  bleContacts(state): ContactBLEModel[] | null {
    return state.bleContacts
  },
  signedIn(state): boolean {
    return true
  },
  headOfHouseHoldContact(state): ContactModel | null {
    const headOfHouseHold = (state.contacts && state.contacts.filter((c) => c.isHeadOfHouseHold)[0]) || null
    if (headOfHouseHold) {
      return headOfHouseHold
    }
    return null
  },
}
