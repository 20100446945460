import { TaskState } from './types'

export const state: TaskState = {
  contactTasks: null,
  currentTask: null,
  tasks: null,
  taskTypes: null,
  taskTypeKeys: [
    {
      taskTypeId: 1,
      routeName: 'Waiver',
    },
    {
      taskTypeId: 2,
      routeName: 'RentalProfile',
    },
    {
      taskTypeId: 3,
      routeName: 'ChildRegistration',
    },
    {
      taskTypeId: 4,
      routeName: 'Photo',
    },
  ],
}
