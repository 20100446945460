const schema = {
  type: 'object',
  'x-props': {
    tile: true,
    mandatory: false,
  },
  properties: {
    application: {
      title: 'Application Header',
      type: 'object',
      required: ['subtitle', 'title'],
      properties: {
        title: {
          title: 'Title',
          description: 'Title text in the header area',
          type: 'string',
        },
        subtitle: {
          title: 'Subtitle',
          description: 'Subtitle text in the header area.',
          type: 'string',
        },
      },
    },
    home: {
      title: 'Home page',
      type: 'object',
      required: ['checkInTitle', 'checkInButton', 'membersTitle'],
      properties: {
        checkInTitle: {
          title: 'Title',
          description: 'Title Text on the home page if the user is ready to check in',
          type: 'string',
        },
        checkInTitleNoItems: {
          title: 'Title (No Check In Items)',
          description: 'Title text on the home page if there is no check in needed for the user',
          type: 'string',
        },
        checkInTitleEditProfile: {
          title: 'Title (Edit Profile)',
          description: 'Title text on the home page for the edit profile location',
          type: 'string',
        },
        checkInButton: {
          title: 'Check In Button',
          description: 'Check In Button Label - use {MemberCount} to represent count of selected members',
          type: 'string',
        },
        startEditProfileButton: {
          title: 'Start Edit Profile Button',
          description: 'Start Edit Profile Button Label - use {MemberCount} to represent count of selected members',
          type: 'string',
        },
        membersTitle: {
          title: 'Family Members Title',
          description: 'Title text of the section that displays all family members',
          type: 'string',
        },
        posCheckInInstructions: {
          title: 'Check In Instructions',
          description: 'Check in instructions if the user has POS transactions',
          type: 'string',
        },
        posCheckInInstructionsNoItems: {
          title: 'Check In Instructions (No Items)',
          description: 'Check in instructions if there are no POS transactions',
          type: 'string',
        },
        editProfileInstructions: {
          title: 'Edit Profile Instructions',
          description: 'Instructions for the edit profile location',
          type: 'string',
        },
        editProfileButtonText: {
          title: 'Edit Profile Button',
          description: 'Label on button to edit user profile',
          type: 'string',
        },
        selectFamilyTitle: {
          title: 'Select Family Members Title',
          description: '???',
          type: 'string',
        },
        selectFamilyInstructions: {
          title: 'Select Family Members Instructions',
          description: '???',
          type: 'string',
        },
        qrCodesTitle: {
          title: 'QR Codes Title',
          description: 'QR Codes Title - use {QRCodeCount} to represent count of pritable media',
          type: 'string',
        },
      },
    },
    rentalProfile: {
      title: 'Rental Profile Page',
      type: 'object',
      required: [],
      properties: {
        skierAbilityUrl: {
          title: 'Skier Ability Help URL',
          description: 'The full URL of the page thta provides information about skier ability levels',
          type: 'string',
        },
        skierAbilityText: {
          title: 'Skier Ability Text',
          description:
            'This will display below the skier ability dropdown on the rental page and will be a link to the configured URL',
          type: 'string',
        },
      },
    },
    welcome: {
      title: 'Welcome Page',
      type: 'object',
      required: ['title', 'description', 'signInButton', 'signInNote'],
      properties: {
        title: {
          title: 'Welcome Title',
          description: 'Title Text on Welcome Page',
          type: 'string',
        },
        description: {
          title: 'Welcome Description',
          description: 'Description Text on Welcome Page',
          type: 'string',
        },
        signInButton: {
          title: 'Sign in Button',
          description: 'Label on Button to Sign In',
          type: 'string',
        },
        signInNote: {
          title: 'Sign In Note',
          description: 'Appears in small text under the sign in button',
          type: 'string',
        },
      },
    },
    checkIn: {
      title: 'Check In',
      type: 'object',
      required: ['checkInCompleteTitle'],
      properties: {
        checkInCompleteTitle: {
          title: 'Check In Complete Title',
          description: 'Shows on the last page of check in. Leave blank if not desired.',
          type: 'string',
          default: 'You are all set',
        },
        checkInAgainText: {
          title: 'Check In Again Prompt',
          description: 'Shows on the last page of check in above the check in again button.',
          type: 'string',
          default: '',
        },
      },
    },
  },
}

export default schema
