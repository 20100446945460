<template>
  <div id="admin-reporting" class="admin--page">
    <v-row>
      <v-col cols="12" lg="6">
        <v-card class="pa-2">
          <v-card-title class="pt-2">Customer Activity Report</v-card-title>
          <v-card-text>
            <v-alert type="warning" v-if="status && status.statusCode == 1"
              >The data for this report is currently processing, check back later</v-alert
            >
            <v-alert type="success" v-else-if="status && status.statusCode == 2">
              The data for this report was last populated on {{ status.lastRefreshDate | longDate }}
            </v-alert>
            <v-alert type="error" prominent v-else-if="status && status.statusCode == 3">
              <v-row align="center">
                <v-col class="grow"> There has been an issue populating the data for this report </v-col>
                <v-col class="shrink">
                  <v-btn @click="reRunReport" :loading="reRunningReport">Try Again</v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-alert type="warning" v-else>The data for this report has not been populated yet</v-alert>
            <v-container fluid v-if="locations">
              <v-row align="center">
                <v-col class="d-flex" cols="12" lg="6">
                  <v-select
                    v-model="request.locationId"
                    :items="locations"
                    :item-text="getLocationText"
                    item-value="id"
                    :item-disabled="isLocationDisabled"
                    label="Location"
                    prepend-icon="mdi-map-marker"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" lg="3">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDateFormatted"
                        label="Begin Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="startDate = parseDate(startDateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" no-title @input="startDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDateFormatted"
                        label="End Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="endDate = parseDate(endDateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" no-title @input="endDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12" lg="6">
                  <v-switch v-model="request.includePrintableMedia" label="Include Printable Media"></v-switch>
                  <v-switch v-model="request.includeDetails" label="Include Details"></v-switch>
                  <v-switch v-model="request.isCsvExport" label="CSV Export"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pt-0 pl-4 pr-4 pb-4">
            <v-btn :loading="saving" @click="getCustomerReport"
              >Download Report <v-icon>mdi-cloud-download-outline</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="pa-2">
          <v-card-title class="pt-2">Test Customer Activities</v-card-title>
          <v-card-text>
            <v-textarea
              name="input-7-1"
              filled
              label="IP Codes (Comma Separated)"
              auto-grow
              v-model="customerEntitlementsRequest.ipCodesCommaSeparated"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn :loading="downloadingCustomerEntitlementsReport" @click="getCustomerEntitlementsReport"
              >Download Report <v-icon>mdi-cloud-download-outline</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ReportService from '../../Reports/services/ReportService'
import ContactEntitlementReportRequest from '../../Reports/interfaces/ContactEntitlementReportRequest'
import Location from '../../Location/interfaces/location'
import store from '@/Common/store'
import axios from 'axios'
import CustomerEntitlementsReportRequest from '../../Reports/interfaces/CustomerEntitlementsReportRequest'
import ContactEntitlementReportStatus from '../../Reports/interfaces/ContactEntitlementReportStatus'

@Component
export default class AdminReporting extends Vue {
  @Action('location/fetchLocations')
  private fetchLocations: any
  @Getter('location/locations')
  private locations?: []

  private saving: boolean = false
  private reRunningReport: boolean = false
  private downloadingCustomerEntitlementsReport: boolean = false

  private startDate = new Date().toISOString().substr(0, 10)
  private startDateFormatted = this.formatDate(this.startDate)
  private startDateMenu = false

  private endDate = new Date().toISOString().substr(0, 10)
  private endDateFormatted = this.formatDate(this.endDate)
  private endDateMenu = false
  private status: ContactEntitlementReportStatus | null = null

  private request: ContactEntitlementReportRequest = {
    locationId: 1,
    startDate: new Date(),
    endDate: new Date(),
    includePrintableMedia: true,
    isCsvExport: true,
    includeDetails: true,
  }

  private customerEntitlementsRequest: CustomerEntitlementsReportRequest = {
    ipCodesCommaSeparated: '',
  }

  // Methods
  public async created() {
    if (!this.locations) {
      this.getLocations()
    }
    this.status = await ReportService.GetCustomerReportStatus()
  }

  private async reRunReport() {
    this.reRunningReport = true
    await ReportService.ReRunCustomerReport()
    await new Promise((resolve) => setTimeout(resolve, 5000))
    this.status = await ReportService.GetCustomerReportStatus()
    this.reRunningReport = false
  }
  private async getCustomerReport() {
    this.saving = true
    const request = this.request
    const fileDownloadName = `arrival-customer-report-${
      request.locationId
    }-location-${request.startDate.toDateString()}-to-${request.endDate.toDateString()}-${
      request.includePrintableMedia ? 'include-printable-media' : 'exclude-printable-media'
    }${request.isCsvExport ? '.csv' : '.json'}`
    const response = await ReportService.GetCustomerReport(request)

    let data = response
    if (!request.isCsvExport) {
      data = JSON.stringify(response)
    }

    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileDownloadName)
    document.body.appendChild(link)
    link.click()
    this.saving = false
  }

  private async getCustomerEntitlementsReport() {
    this.downloadingCustomerEntitlementsReport = true
    const response = await ReportService.GetCustomerEntitlementsReport(this.customerEntitlementsRequest)

    const url = window.URL.createObjectURL(new Blob([JSON.stringify(response)]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'customer-entitlements-report.json')
    document.body.appendChild(link)
    link.click()

    this.downloadingCustomerEntitlementsReport = false
  }

  private isLocationDisabled(item: Location) {
    return item.usePosTransactionsAndOrders == false
  }

  private getLocationText(item: Location) {
    if (item.usePosTransactionsAndOrders) {
      return item.name
    } else {
      return item.name + ' (POS disabled for this location)'
    }
  }

  public async getLocations() {
    await this.fetchLocations()
  }

  private formatDate(date) {
    if (!date) {
      return null
    }
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }

  private parseDate(date) {
    if (!date) {
      return null
    }
    const [month, day, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  // Computed
  get computedStartDateFormatted() {
    return this.formatDate(this.startDate)
  }

  get computedEndDateFormatted() {
    return this.formatDate(this.endDate)
  }

  // Watches
  @Watch('startDate')
  onStartDateChangedChanged(value: string) {
    this.startDateFormatted = this.formatDate(this.startDate)
    const stringValue: string | null = this.startDateFormatted
    if (stringValue) {
      this.request.startDate = new Date(stringValue)
    }
  }
  @Watch('endDate')
  onEndDateChangedChanged(value: string) {
    this.endDateFormatted = this.formatDate(this.endDate)
    const stringValue: string | null = this.endDateFormatted
    if (stringValue) {
      this.request.endDate = new Date(stringValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
