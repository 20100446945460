import { ActionTree } from 'vuex'
import ContactBLE from '../models/ContactBLE'
import ETicketDelivery from '../models/ETicketDelivery'
import ETicketDeliveryMode from '../enums/ETicketDeliveryMode'
import ETicketDownload from '../models/ETicketDownload'
import ETicketStatus from '../enums/ETicketStatus'
import ETicketSummary from '../models/ETicketSummary'
import GetBLEDataRequest from '../interfaces/getBLEDataRequest'
import MediaService from '@/Profile/services/MediaService'
import ProfileService from '@/Profile/services/ProfileService'
import { ProfileState } from './types'
import { RootState } from '@/Common/store/types'

export const actions: ActionTree<ProfileState, RootState> = {
  async fetchProfile({ commit }): Promise<any> {
    const profileResponse = await ProfileService.FetchProfile()
    const contacts = profileResponse.contacts || []
    const profileContacts = contacts.map((c: any) => {
      return {
        id: c.contactId,
        firstName: c.firstName,
        lastName: c.lastName,
        photo: c.photo,
        isHeadOfHouseHold: c.isHeadOfHouseHold,
      }
    })
    profileContacts.forEach((c: any) => {
      commit('addContact', c)
    })
  },
  async fetchContactBLE({ commit }, contactId: string): Promise<any> {
    let bleContact: ContactBLE | null = null

    const bleDataResult = await MediaService.FetchBLEData(contactId)

    if (bleDataResult) {
      const ticketSummary: ETicketSummary = {
        id: bleDataResult.id,
        delivery: bleDataResult.delivery || new ETicketDelivery(ETicketDeliveryMode.Unknown, null, false, null, null),
        download: bleDataResult.download || new ETicketDownload(null, null, null, ETicketDeliveryMode.Unknown),
        status: bleDataResult.status || ETicketStatus.Unknown,
      }
      bleContact = {
        id: contactId,
        eticket: ticketSummary,
        status: bleDataResult.status,
      }
    } else {
      const emptyTicket = new ETicketSummary(
        null,
        new ETicketDelivery(ETicketDeliveryMode.Unknown, null, false, null, null),
        new ETicketDownload(null, null, null, ETicketDeliveryMode.Unknown)
      )
      bleContact = {
        id: contactId,
        eticket: emptyTicket,
        status: ETicketStatus.NoMedia,
      }
    }

    commit('addContactBLE', bleContact)
    return bleContact
  },
  reset({ commit }): any {
    commit('resetContacts')
  },
}
