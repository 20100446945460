<template>
  <div id="view-welcome">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="11" sm="6" xl="4">
          <div class="logo" :style="logoStyle"></div>
          <h1 :style="titleStyle" :class="titleClasses" v-text="language.application.title"></h1>
          <h2 class="subhead" v-text="language.welcome.title"></h2>
          <p v-text="language.welcome.description"></p>
          <v-btn @click="onSignIn" class="mb-4" color="primary" :loading="signingIn" large>
            {{ language.welcome.signInButton }}
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <p class="note" v-text="language.welcome.signInNote"></p>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class Welcome extends Vue {
  @Getter('settings/theme') theme
  @Getter('settings/languageSettings') language
  @Action('oidcStore/authenticateOidcSilent') authenticateSilent

  public signingIn: boolean = false

  public onSignIn() {
    this.signingIn = true
    this.$router.push({ name: 'home' }).catch(() => {
      // ... No catch response yet
    })
  }

  get logoStyle() {
    if (this.theme && this.theme.general && this.theme.general.logoUrl && this.theme.general.logoWelcomeWidth) {
      return `background-image: url('${this.theme.general.logoUrl}');width: ${this.theme.general.logoWelcomeWidth}px;`
    }

    return ''
  }

  get titleClasses() {
    const classes: string[] = []
    if (this.theme && this.theme.general && this.theme.general.showArrivalTitleAsImage) {
      classes.push('bgImage')
    }
    return classes.join(' ')
  }

  get titleStyle() {
    if (this.theme && this.theme.general && this.theme.general.showArrivalTitleAsImage) {
      return `background-image: url('${this.theme.general.arrivalTitleImageUrl}');`
    }

    return ''
  }
}
</script>
<style lang="scss" scoped>
#view-welcome {
  max-width: none;
  min-height: 100vh;
  position: relative;
  z-index: 100;
  color: white;
}
h1 {
  margin: 0 auto 1rem auto;
  font-size: 2rem;
  color: inherit;
  text-transform: none;
  letter-spacing: normal;

  &.bgImage {
    font-size: 0;
    height: 80px;
    width: var(--arrivalTitleImageWidth, '100%');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
h2 {
  margin: 0 1rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 1.1em;
}
p {
  line-height: 1.5;
  margin-bottom: 2rem;
}
h1,
h2,
p {
  text-align: center;
  margin: 1.5rem auto;
}
p.note {
  font-size: 0.875rem;
  margin: 0 auto;
}
.v-btn {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: block;
}
.logo {
  height: 120px;
  width: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 100;
  margin: 2rem auto;
}
</style>
