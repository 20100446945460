import { render, staticRenderFns } from "./ChildRegistrationForm.vue?vue&type=template&id=8b93b7ee&scoped=true"
import script from "./ChildRegistrationForm.vue?vue&type=script&lang=ts"
export * from "./ChildRegistrationForm.vue?vue&type=script&lang=ts"
import style0 from "./ChildRegistrationForm.vue?vue&type=style&index=0&id=8b93b7ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b93b7ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMessages } from 'vuetify/lib/components/VMessages';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VForm,VMessages,VSpacer,VTextField,VTextarea})
