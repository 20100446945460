import { GetterTree } from 'vuex'
import { SettingsState } from './types'
import { RootState } from '@/Common/store/types'

export const getters: GetterTree<SettingsState, RootState> = {
  baseUrl(state): string {
    return state.appSettings ? state.appSettings.baseUrl : ''
  },
  settings(state): any {
    return state.appSettings
  },
  languageSettings(state): any {
    return state.languageSettings
  },
  themeSettings(state): any {
    return state.themeSettings
  },
  theme(state): any {
    return state.themeSettings ? state.themeSettings.theme : null
  },
  isDevelopment(state): any {
    return state.environment ? state.environment.toUpperCase() === 'DEVELOPMENT' : false
  },
  oneRiskSettings(state): any {
    return state.oneRiskSettings
  },
}
