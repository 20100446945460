import { ActionTree } from 'vuex'
import { RootState } from '@/Common/store/types'
import Task from '../interfaces/Task'
import TaskService from '../services/TaskService'
import { TaskState } from './types'
import TaskType from '../interfaces/TaskType'
import moment from 'moment'
import { task } from '.'

export const actions: ActionTree<TaskState, RootState> = {
  async fetchTasks({ state, commit }): Promise<Task[] | null> {
    const tasksResponse = await TaskService.FetchTasks()
    const allTasks = tasksResponse || null
    const modifiedTasks = allTasks.map((task) => {
      return {
        ...task,
        modifiedDate: moment(task.modifiedDateUtc).format('MM/DD/YY hh:MM a'),
      }
    })

    commit('setTasks', modifiedTasks)

    return allTasks
  },
  async fetchTaskTypes({ commit }): Promise<TaskType[] | null> {
    const taskTypesResponse = await TaskService.FetchTaskTypes()
    const allTaskTypes = taskTypesResponse || null

    commit('setTaskTypes', allTaskTypes)

    return allTaskTypes
  },
  setCurrentTaskByRoute({ state, commit, rootGetters }, routeName: string) {
    const desiredRoute = routeName.replace('checkin-', '')
    const foundTaskKey = state.taskTypeKeys!.find((ttk) => ttk.routeName === desiredRoute)
    if (foundTaskKey) {
      const tasks = rootGetters['activity/selectedActivityTasks']
      if (tasks) {
        const foundTask = tasks.find((task) => task.taskType!.id == foundTaskKey.taskTypeId)
        if (foundTask) {
          commit('setCurrentTask', foundTask)
        }
      }
    }
  },
  advanceTask({ commit, getters, rootGetters }) {
    let tasks = rootGetters['activity/selectedActivityTasks']

    if (!tasks) {
      tasks = rootGetters['task/tasks']
    }
    const currentTask = getters.currentTask
    if (!currentTask) {
      const firstTask = tasks[0]
      commit('task/setCurrentTask', firstTask, { root: true })
    } else {
      const currentTaskPosition = tasks.findIndex((task: Task) => task.id == currentTask.id)
      if (currentTaskPosition < tasks.length - 1) {
        const nextTask = tasks[currentTaskPosition + 1]
        commit('setCurrentTask', nextTask)
      } else {
        commit('setCurrentTask', null)
      }
    }
  },
  completeCurrentTask({ getters, commit, dispatch }) {
    const currentTask = getters.currentTask
    const completedTask = {
      ...currentTask,
      isComplete: true,
    }
    commit('setCurrentTask', completedTask)
    dispatch('advanceTask')
  },
}
