<template>
  <nav>
    <router-link :to="{ name: 'home' }" v-if="!user">Home</router-link>
    <router-link to="welcome" v-if="user">Welcome</router-link>
    <router-link to="about">About</router-link>
    <a @click="signOut" v-if="user">Sign Out</a>
  </nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class Navigation extends Vue {
  @Getter('oidcStore/oidcUser')
  private user!: any
  @Action('oidcStore/signOutOidc')
  private signOut: any

  private signOutOidc() {
    this.signOut()
  }
}
</script>

<style lang="scss" scoped>
nav {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  background: #cecece;
  padding: 5px;
  a {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>