<template>
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="type === 'check'"
    class="icon"
  >
    <path
      d="M14.5656 0.311706C14.1413 0.315541 13.7335 0.484254 13.4306 0.782062L6.26289 7.6635L3.134 5.25036C2.41951 4.70076 1.39445 4.83243 0.842292 5.54434C0.290135 6.25754 0.419217 7.28389 1.12988 7.83732L5.38353 11.1094C6.02514 11.6078 6.9352 11.5605 7.52057 10.9969L15.7007 3.14398C16.1953 2.68387 16.3551 1.9668 16.1033 1.33926C15.8528 0.712954 15.2418 0.304017 14.5656 0.311706Z"
      :fill="color"
    />
  </svg>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'arrow'"
  >
    <path
      d="M8.49105 0.501802C8.3668 0.352391 8.18461 0.262433 7.99022 0.254061C7.79584 0.244914 7.6068 0.318093 7.46881 0.456079C7.33159 0.593294 7.25842 0.782347 7.26679 0.976735C7.27594 1.17112 7.36589 1.35331 7.51529 1.47756L12.4939 6.46224H1.51571C1.14677 6.4813 0.857849 6.78623 0.857849 7.15594C0.857849 7.52488 1.14677 7.8298 1.51571 7.84963H12.4939L7.50768 12.8275C7.24698 13.1004 7.24698 13.5303 7.50768 13.8032C7.63651 13.9328 7.8126 14.006 7.99555 14.006C8.1785 14.006 8.35383 13.9328 8.48342 13.8032L14.6505 7.63614C14.7831 7.50807 14.8578 7.33274 14.8578 7.14903C14.8578 6.96455 14.7824 6.78845 14.6497 6.66116L8.49105 0.501802Z"
      :fill="color"
    />
  </svg>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'x'"
  >
    <path
      d="M0.486706 16.6104C0.700564 16.8264 0.991421 16.9483 1.29512 16.9483C1.60094 16.9483 1.89177 16.8264 2.10567 16.6104L8.12799 10.5881L14.1739 16.6339C14.6208 17.0809 15.3458 17.0809 15.7928 16.6339C16.2397 16.1869 16.2397 15.462 15.7928 15.015L9.7469 8.96914L15.7928 2.92328C16.2397 2.47631 16.2397 1.75134 15.7928 1.30437C15.3458 0.857407 14.6208 0.857402 14.1739 1.30437L8.12799 7.35023L2.08213 1.32791C1.63516 0.880943 0.910185 0.880943 0.463219 1.32791C0.0162479 1.77489 0.0162479 2.49986 0.463219 2.94682L6.50908 8.96914L0.48676 15.015C0.0504817 15.4577 0.0504817 16.1677 0.48676 16.6104L0.486706 16.6104Z"
      :fill="color"
    />
  </svg>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'icon-arrow-x'"
  >
    <path
      d="M21.3828 10.736L14.857 1.15057C14.6947 0.910414 14.4212 0.767456 14.1287 0.767456H1.41277C0.927464 0.767456 0.533615 1.15794 0.533615 1.63909V20.8092C0.533615 21.2904 0.927464 21.6808 1.41277 21.6808H14.1295C14.422 21.6808 14.6956 21.5362 14.8579 21.2985L21.3843 11.7139C21.5837 11.4174 21.5837 11.031 21.3826 10.7361L21.3828 10.736ZM13.6617 19.9376H2.29216V2.50989H13.6617L19.5941 11.2237L13.6617 19.9376Z"
      :fill="color"
    />
    <path
      d="M13.3445 7.16031C13.0001 6.8213 12.4455 6.82293 12.102 7.16276L9.26348 9.98846L6.425 7.16276C6.08388 6.82211 5.52772 6.8213 5.18249 7.16031C4.83809 7.50014 4.83726 8.05154 5.18002 8.393L8.02336 11.2243L5.18065 14.054C4.83789 14.3963 4.83871 14.9461 5.18313 15.2859C5.35451 15.455 5.57862 15.5416 5.80355 15.5416C6.03014 15.5416 6.25507 15.455 6.42563 15.2843L9.26327 12.4569L12.1009 15.2843C12.2723 15.455 12.4981 15.5416 12.723 15.5416C12.9471 15.5416 13.172 15.455 13.3434 15.2859C13.6886 14.9461 13.6895 14.3963 13.3459 14.054L10.504 11.2227L13.3467 8.39216C13.6887 8.05233 13.6878 7.50012 13.3443 7.16029L13.3445 7.16031Z"
      :fill="color"
    />
  </svg>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'add-user'"
  >
    <path
      d="M6.26523 0.15683C9.14018 0.15683 10.5943 3.65543 8.55079 5.69099C6.50745 7.72474 3.02466 6.26436 3.02466 3.39619C3.02527 1.57735 4.50895 0.15683 6.26507 0.15683H6.26523ZM8.11448 1.57292C6.48094 -0.05418 3.65665 1.09447 3.65665 3.39569C3.65665 5.69937 6.48094 6.84554 8.11448 5.22093C9.12537 4.21274 9.12537 2.58118 8.11448 1.57292Z"
      :fill="color"
    />
    <path
      d="M9.23836 11.9739C9.71062 11.9739 9.71062 12.6875 9.23836 12.6875L0.357049 12.6882C0.12967 12.6882 0.00232342 12.5203 0.000227842 12.2831C-0.0214605 9.84686 1.50727 7.38018 3.71818 6.39579C4.17084 6.1852 4.53257 7.10034 6.2746 7.10034C8.01392 7.10034 8.3777 6.18729 8.82834 6.3937C9.80085 6.84147 10.5341 7.7361 11.2148 8.5722C11.5121 8.93391 10.9587 9.38449 10.6614 9.02277C10.1416 8.38329 9.4587 7.47186 8.70658 7.12204C7.23103 8.04908 5.31891 8.04908 3.84341 7.12204C2.0775 7.94132 0.844047 10.0071 0.724402 11.9746L9.23836 11.9739Z"
      :fill="color"
    />
    <path
      d="M10.9611 11.7323C10.4915 11.7372 10.4817 11.0227 10.9513 11.0185L12.4559 10.9996L12.437 9.55239C12.43 9.08281 13.1438 9.07301 13.1488 9.54259L13.1676 10.9898L14.6722 10.973C15.1418 10.966 15.1516 11.6798 14.682 11.6847L13.1774 11.7036L13.1963 13.1536C13.2033 13.6211 12.4895 13.6302 12.4846 13.1606L12.4657 11.7134L10.9611 11.7323Z"
      :fill="color"
    />
  </svg>
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'shoe'"
  >
    <path
      d="M8.73266 2.72348L9.60925 2.75209H9.66442L9.70324 2.76741L9.73696 2.7858H9.73798C9.75637 2.79908 9.77374 2.81543 9.78702 2.83382C9.78702 2.83382 10.1712 3.315 10.3469 3.53162C11.9008 5.39613 13.7357 7.00727 15.7873 8.30479C15.9231 8.36507 16.1877 8.3334 16.6005 8.2343C14.3978 6.60781 12.6773 4.32945 10.4778 1.59867C10.347 1.41579 9.09649 2.20043 9.09649 2.20043C8.92587 2.33427 8.79817 2.51611 8.73279 2.72352L8.73266 2.72348Z"
      :fill="color"
    />
    <path
      d="M1.54021 5.97944C4.34579 6.63636 7.1001 7.49046 9.78485 8.53459L10.8883 8.96572C13.4904 9.99147 15.596 10.8231 19.7807 11.3329C20.215 11.383 20.6727 11.4351 21.1569 11.479C21.1283 10.9559 21.1252 10.3388 20.7462 10.2704V10.2714C19.5509 9.93526 18.4107 9.42648 17.3616 8.76242C17.335 8.75118 17.3064 8.74403 17.2778 8.73892C16.9355 8.84109 16.1427 9.05053 15.6881 8.92691C15.6442 8.91771 15.6033 8.90239 15.5645 8.87991H15.544C13.3812 7.53848 11.3981 5.74139 9.48669 3.38749L8.70206 3.36093C6.85083 4.28962 4.6429 3.36093 4.39993 2.05321C4.20888 1.29207 3.96265 0.745491 3.49268 0.862976C2.70805 1.01725 1.88152 2.25141 1.50762 3.64606L1.5066 3.64709C1.3227 4.41333 1.33189 5.21431 1.53316 5.97749L1.54021 5.97944Z"
      :fill="color"
    />
    <path
      d="M2.12161 9.19355L14.9373 12.6253C18.9441 13.462 20.872 13.8942 21.2695 12.9757C21.3431 12.6968 21.3645 12.4076 21.3318 12.1206H21.3032C20.9078 12.0705 16.6639 11.5117 15.1601 11.1081H15.159C13.648 10.6841 12.1636 10.1692 10.7128 9.56847C9.58796 9.1261 8.39262 8.65613 6.91231 8.1586C5.43192 7.66208 3.67466 7.14103 1.41989 6.58933L0.931536 7.87867C0.857977 8.54069 1.26153 8.97694 2.13197 9.18639L2.12161 9.19355Z"
      :fill="color"
    />
  </svg>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'person'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.98821 0.755847C4.81721 0.755847 0.614006 4.95905 0.614006 10.1534C0.614006 12.3964 1.41713 14.4744 2.73901 16.08C2.95151 14.4511 4.58041 13.0574 6.89441 12.3964C8.64207 14.0496 11.2624 14.073 13.0576 12.4675C15.3716 13.1761 16.9772 14.6401 17.0248 16.3635C18.4889 14.687 19.3858 12.5143 19.3858 10.1533C19.3858 4.95874 15.1826 0.755741 9.98821 0.755741V0.755847ZM9.98821 12.5386C7.79221 12.5386 6.28121 10.484 6.28121 8.38325C6.28121 6.09265 7.95777 4.22785 9.98821 4.22785C12.0428 4.22785 13.6952 6.09347 13.6952 8.38325C13.6952 10.4848 12.1843 12.5386 9.98821 12.5386Z"
      :fill="color"
    />
  </svg>
  <svg
    width="23"
    height="17"
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'ski'"
  >
    <path
      d="M18.0554 1.13101C18.9685 1.59602 19.3309 2.71428 18.8649 3.6263C18.3999 4.53938 17.2826 4.90178 16.3696 4.43583C15.4565 3.97082 15.0941 2.8535 15.5601 1.94054C16.0251 1.02746 17.1434 0.665057 18.0554 1.13101Z"
      :fill="color"
    />
    <path
      d="M22.4315 15.3777C22.2451 15.1358 21.8987 15.0906 21.6568 15.277C20.6345 16.063 19.3553 15.6836 19.3449 15.6808L11.9518 13.3303L13.5332 10.4519C13.7187 10.1149 13.6396 9.69322 13.345 9.44564L11.5395 7.92918L12.1853 7.54608C12.19 7.58562 12.1966 7.62515 12.2069 7.66374C12.2822 7.94142 12.4997 8.15794 12.7783 8.2323L15.9599 9.07947C16.0295 9.0983 16.0992 9.10677 16.1679 9.10677C16.5247 9.10677 16.8504 8.86862 16.9473 8.50715C17.0622 8.07696 16.8061 7.63456 16.375 7.51971L14.5404 7.03118L15.1513 6.41556C15.9458 5.66252 16.151 4.79368 15.6747 4.19972C15.6596 4.1809 15.6427 4.16207 15.6257 4.14513L15.2831 3.78931C15.2586 3.76389 15.2323 3.74036 15.205 3.71871C14.5705 3.21321 13.8034 3.31864 13.2555 3.54268C12.9204 3.63775 12.0827 4.01521 11.1715 4.52634L10.9117 4.64777L7.28477 2.18332C7.4655 1.88492 7.53423 1.62325 7.4335 1.54512C7.32996 1.46417 7.07956 1.60631 6.82542 1.87082L6.51102 1.65715C6.38489 1.55831 6.20229 1.57902 6.10344 1.70515C6.00366 1.83129 6.02531 2.01389 6.15145 2.11274L6.46115 2.34901C6.29171 2.63705 6.22958 2.88744 6.32748 2.96369C6.42538 3.03993 6.65506 2.91756 6.89416 2.68035L10.1305 5.15685C9.51953 5.55692 8.95006 5.99178 8.55469 6.41633C8.26665 6.65636 8.08404 7.01877 8.08404 7.42259C8.08404 7.89512 8.33444 8.30743 8.70908 8.53804L11.0181 10.255L8.17436 11.9503C8.11412 11.9861 8.06235 12.0303 8.01528 12.0783L0.863141 9.80411C0.572284 9.71092 0.261643 9.87283 0.169398 10.1628C0.0771506 10.4536 0.238115 10.7643 0.528041 10.8565L19.0106 16.7322C19.0473 16.7435 19.4897 16.88 20.1072 16.88C20.7463 16.88 21.5738 16.7341 22.3306 16.1514C22.5725 15.966 22.6177 15.6196 22.4313 15.3777L22.4315 15.3777ZM10.4647 12.6865C10.4383 12.7354 10.4167 12.7853 10.4007 12.8371L10.0345 12.7204L10.6464 12.3561L10.4647 12.6865Z"
      :fill="color"
    />
  </svg>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'switch'"
  >
    <path
      d="M9.19596 16.6683L7.19427 15.2005C6.80457 14.9148 7.00669 14.2973 7.48993 14.2973H19.9607"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0815 8.51797L17.0837 9.98571C17.4735 10.2714 17.2714 10.889 16.7881 10.889H4.3177"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M22.2596 12.3008C22.2596 17.8423 17.7669 22.3341 12.2254 22.3341C6.68486 22.3341 2.19209 17.8423 2.19209 12.3008C2.19209 6.75935 6.68486 2.26756 12.2254 2.26756C17.7669 2.26756 22.2596 6.75935 22.2596 12.3008"
      :stroke="color"
      stroke-width="1.25"
      stroke-miterlimit="10"
    />
  </svg>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'ruler'"
  >
    <path
      d="M4.78076 8.09119L3.83665 9.72656L5.14552 10.4823C5.50674 10.6912 5.63139 11.1502 5.42174 11.5142C5.21281 11.8754 4.75385 12.0008 4.38981 11.7911L3.08094 11.0354L1.94772 12.998L7.18172 16.0201L13.6035 4.89756L8.36951 1.87542L7.23629 3.83796L8.54516 4.59368C8.90638 4.80261 9.03103 5.26157 8.82138 5.62561C8.61245 5.98682 8.15349 6.11219 7.78945 5.90253L6.48058 5.14681L5.53647 6.78218L8.15139 8.29218C8.51402 8.50181 8.64008 8.96148 8.42974 9.32553C8.22152 9.68674 7.75902 9.80998 7.39639 9.60103L4.78076 8.09119ZM7.43882 0.465301C7.64775 0.104087 8.10954 -0.0191521 8.47217 0.190501L15.0114 3.96546C15.3741 4.1751 15.498 4.63759 15.2898 4.9981L8.1123 17.4301C7.90407 17.7913 7.44158 17.9145 7.07895 17.7049L0.539673 13.9299C0.177752 13.7203 0.0530982 13.2578 0.261319 12.8973L7.43882 0.465301Z"
      :fill="color"
    />
  </svg>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'scale'"
  >
    <path
      d="M2.23453 0.508579H17.7655C18.6595 0.508579 19.355 1.28568 19.2563 2.17423L17.7007 16.1742C17.6163 16.9339 16.9742 17.5086 16.2099 17.5086H3.79009C3.02576 17.5086 2.38367 16.9339 2.29926 16.1742L0.743704 2.17423C0.644977 1.28568 1.34051 0.508579 2.23453 0.508579Z"
      :stroke="color"
    />
    <path
      d="M7.02818 10.0782H12.9728H12.972C14.0462 10.0766 15.0752 9.64931 15.8344 8.88993C16.5936 8.13055 17.0211 7.10165 17.0219 6.02813V2.51633C17.0219 2.36945 16.9641 2.22805 16.8594 2.12415C16.7555 2.01946 16.6141 1.96087 16.4664 1.96087H3.51644C3.2102 1.96087 2.96098 2.21009 2.96098 2.51633V6.02812C2.96255 7.10468 3.39222 8.13592 4.15474 8.89612C4.91802 9.6555 5.95162 10.0805 7.02814 10.0782L7.02818 10.0782ZM4.06098 3.06101H5.17192V4.13913C5.13052 4.30475 5.16802 4.48053 5.27349 4.61569C5.37817 4.75084 5.53989 4.82975 5.71099 4.82975C5.88208 4.82975 6.04381 4.75084 6.14927 4.61569C6.25473 4.48053 6.29145 4.30475 6.25005 4.13913V3.06101H7.32817V4.13913C7.3852 4.39147 7.61021 4.57117 7.86957 4.57117C8.12893 4.57117 8.35317 4.39148 8.41097 4.13913V3.06101H9.44457V4.13913C9.50238 4.39147 9.72661 4.57117 9.98597 4.57117C10.2453 4.57117 10.4703 4.39148 10.5281 4.13913V3.06101H11.6063L11.6055 4.13913C11.6633 4.39147 11.8883 4.57117 12.1469 4.57117C12.4063 4.57117 12.6313 4.39148 12.6891 4.13913V3.06101H13.7672L13.7664 4.13913C13.7664 4.44617 14.0156 4.69459 14.3219 4.69459C14.6289 4.69459 14.8781 4.44615 14.8781 4.13913V3.06101H15.95V6.02821C15.95 6.81727 15.6359 7.57431 15.0773 8.13141C14.5187 8.68923 13.7617 9.00173 12.9719 9.00017H10.5555V6.30017C10.4938 6.05329 10.2711 5.87985 10.0165 5.87985C9.76179 5.87985 9.53991 6.05328 9.47819 6.30017V9.00017H7.02819C6.24069 8.9986 5.48601 8.68455 4.92979 8.12751C4.37355 7.57047 4.06103 6.81501 4.06103 6.02831L4.06098 3.06101Z"
      :fill="color"
    />
  </svg>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'phone'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9212 1.14211H5.95039C5.32232 1.14211 4.80777 1.65592 4.80777 2.28472V16.8932C4.80777 17.5213 5.32159 18.0358 5.95039 18.0358H12.9204C13.5485 18.0358 14.063 17.522 14.063 16.8932L14.0638 2.28472C14.063 1.65666 13.5492 1.14211 12.9212 1.14211H12.9212ZM9.43578 17.0539C9.04287 17.0539 8.72294 16.7332 8.72294 16.341C8.72294 15.9481 9.04361 15.6282 9.43578 15.6282C9.82869 15.6282 10.1486 15.9489 10.1486 16.341C10.1486 16.7332 9.82869 17.0539 9.43578 17.0539ZM13.3664 14.8984H5.50521V2.62956H13.3656L13.3664 14.8984Z"
      :fill="color"
    />
  </svg>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    :fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="type === 'drugallergy'"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M9.58942 0.307098C7.08667 0.307098 4.68658 1.30154 2.91737 3.07084C1.14816 4.84015 0.153625 7.24033 0.153625 9.74289C0.153625 12.2455 1.14806 14.6457 2.91737 16.4149C4.68667 18.1842 7.08686 19.1787 9.58942 19.1787C12.092 19.1787 14.4923 18.1842 16.2615 16.4149C18.0307 14.6456 19.0252 12.2455 19.0252 9.74289C19.0223 7.2409 18.0278 4.84288 16.2586 3.07367C14.4895 1.30446 12.0914 0.309929 9.58942 0.307098V0.307098ZM0.743363 9.74289C0.7441 7.43112 1.65009 5.21069 3.26668 3.55867C4.88403 1.90594 7.08384 0.952129 9.39485 0.901176C11.7066 0.850311 13.9461 1.7069 15.6342 3.28673L3.13326 15.7876C1.59553 14.1504 0.741098 11.989 0.743363 9.74289V9.74289ZM9.58942 18.5889C7.34332 18.5912 5.18196 17.7368 3.54466 16.199L16.0456 3.69813C17.6253 5.38625 18.4819 7.62569 18.4311 9.93746C18.3803 12.2485 17.4264 14.4481 15.7736 16.0656C14.1216 17.6822 11.9012 18.5882 9.58942 18.5889V18.5889Z"
        :fill="color"
      />
      <path
        d="M13.4073 13.1785C13.6329 12.7591 13.5592 12.2423 13.2245 11.9032L12.5375 11.2103L12.8707 10.8712C12.9842 10.7562 12.9842 10.5719 12.8707 10.4569L11.9595 9.54427C11.8438 9.43812 11.6647 9.44181 11.5526 9.55238C11.4413 9.66296 11.4361 9.84134 11.5408 9.95856L12.247 10.6721L10.5029 12.4266L9.7775 11.6894C9.6625 11.5737 9.47599 11.573 9.36027 11.688C9.24455 11.803 9.2438 11.9895 9.3588 12.1052L10.2935 13.0488C10.3488 13.1048 10.424 13.1358 10.5029 13.1358C10.5818 13.1358 10.657 13.1048 10.7122 13.0488L11.0425 12.7156L11.731 13.4085C12.0635 13.7469 12.5802 13.8243 12.9975 13.5972L14.6138 15.2328C14.6676 15.2932 14.7435 15.3279 14.8246 15.3308C14.905 15.3331 14.9831 15.3021 15.0406 15.2453C15.0981 15.1886 15.1298 15.1112 15.1283 15.0301C15.1268 14.9497 15.0922 14.8731 15.0325 14.8185L13.4073 13.1785ZM12.1438 12.9942L11.4568 12.2998L12.1217 11.629L12.8043 12.319C12.9908 12.5011 12.9938 12.8003 12.8117 12.9869C12.6296 13.1734 12.3303 13.1763 12.1438 12.9942Z"
        :fill="color"
      />
      <path
        d="M3.74929 6.15035L4.08396 5.81271L5.11601 6.84476L4.06186 7.90923C3.94686 8.02497 3.9476 8.21148 4.06333 8.32646C4.17907 8.44146 4.36558 8.44073 4.48057 8.32499L4.99068 7.80897L7.13281 9.97167C7.18663 10.0321 7.26256 10.0668 7.34365 10.0697C7.424 10.0719 7.50214 10.041 7.55963 9.9842C7.61713 9.92744 7.64883 9.85003 7.64736 9.76895C7.64588 9.6886 7.61123 9.61193 7.55152 9.55738L5.39901 7.39751L7.14316 5.6283L9.29718 7.81327C9.41218 7.929 9.59869 7.92974 9.71441 7.81474C9.83015 7.69974 9.83089 7.51323 9.71589 7.39751L7.55753 5.21122L8.07208 4.69225C8.17676 4.57504 8.1716 4.39665 8.06029 4.28608C7.94824 4.1755 7.7691 4.17182 7.65338 4.27797L6.60365 5.33361L5.5716 4.30156L5.90627 3.96393C6.02053 3.84819 6.01905 3.66095 5.90332 3.5467C5.78758 3.43244 5.60033 3.43391 5.48608 3.54965L3.33057 5.7346C3.21557 5.85033 3.21631 6.03684 3.33205 6.15183C3.44778 6.26683 3.6343 6.26608 3.74929 6.15035ZM6.18788 5.7567L5.52295 6.42753L4.49976 5.39548L5.16321 4.72465L6.18788 5.7567Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="translate(0 0.153549)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ required: true, default: '' }) type: string
  @Prop({ required: true, default: 'black' }) color: string
}
</script>

<style lang="scss" scoped></style>
