import { MutationTree } from 'vuex'
import { LocationState } from './types'
import LocationModel from '../models/Location'

export const mutations: MutationTree<LocationState> = {
  setCurrentLocation(state, location: LocationModel) {
    state.previousLocation = state.currentLocation
    state.currentLocation = location
    localStorage.setItem('location-key', location.id.toString())
    localStorage.setItem('location-name', location.name)
  },
  setTemporaryLocation(state, location: LocationModel) {
    state.previousLocation = state.currentLocation
    state.currentLocation = location
  },
  setEditLocation(state, location: LocationModel) {
    state.editLocation = location
  },
  setDefaultLocation(state, location: LocationModel) {
    state.defaultLocation = location
  },
  clearLocation(state) {
    state.previousLocation = state.currentLocation
    state.currentLocation = null
  },
  setLocations(state, locations: LocationModel[] | null) {
    state.locations = locations
  },
  setLocationByName(state, locationName: string) {
    const location = state.locations?.find((l) => l.name === locationName)
  },
  setLocationById(state, locationId: number) {
    const location = state.locations?.find((l) => l.id === locationId)
  },
}
