const schema = {
  type: 'object',
  properties: {
    theme: {
      type: 'object',
      'x-display': 'expansion-panels',
      'x-props': {
        tile: true,
        mandatory: false,
      },
      properties: {
        general: {
          type: 'object',
          title: 'General',
          properties: {
            logoUrl: {
              type: 'string',
              title: 'Logo url',
              description: 'URL for the logo image to be used throughout the site',
            },
            logoWelcomeWidth: {
              type: 'number',
              title: 'Welcome page logo width',
              enum: [100, 150, 200, 250, 300, 350, 400, 450, 500],
              description: 'Width of logo on welcome page (in pixels)',
              default: 200,
            },
            favicon: {
              type: 'string',
              title: 'Favicon Image Url',
              description: 'This can be a standard (favicon.ico) or high resolution (favicon-32x32.png) icon',
            },
            backgroundImageUrl: {
              type: 'string',
              title: 'Background image url',
              description: 'General background image used on pages like Welcome',
            },
            overlayBackgroundWithGradient: {
              type: 'string',
              title: 'Overlay the welcome background with dark gradient',
              default: 'Dark',
              enum: ['Dark', 'Light', 'None'],
            },
            showArrivalTitleAsImage: {
              type: 'boolean',
              title: 'Show application title as image',
              default: true,
            },
            arrivalTitleImageUrl: {
              type: 'string',
              title: 'Application title Image Url',
              description: 'Application title image on welcome page. Will be hidden if not provided.',
            },
            arrivalTitleImageWidth: {
              type: 'number',
              title: 'Welcome page arrival title width',
              enum: [200, 250, 300, 350, 400, 450, 500],
              description: 'Width of logo on welcome page (in pixels)',
            },
            loaderPrimaryColor: {
              title: 'Page Loader Primary Color',
              type: 'string',
              format: 'hexcolor',
              default: '#93dbe9',
            },
            loaderSecondaryColor: {
              title: 'Page Loader Secondary Color',
              type: 'string',
              format: 'hexcolor',
              default: '#93dbe9',
            },
          },
        },
        buttons: {
          type: 'object',
          title: 'Buttons',
          properties: {
            primary: {
              type: 'object',
              title: 'Primary Button',
              properties: {
                backgroundColor: {
                  title: 'Background Color',
                  type: 'string',
                  format: 'hexcolor',
                },
                color: {
                  title: 'Text Color',
                  type: 'string',
                  format: 'hexcolor',
                },
              },
            },
            secondary: {
              type: 'object',
              title: 'Secondary Button',
              properties: {
                backgroundColor: {
                  title: 'Background Color',
                  type: 'string',
                  format: 'hexcolor',
                },
                color: {
                  title: 'Text Color',
                  type: 'string',
                  format: 'hexcolor',
                },
              },
            },
          },
        },
        footer: {
          type: 'object',
          title: 'Footer',
          properties: {
            footerBackgroundColor: {
              title: 'Background Color',
              type: 'string',
              format: 'hexcolor',
              default: '#000000',
            },
            footerBkgImageUrl: {
              type: 'string',
              title: 'Background Url',
              description: 'Background image on the application footer',
            },
            footerBkgImageOpacity: {
              type: 'number',
              title: 'Background Opacity',
              description: 'The opacity of the background image on the sidebar',
              enum: [1, 0.75, 0.5, 0.25, 0.1],
            },
            borderColor: {
              type: 'string',
              format: 'hexcolor',
              title: 'Border Color',
              description: 'The color of the border above the application footer',
              default: '#000000',
            },
            textColor: {
              type: 'string',
              format: 'hexcolor',
              title: 'Text Color',
            },
          },
        },
        mediaCard: {
          type: 'object',
          title: 'Media Card',
          properties: {
            showMediaPasses: {
              title: 'Show Media Passes Based On Screen Size',
              type: 'string',
              default: 'Mobile and Desktop',
              description: 'Show media passes when viewing Arrival on a mobile-sized, larger screen, or not at all',
              enum: ['Mobile Only', 'Mobile and Desktop', 'None'],
            },
            resortName: {
              type: 'string',
              title: 'Resort Name',
            },
            heroImageUrl: {
              type: 'string',
              title: 'Hero Image Url',
            },
            logoImageUrl: {
              type: 'string',
              title: 'Logo Image Url',
            },
            backgroundColor: {
              type: 'string',
              title: 'Background Color',
              format: 'hexcolor',
            },
            labelColor: {
              type: 'string',
              title: 'Label Color',
              format: 'hexcolor',
            },
            foregroundColor: {
              type: 'string',
              title: 'Foreground Color',
              format: 'hexcolor',
            },
          },
        },
        sidebar: {
          type: 'object',
          title: 'Sidebar',
          properties: {
            backgroundColor: {
              title: 'Background Color',
              type: 'string',
              format: 'hexcolor',
              default: '#000000',
            },
            sidebarBkgImageUrl: {
              type: 'string',
              title: 'Background Url',
              description: 'Background image on the application sidebar',
            },
            sidebarBkgImageOpacity: {
              type: 'number',
              title: 'Background Opacity',
              description: 'The opacity of the background image on the sidebar',
              enum: [1, 0.75, 0.5, 0.25, 0.1],
            },
          },
        },
      },
    },
  },
}

export default schema
