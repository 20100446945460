import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import About from '../views/About.vue'
import AdminRoutes from '@/Admin/routes'
import AuthenticationRoutes from '@/Authentication/routes'
import goTo from 'vuetify/es5/services/goto'
import LocationRoutes from '@/Location/routes'
import ProfileRoutes from '@/Profile/routes'
import Router from 'vue-router'
import store from '../store/index'
import TaskRoutes from '@/Task/routes'
import Vue from 'vue'
import Welcome from '../views/Welcome.vue'
import NotFound from '../views/NotFound.vue'
import AccessDenied from '../views/AccessDenied.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo: any = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: Welcome,
      meta: {
        isPublic: true,
        gtm: 'Welcome',
      },
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters['oidcStore/oidcIsAuthenticated']) {
          next({ name: 'home' })
        } else {
          store.dispatch('oidcStore/authenticateOidcSilent')
          if (store.getters['oidcStore/oidcIsAuthenticated']) {
            next({ name: 'home' })
          } else {
            next({ name: 'welcome-page' })
          }
        }
      },
    },
    {
      path: '/welcome',
      name: 'welcome-page',
      component: Welcome,
      meta: {
        isPublic: true,
        gtm: 'Welcome',
      },
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        isPublic: true,
        gtm: 'About',
      },
    },
    {
      path: '*',
      name: 'Not Found',
      component: NotFound,
      meta: {
        isPublic: true,
        gtm: 'Page Not Found',
      },
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: AccessDenied,
      meta: {
        isPublic: true,
        gtm: 'Access Denied',
      },
    },
    ...AuthenticationRoutes,
    ...LocationRoutes,
    ...ProfileRoutes,
    ...TaskRoutes,
    ...AdminRoutes,
  ],
})
// Do not change the beforeEach ... required for OIDC
router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
router.beforeResolve((to, from, next) => {
  // Do any navigation guards here that you need global across routes
  next()
})

export default router
