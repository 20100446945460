import Vue, { DirectiveOptions } from 'vue'

const VueFocus: DirectiveOptions = {
  // When the bound element is inserted into the DOM...
  inserted(el, node) {
    // Focus the element
    if (el) {
      el.focus()
    }
  },
  update(el, { value, oldValue }, vNode) {
    if (value && value !== oldValue) {
      Vue.nextTick(() => {
        el.focus()
      })
    }
  },
}

export default VueFocus
