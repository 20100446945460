import store from '@/Common/store'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import ArrivalService from '@/Common/services/ArrivalService'
import Collection from '../interfaces/Collection'
import CollectionItem from '../interfaces/CollectionItem'

class CollectionService extends ArrivalService {
  public static async FetchCollections(): Promise<Collection[]> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/collections`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateCollection(collection: Collection): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/collections/${collection.id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: collection,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async AddCollection(collection: Collection): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/collections`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: collection,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async DeleteCollection(id: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/collections/${id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchCollectionItems(): Promise<CollectionItem[]> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/collections/items`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async AddCollectionItem(collectionItem: CollectionItem): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/collections/items`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: collectionItem,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateCollectionItem(collectionItem: CollectionItem): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/collections/items/${collectionItem.id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: collectionItem,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async DeleteCollectionItem(id: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/collections/items/${id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }
}

export default CollectionService
