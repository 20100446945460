import ContactBLEModel from '../models/ContactBLE'
import ContactModel from '../models/Contact'
import { MutationTree } from 'vuex'
import { ProfileState } from './types'

export const mutations: MutationTree<ProfileState> = {
  addContact(state, contact: ContactModel) {
    const oldContacts = state.contacts
    const contactsWithoutNewId = oldContacts ? oldContacts.filter((c) => c.id !== contact.id) : []
    state.contacts = [...contactsWithoutNewId, contact]
  },
  addContactBLE(state, contactBLE: ContactBLEModel) {
    const oldContactBLEs = state.bleContacts
    const contactsWithoutNewId = oldContactBLEs ? oldContactBLEs.filter((c) => c.id !== contactBLE.id) : []
    state.bleContacts = [...contactsWithoutNewId, contactBLE]
  },
  resetContacts(state) {
    state.contacts = []
  },
}
