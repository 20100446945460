import Vue from 'vue'
import store from '@/Common/store/index'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

const routes: any[] = [
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresLocation: true,
      showFooter: true,
      gtm: 'Home',
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/Home.vue'),
  },
  {
    path: '/add-family-member',
    name: 'add-family-member',
    meta: {
      showHeader: false,
      showFooter: true,
      gtm: 'Add Family Member',
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/AddFamilyMember.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['profile/contacts']) {
        next({ name: 'home' })
      }

      next()
    },
  },
  {
    path: '/pass-checker',
    name: 'pass-checker',
    meta: {
      showHeader: false,
      showFooter: true,
      isPublic: true,
      gtm: 'Pass Checker',
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/PassChecker.vue'),
    beforeEnter: (to, from, next) => {
      const hasPassChecker = store.getters.hasFeature('PassChecker')
      if (!hasPassChecker) {
        next({ name: 'Not Found' })
      } else {
        next()
      }
    },
  },
  {
    path: '/ble-connect',
    name: 'ble-connect',
    meta: {
      showHeader: false,
      showFooter: true,
      isPublic: false,
      gtm: 'BLE Connect',
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/BLEConnect.vue'),
    beforeEnter: (to, from, next) => {
      const hasBLEConnect = store.getters.hasFeature('BLEConnect')
      if (!hasBLEConnect) {
        next({ name: 'Not Found' })
      } else {
        // TODO: Remove this if the isPublic flag works to make people sign in
        // if (store.getters['oidcStore/oidcIsAuthenticated']) {
        //   next()
        // } else {
        //   store.dispatch('oidcStore/authenticateOidcSilent')
        //   if (store.getters['oidcStore/oidcIsAuthenticated']) {
        //     next()
        //   } else {
        //     //Need to store the route we were going to so the OIDC Callback knows where to go after sign in
        //     localStorage.setItem('redirectPath', 'ble-connect')
        //     next({ name: 'welcome-page'})
        //   }
        // }
      }
      next()
    },
  },
  {
    path: '/connect',
    name: 'connect',
    meta: {
      showHeader: false,
      showFooter: true,
      isPublic: true,
      gtm: 'BLE Connect',
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/BLEConnect.vue'),
    beforeEnter: (to, from, next) => {
      const hasBLEConnect = store.getters.hasFeature('BLEConnect')
      if (!hasBLEConnect) {
        next({ name: 'Not Found' })
      } else {
        next()
      }
      next()
    },
  },
]

export default routes
