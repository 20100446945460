import ContactTask from '../interfaces/ContactTask'
import ContactTaskGrouping from '../interfaces/ContactTaskGrouping'
import { GetterTree } from 'vuex'
import { RootState } from '@/Common/store/types'
import Task from '../interfaces/Task'
import { TaskState } from './types'
import TaskType from '../interfaces/TaskType'

export const getters: GetterTree<TaskState, RootState> = {
  contactTasks(state): ContactTaskGrouping | null {
    return state.contactTasks
  },
  currentTask(state): Task | null {
    return state.currentTask
  },
  tasks(state): Task[] | null {
    return state.tasks
  },
  taskTypes(state): TaskType[] | null {
    return state.taskTypes
  },
}
