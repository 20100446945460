import { GetterTree } from 'vuex'
import { AdminState } from './types'
import { RootState } from '@/Common/store/types'

export const getters: GetterTree<AdminState, RootState> = {
  settings(state): any {
    return state.adminSettings
  },
  emailAccess(state): any {
    return state.emailAccess
  },
}
