import { ActionTree } from 'vuex'
import { AdminState } from './types'
import { RootState } from '@/Common/store/types'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'
import AdminEmailAccess from '../interfaces/AdminEmailAccess'

export const actions: ActionTree<AdminState, RootState> = {
  async fetchAdminSettings({ commit }): Promise<AdminSetting | null> {
    const adminSettingsResponse = await AdminService.FetchAdminSettings()
    const adminSettings = adminSettingsResponse || null

    commit('setAdminSettings', adminSettings)

    return adminSettings
  },
  async fetchAdminEmailAccess({ commit }): Promise<AdminEmailAccess[] | null> {
    const response = (await AdminService.FetchAdminEmailAccess()) || null

    commit('setAdminEmailAccess', response)

    return response
  },
  reset({ commit }): any {
    commit('resetSettings')
  },
}
