<template>
  <div id="checkIn">
    <template v-if="currentTask">
      <v-container>
        <v-row>
          <v-spacer />
          <v-col cols="12" md="8">
            <router-view :contactTasks="currentContactTasks" @complete-task="onCompleteTask"></router-view>
            <div class="button-wrapper">
              <v-btn @click="onCompleteTask" color="primary" v-if="showCompleteButton" rounded>Complete</v-btn>
            </div>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import ChildRegistrationTask from '../components/ChildRegistrationTask.vue'
import ContactTask from '../interfaces/ContactTask'
import LocationModel from '../../Location/models/Location'
import PhotoTask from '../components/PhotoTask.vue'
import RentalProfileTask from '../components/RentalProfileTask.vue'
import TaskService from '../../Task/services/TaskService'
import WaiverTask from '../components/WaiverTask.vue'
import type Task from '../interfaces/Task'
import type ContactTaskGrouping from '../interfaces/ContactTaskGrouping'
import TaskType from '../enums/TaskTypeEnum'

@Component({
  components: {
    WaiverTask,
    PhotoTask,
    ChildRegistrationTask,
    RentalProfileTask,
  },
})
export default class CheckIn extends Vue {
  private currentTaskIndex: number = 0
  private allTasksComplete: boolean = false
  private TaskTypeCol = {
    1: 'Waiver',
    2: 'RentalProfile',
    3: 'ChildRegistration',
    4: 'Photo',
  }

  @Action('task/advanceTask') advanceTask
  @Action('task/completeCurrentTask') completeCurrentTask
  @Getter('task/contactTasks') contactTasks: ContactTaskGrouping
  @Getter('task/currentTask') currentTask

  @Watch('currentTask')
  onPropertyChanged(value: Task, oldValue: Task) {
    if (!value && oldValue) {
      this.$router.push({ name: 'checkInComplete' }).catch(() => {
        // .. Nothing yet
      })
    }
  }

  @Watch('currentTaskType', { immediate: true })
  onTaskTypeChanged(value: string, oldValue: string) {
    if (value) {
      if (this.$route.name == 'checkIn') {
        this.$router.replace({ name: `checkin-${value}` })
      } else {
        this.$router.push({ name: `checkin-${value}` }).catch(() => {
          // Nothing yet
        })
      }
    }
  }

  serviceError: ServiceError | null = null

  get currentTaskType() {
    if (this.currentTask && this.currentTask.taskTypeId) {
      const typeId = this.currentTask.taskTypeId
      return `${this.TaskTypeCol[typeId]}`
    } else {
      return null
    }
  }

  get currentTaskComponent() {
    const typeId = this.currentTask.taskTypeId
    return `${this.TaskTypeCol[typeId]}Task`
  }

  get showCompleteButton() {
    if (
      this.currentContactTasks!.filter((t) => (t.isComplete || t.isOptional) && t.isReadOnly).length ==
      this.currentContactTasks!.length
    ) {
      return true
    }

    return false
  }

  // Computed property to return only those contact tasks for the current task ID
  get currentContactTasks() {
    if (this.contactTasks) {
      switch (this.currentTask.taskTypeId) {
        case TaskType.Photo: {
          return this.contactTasks.photoContactTasks
          break
        }
        case TaskType.ChildRegistrationProfile: {
          return this.contactTasks.childRegistrationContactTasks
          break
        }
        case TaskType.RentalProfile: {
          return this.contactTasks.rentalProfileContactTasks
          break
        }
        case TaskType.Waiver: {
          if (this.contactTasks && this.contactTasks.waiverContactTasks) {
            return this.contactTasks.waiverContactTasks.filter(
              (ct) => ct.taskId == this.currentTask.id && !ct.isComplete
            )
          }
          break
        }
        default: {
          return []
          break
        }
      }
    }
  }

  private onCompleteTask() {
    this.advanceTask()
  }
}
</script>
<style lang="scss" scoped>
#checkIn {
  min-height: calc(100vh - 100px);
  max-width: 100vw;
  overflow: hidden;
  background: url('@public/img/screen-bg--bigsky.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  // Can't decide between image background and light color. Going with the following light color for now
  background: #f7f7f7;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    font-weight: 510;
    color: #333;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    padding-right: 1rem;
    bottom: 1rem;
    left: 0;
    right: 0;

    .ax--btn--primary {
      outline: none;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      font-weight: 700;
    }

    @include breakpoint('tablet') {
      bottom: 3rem;
      right: 3rem;
    }
  }
}
</style>
