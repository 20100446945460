import ArrivalService from '@/Common/services/ArrivalService'
import CreateEticketRequest from '../interfaces/CreateETicketRequest'
import ETicketError from '../models/ETicketError'
import ETicketStatus from '../enums/ETicketStatus'
import ETicketSummary from '../models/ETicketSummary'
import FulfillmentTransaction from '../models/FulfillmentTransaction'
import GetBLEDataRequest from '../interfaces/getBLEDataRequest'
import SendBleLinkRequest from '../interfaces/sendBleLinkRequest'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import store from '@/Common/store'

class MediaService extends ArrivalService {
  private static convertFulfillmentRespsonse(data: any): FulfillmentTransaction {
    const contactId = data.confirmations[0]?.contactId?.ids[0]?.infoRecId || ''
    const orderId = data.confirmations[0]?.orderId?.ids[0]?.infoRecId || ''
    const transactionId = data.confirmations[0]?.transactionId?.ids[0]?.infoRecId || ''
    return new FulfillmentTransaction(contactId, orderId, transactionId)
  }

  private static convertBLEResponse(data: any): ETicketSummary {
    const hasBLEMedia = !!data.id
    const hasSentLink = !!data.delivery && data.delivery?.isSent
    const hasConnectedMedia = !!data.download && !!data.download?.device

    const bleInfo: any = {
      ...data,
      status: ETicketStatus.Unknown,
    }

    if (!hasBLEMedia) {
      bleInfo.status = ETicketStatus.NoMedia
    } else if (hasConnectedMedia) {
      bleInfo.status = ETicketStatus.Connected
    } else if (!hasSentLink) {
      bleInfo.status = ETicketStatus.Unsent
    } else if (hasSentLink && !hasConnectedMedia) {
      bleInfo.status = ETicketStatus.Sent
    }

    return bleInfo
  }

  public static async FetchBLEData(ipCode: string): Promise<ETicketSummary | any> {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/media/contactmedia?ipcode=${ipCode}`,
      })
      // Only return data object if status is 200, else empty object
      return this.convertBLEResponse(data)
    } catch (error) {
      const ticketError: ETicketError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      return [ticketError]
    }
  }

  public static async SendBleLink(request: SendBleLinkRequest): Promise<any> {
    try {
      const baseUrl = store.getters['settings/baseUrl']

      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/media/updateeticketdelivery`,
        data: request,
      })

      if (status === 200) {
        return true
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return true
  }

  public static async ActivateEticket(ticketId: string): Promise<any> {
    try {
      const baseUrl = store.getters['settings/baseUrl']

      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/media/ble-activation?transactionId=${ticketId}`,
      })

      return status === 200
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return false
  }

  public static async CreateEticket(id: string): Promise<any> {
    try {
      const baseUrl = store.getters['settings/baseUrl']

      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/media/ble?customerId=${id}`,
      })

      if (status === 200) {
        return this.convertFulfillmentRespsonse(data)
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return true
  }
}

export default MediaService
