import Vue from 'vue'
import PhotoTask from '../Task/components/PhotoTask.vue'
import RentalProfileTask from '../Task/components/RentalProfileTask.vue'
import ChildRegistrationTask from '../Task/components/ChildRegistrationTask.vue'
import CheckIn from './views/CheckIn.vue'
import store from '../Common/store/index'

const routes: any[] = [
  {
    path: '/checkin',
    name: 'checkIn',
    meta: {
      title: 'Check In',
      description: 'Check In',
      showHeader: true,
      hidePageBackground: true,
      gtm: 'Check-In',
    },
    props: true,
    component: CheckIn,
    beforeEnter: (to, from, next) => {
      if (!store.getters['task/contactTasks']) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
    children: [
      {
        path: '/checkin/photo',
        component: PhotoTask,
        name: 'checkin-Photo',
        meta: {
          title: 'Check In',
          description: 'Photo',
          showHeader: true,
          hidePageBackground: true,
          gtm: 'Photo',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('task/setCurrentTaskByRoute', to.name)
          next()
        },
      },
      {
        path: '/checkin/waiver',
        component: () => import(/* webpackChunkName: "checkIn" */ '../Task/components/WaiverTask.vue'),
        name: 'checkin-Waiver',
        props: true,
        meta: {
          title: 'Check In',
          description: 'Waivers',
          showHeader: false,
          hidePageBackground: true,
          gtm: 'Waiver',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('task/setCurrentTaskByRoute', to.name)
          next()
        },
      },
      {
        path: '/checkin/rentalprofile',
        component: RentalProfileTask,
        name: 'checkin-RentalProfile',
        props: true,
        meta: {
          title: 'Check In',
          description: 'Rental Info',
          showHeader: true,
          hidePageBackground: true,
          gtm: 'Rental Profile',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('task/setCurrentTaskByRoute', to.name)
          next()
        },
      },
      {
        path: '/checkin/childregistration',
        component: ChildRegistrationTask,
        name: 'checkin-ChildRegistration',
        props: true,
        meta: {
          title: 'Check In',
          description: 'Child Registration',
          showHeader: true,
          hidePageBackground: true,
          gtm: 'Child Registration',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('task/setCurrentTaskByRoute', to.name)
          next()
        },
      },
    ],
  },
  {
    path: '/checkinComplete',
    name: 'checkInComplete',
    component: () => import(/* webpackChunkName: "checkIn" */ './views/CheckInComplete.vue'),
    meta: {
      title: 'Check In',
      description: 'Complete',
      showHeader: false,
      hidePageBackground: false,
      gtm: 'Check-In Complete',
    },
  },
]

export default routes
