<template>
  <div id="admin-activities">
    <div id="activity-list" v-if="tasks">
      <v-card class="pa-2">
        <v-card-title class="pt-2">Task List</v-card-title>
        <v-card-subtitle class="description">Click the box to view or edit task details.</v-card-subtitle>
        <v-data-table
          :headers="tasksTableHeaders"
          :items="tasks"
          :items-per-page="10"
          @item-selected="onTaskSelected"
          show-select
          single-select
        >
          <template v-slot:item.taskTypeId="{ item }">
            <span class="task-description" v-if="item.taskTypeId == 1">
              <v-icon>mdi-file-document-edit-outline</v-icon>
              Waiver
            </span>
            <span class="task-description" v-else-if="item.taskTypeId == 2">
              <v-icon>mdi-clipboard-text-outline</v-icon>
              Rental Profile
            </span>
            <span class="task-description" v-else-if="item.taskTypeId == 3">
              <v-icon>mdi-face-profile</v-icon>
              Child Profile
            </span>
            <span class="task-description" v-else-if="item.taskTypeId == 4">
              <v-icon>mdi-camera-outline</v-icon>
              Photo
            </span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-card id="task-details" v-if="currentTask" class="mt-8">
      <v-card-title class="padding-bottom-15">{{ currentTask.name }} Task</v-card-title>
      <v-card-text>
        <v-form v-model="updateTaskValid" ref="updateTaskForm">
          <v-jsf v-model="currentTask" :schema="taskSchema" :options="newTaskFormOptions" />
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="updateTask" :loading="updatingTask"
          >Update Task <v-icon>mdi-upload</v-icon></v-btn
        >
        <v-spacer />
        <v-btn color="error" @click="removeTask" :loading="deletingTask">Delete Task <v-icon>mdi-delete</v-icon></v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="pa-2 mt-8" v-else>
      <v-card-title class="pt-2">Add New Task</v-card-title>
      <v-card-subtitle>Complete the following information to add a new task.</v-card-subtitle>
      <v-card-text>
        <v-form v-model="newTaskValid" ref="newTaskForm">
          <v-jsf v-model="newTask" :schema="taskSchema" :options="newTaskFormOptions"> </v-jsf>
        </v-form>
        <v-alert color="red" icon="mdi-alert-octagon-outline" v-if="!$v.newTask.validWaivers"
          >At least one signature method is required</v-alert
        >
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="addTask" :loading="updatingTask" large
          >Add Task<v-icon>mdi-upload</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import ActivityProduct from '../../Activity/interfaces/ActivityProduct'
import ActivityTask from '../../Activity/interfaces/ActivityTask'
import ActivityService from '../../Activity/services/ActivityService'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'
import Task from '../../Task/interfaces/Task'
import TaskType from '../../Task/interfaces/TaskType'
import TaskService from '../../Task/services/TaskService'

@Component
export default class AdminTasks extends Vue {
  @Action('task/fetchTasks')
  private fetchTasks: any
  @Getter('task/tasks')
  private tasks?: []
  @Action('task/fetchTaskTypes')
  private fetchTaskTypes: any
  @Getter('task/taskTypes')
  private taskTypes?: []

  private currentTask: Task | null = null
  private newTask: Task | {} = {}
  private taskTypeListOptions = []
  private newTaskValid: any = false
  private updateTaskValid: any = false
  private deletingTask: boolean = false
  private updatingTask: boolean = false

  $refs: {
    newTaskForm: HTMLFormElement
  }

  onTaskSelected({ item: selTask, value: selected }) {
    if (selTask && selected) {
      const modifiedTask = {
        ...selTask,
        startEffectiveDate:
          selTask.startEffectiveDate != null ? new Date(selTask.startEffectiveDate).toISOString().substr(0, 10) : '',
        endEffectiveDate:
          selTask.endEffectiveDate != null ? new Date(selTask.endEffectiveDate).toISOString().substr(0, 10) : '',
        validationDate:
          selTask.validationDate != null ? new Date(selTask.validationDate).toISOString().substr(0, 10) : '',
        taskTypeId: selTask.taskTypeId,
      }
      Vue.nextTick(() => {
        this.currentTask = modifiedTask
      })
    } else {
      this.currentTask = null
    }
  }

  private taskSchema = {
    required: ['name'],
    type: 'object',
    title: 'Select the Task Type',
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
        default: 0,
      },
      name: {
        title: 'Name',
        type: 'string',
        default: '',
      },
      ageMin: {
        title: 'Minimum Age',
        type: 'number',
      },
      ageMax: {
        title: 'Maximum Age',
        type: 'number',
      },
      isOptional: {
        title: 'This task is optional',
        type: 'boolean',
        'x-display': 'switch',
      },
    },
    oneOf: [
      {
        title: 'Waiver',
        required: ['posWaiverId'],
        properties: {
          taskTypeId: {
            type: 'number',
            const: 1,
          },
          posWaiverId: {
            title: 'POS Waiver Id',
            type: 'string',
          },
          externalWaiverId: {
            title: 'External Waiver Id',
            type: 'string',
            default: '',
          },
          consolidateWaivers: {
            title: 'Consolidate Waivers',
            type: 'boolean',
            'x-display': 'switch',
          },
          useTodayAsValidationDate: {
            title: 'One Day Waiver',
            description: 'This will set the effective date, expiration date, and validation date to the current date',
            type: 'boolean',
            'x-display': 'switch',
          },
          startEffectiveDate: {
            title: 'Effective Date',
            type: 'string',
            format: 'date',
          },
          endEffectiveDate: {
            title: 'Expiration Date',
            type: 'string',
            format: 'date',
          },
          validationDate: {
            title: 'Validation Date',
            type: 'string',
            format: 'date',
          },
          enableAgreeCheckbox: {
            title: 'Enable Agree Checkbox',
            type: 'boolean',
            'x-display': 'switch',
          },
          enableTextSignature: {
            title: 'Enable Text Signature',
            type: 'boolean',
            'x-display': 'switch',
          },
          enableWetSignature: {
            title: 'Enable Wet Signature',
            type: 'boolean',
            'x-display': 'switch',
          },
          customComment: {
            title: 'Custom Comment (Tokens: {Signer}, {SignedDate}, {BrowserName} {BrowserVersion})',
            type: 'string',
            default: '',
          },
        },
      },
      {
        title: 'Rental Profile',
        properties: {
          taskTypeId: {
            type: 'number',
            const: 2,
          },
        },
      },
      {
        title: 'Child Registration Profile',
        properties: {
          taskTypeId: {
            type: 'number',
            const: 3,
          },
        },
      },
      {
        title: 'Photo',
        properties: {
          taskTypeId: {
            type: 'number',
            const: 4,
          },
        },
      },
    ],
  }

  private newTaskFormOptions = {
    initialValidation: 'none',
    removeAdditionalProperties: false,
  }

  public created() {
    if (!this.tasks) {
      this.getTasks()
    }

    if (!this.taskTypes) {
      this.getTaskTypes()
    }
  }

  private validations() {
    return {
      newTask: {
        validWaivers(value) {
          if (value.taskTypeId === 1) {
            return !!value.enableAgreeCheckbox || !!value.enableTextSignature || !!value.enableWetSignature
          }

          return true
        },
      },
    }
  }

  public getTasks() {
    this.fetchTasks()
  }

  public getTaskTypes() {
    this.fetchTaskTypes()
  }

  public async addTask() {
    this.$refs.newTaskForm.validate()

    if (this.newTaskValid && this.$v.newTask.validWaivers) {
      this.updatingTask = true
      await TaskService.AddTask(this.newTask as Task)
      this.$set(this, 'newTask', { id: 0, name: '' })
      this.$refs.newTaskForm.reset()
      this.getTasks()
      this.updatingTask = false
    }
  }

  public async updateTask() {
    if (this.updateTaskValid) {
      this.updatingTask = true
      await TaskService.UpdateTask(this.currentTask as Task)
      this.getTasks()
      this.updatingTask = false
    }
  }

  public async removeTask() {
    if (this.currentTask) {
      this.deletingTask = true
      await TaskService.RemoveTask(this.currentTask.id)
      this.getTasks()
      this.currentTask = null
      this.deletingTask = false
    }
  }

  get tasksTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'taskTypeId' },
      { text: 'Modified Date', value: 'modifiedDate', align: 'center' },
    ]
  }
}
</script>

<style lang="scss" scoped>
.v-btn + .v-btn {
  margin-left: 2rem;
}
.v-alert {
  color: white;
}
.task-description {
  font-size: 0.75rem;
  .v-icon {
    margin-right: 5px;
  }
}
</style>
