import { MutationTree } from 'vuex'
import { SettingsState } from './types'
import { Settings } from '../interfaces/Settings'
import { OneRiskSettings } from '../interfaces/OneRiskSettings'

export const mutations: MutationTree<SettingsState> = {
  addSettings(state, settings: Settings) {
    state.appSettings = settings.frontEndSettings
    state.environment = settings.environment
    state.oneRiskSettings = {
      'oneRiskUrl': settings.oneRiskUrl,
      'oneRiskSecret': settings.oneRiskSecret,
      'pointOfSaleSystem': settings.pointOfSaleSystem,
      'dateFormat': settings.dateFormat
    }
    if (settings.languageSettings) {
      state.languageSettings = JSON.parse(settings.languageSettings)
    }
    if (settings.themeSettings) {
      state.themeSettings = JSON.parse(settings.themeSettings)
    }

  },
}
