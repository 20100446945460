import ETicketDeliveryMode from '../enums/ETicketDeliveryMode';

export default class ETicketDelivery {
  constructor(
    /// BLE ticket delivery mode, ex. email or sms
    public deliveryMode: ETicketDeliveryMode | null,
    // Recipient's email or phone number
    public recipient: string | null,
    // Determines wheter the BLE ticket was sent via either sms or text
    public isSent: boolean,
    public createDateUtc: Date | null,
    public updateDateUtc: Date |null
  ) { }
}
