<template>
  <div class="ax--select">
    <label for="select-id" class="ax--label" v-if="label">{{ label }}</label>
    <div class="ax--select-input__wrapper">
      <select id="select-id" class="ax--select-input" v-model="selectedOptionValue" :disabled="disabled" :size="size">
        <option class="ax--select-option" :value="null" disabled selected :hidden="!placeholder">{{
          placeholder
        }}</option>
        <option
          v-for="option in selectOptions"
          :key="option.value"
          class="ax--select-option"
          :value="option[optionKey]"
          >{{ option[optionText] }}</option
        >
      </select>
      <svg
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        style="will-change: transform;"
        xmlns="http://www.w3.org/2000/svg"
        class="ax--select__arrow"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        aria-hidden="true"
      >
        <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
      </svg>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Model, PropSync, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AxSelect extends Vue {
  @Prop({ type: String, default: null }) label: string | null
  @Prop({ type: String, default: 'value' }) optionKey: string
  @Prop({ type: String, default: 'text' }) optionText: string
  @Prop({ type: String, default: 'Choose an option' }) placeholder: string
  @Prop({ type: Array, default: null }) selectOptions: [] | null
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: String, default: null }) size: string | null
  @Model('update:value', { type: Number, required: false }) readonly value!: number | null
  @PropSync('value', { default: null, required: false }) selectedOptionValue!: number | null

  @Watch('selectedOptionValue')
  onSelectedOptionChanged(value: any) {
    if (this.selectOptions) {
      const selectedOption = this.selectOptions.find((opt) => opt[this.optionKey] === value)
      this.$emit('select-changed', selectedOption)
    }
  }

  get componentClasses() {
    const classes: string[] = []
    classes.push(`size--${this.size}`)
    return classes.join(' ')
  }
}
</script>
<style lang="scss" scoped>
[hidden] {
  display: none;
}
.ax--select {
  width: auto;

  &.error {
    select {
      border: var(--select-error-border, 1px solid red);
      box-shadow: var(--select-error-shadow, 0px 0px 2px red);
    }
  }
}
.ax--select *,
.ax--select ::after,
.ax--select ::before {
  box-sizing: inherit;
}
.ax--select-input__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}
.ax--select-input {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.125rem);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  height: 2.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: var(--select-width, 14em);
  min-width: var(--select-min-width, 8em);
  max-width: var(--select-max-width, 28em);
  padding: 0 var(--cds-spacing-09, 3rem) 0 var(--cds-spacing-05, 0.25rem);
  color: var(--cds-text-01, #161616);
  background-color: var(--cds-field-01, #f4f4f4);
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  border-radius: var(--select-border-radius, 0);
  cursor: pointer;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  &:disabled,
  &[disabled] {
    opacity: 0.5 !important;
  }
  &:focus {
    box-shadow: 0 0 0 2pt var(--input-focus-color, blue);
  }
}

.ax--select-optgroup,
.ax--select-option {
  color: var(--cds-text-01, #161616);
}
.ax--select-option[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.ax--select-option {
  background-color: var(--cds-ui-01, #f4f4f4);
}
.ax--select__arrow {
  fill: var(--cds-ui-05, #161616);
  position: absolute;
  right: var(--cds-spacing-05, 1rem);
  pointer-events: none;
}
.ax--label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1rem);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  color: var(--cds-text-02, #393939);
  font-weight: 400;
  display: inline-block;
  vertical-align: initial;
  margin: 0 0 0.5rem;
  line-height: 1rem;
}
</style>
