<template>
  <div id="admin-reporting" class="admin--page">
    <v-card class="pa-2">
      <v-card-title class="pt-2">API Access Keys</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Key Name</th>
                <th>API Key</th>
                <th>Type</th>
                <th>Date Created</th>
                <th class="text-right">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="apiKey in apiKeys" :key="apiKey.id">
                <td>{{ apiKey.nickname }}</td>
                <td>{{ apiKey.token }}</td>
                <td>{{ apiKey.type }}</td>
                <td>{{ apiKey.createdDateUtc | shortDate }}</td>
                <td class="text-right">
                  <v-btn @click="removeApiKey(apiKey.id)" text>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br />
        <v-btn @click="toggleShowCreateTokenForm" v-if="!showCreateTokenForm"> Create API Token </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="pa-2" v-if="showCreateTokenForm">
      <v-card-title class="pt-2">Create API Token</v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="newApiKey.nickname" label="Token Nickname" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn :loading="saving" @click="addApiKey">Create Token</v-btn>
              <v-btn :loading="saving" @click="toggleShowCreateTokenForm">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="pa-2">
      <v-card-title class="pt-2">API Default Settings</v-card-title>
      <v-card-text>
        <v-form v-if="settings">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="settings.daysBeforeCurrentDay"
                label="Days Before Current Day"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="settings.daysAfterCurrentDay"
                label="Days After Current Day"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn :loading="saving" @click="updateSettings"
                >Update Default Settings <v-icon>mdi-upload</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="pa-2">
      <v-card-title>Available API Endpoints & Documentation</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <p>
              All API requests require a http header field shown below:
              <br />
              <code> headers: { 'x-api-key':'[YOUR_API_KEY]'}} </code>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-expansion-panels accordian>
              <v-expansion-panel>
                <v-expansion-panel-header>GET /export/customers</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    This endpoint returns a list of customers that have upcoming reservations based on your
                    point-of-sale system
                  </p>
                  <h4>Parameters</h4>
                  <ul>
                    <li>locationId - number</li>
                    <li>includePrintableMedia - boolean</li>
                    <li>
                      <i>(optional)</i> daysBeforeCurrentDate - number
                      <br />
                      <i
                        >If you do not provide a value for this parameter. The endpoint will use the value in the 'API
                        Default Settings' section above</i
                      >
                    </li>
                    <li>
                      <i>(optional)</i> daysAfterCurrentDate - number
                      <br />
                      <i
                        >If you do not provide a value for this parameter. The endpoint will use the value in the 'API
                        Default Settings' section above</i
                      >
                    </li>
                    <li>
                      <i>(optional)</i> includeDetails - boolean
                      <br />
                      <i>Default value is true</i>
                    </li>
                  </ul>
                  <br />
                  <h4>JavaScript Example</h4>
                  <code>
                    $http({method: 'GET', url:
                    '{BASE_URL}/api/export/customers?locationId={LOCATION_ID}&includePrintableMedia={INCLUDE_PRINTABLE_MEDIA}',
                    headers: { 'x-api-key':'[YOUR_API_KEY]'}});
                  </code>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ApiKey from '../../Api/interfaces/ApiKey'
import ApiService from '../../Api/services/ApiService'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminApi extends Vue {
  private saving: boolean = false
  private showCreateTokenForm: boolean = false
  private panel = [0]
  private newApiKey: ApiKey = {
    id: null,
    createdDateUtc: new Date(),
    token: null,
    nickname: '',
    type: 'standard',
  }
  private apiKeys: ApiKey[] = []
  private settings: AdminSetting | null = null

  // Methods
  public created() {
    this.getApiKeys()
    this.getSettings()
  }

  private async getApiKeys() {
    this.apiKeys = await ApiService.FetchApiKeys()
  }

  private async getSettings() {
    this.settings = await AdminService.FetchAdminSettings()
  }

  private async updateSettings() {
    if (this.settings) {
      await AdminService.UpdateAdminSettings(this.settings)
      await this.getSettings()
    }
  }

  private async addApiKey() {
    await ApiService.CreateApiKey(this.newApiKey)
    await this.getApiKeys()
    this.newApiKey.nickname = ''
    this.showCreateTokenForm = false
  }

  private async removeApiKey(id: number) {
    await ApiService.RemoveApiKey(id)
    await this.getApiKeys()
  }

  private toggleShowCreateTokenForm() {
    this.showCreateTokenForm = !this.showCreateTokenForm
  }
}
</script>

<style lang="scss" scoped></style>
