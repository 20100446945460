import Vue from 'vue'
import Home from './views/Home.vue'
import Locations from './components/AdminLocations.vue'
import TaskTypes from './components/AdminTaskTypes.vue'
import Tasks from './components/AdminTasks.vue'
import Activities from './components/AdminActivities.vue'
import Access from './components/AdminAccess.vue'
import Language from './components/AdminLanguage.vue'
import Theme from './components/AdminTheme.vue'
import Settings from './components/AdminGeneralSettings.vue'
import Reporting from './components/AdminReporting.vue'
import Api from './components/AdminApi.vue'
import Collections from './components/AdminCollections.vue'
import AdminService from '@/Admin/services/AdminService'

const checkAdminAccess = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    try {
      if (!(await AdminService.IsAdminAuthenticated())) {
        next({ name: 'access-denied' })
      } else {
        next()
      }
    } catch (error) {
      next({ name: 'access-denied' })
    }
  }
}

const routes: any[] = [
  {
    path: '/admin',
    name: 'admin-home',
    component: () => import(/* webpackChunkName: "admin" */ './views/Home.vue'),
    redirect: '/admin/tasks',
    meta: {
      isPublic: false,
      requiresAdminAuth: true,
    },
    beforeEnter: checkAdminAccess,
    children: [
      {
        path: '/admin/locations',
        name: 'admin-locations',
        component: Locations,
        meta: {
          title: 'Locations',
        },
      },
      {
        path: '/admin/tasktypes',
        name: 'admin-task-types',
        component: TaskTypes,
        meta: {
          title: 'Task Types',
        },
      },
      {
        path: '/admin/tasks',
        name: 'admin-tasks',
        component: Tasks,
        meta: {
          title: 'Tasks',
        },
      },
      {
        path: '/admin/activities',
        name: 'admin-activities',
        component: Activities,
        meta: {
          title: 'Activites',
        },
      },
      {
        path: '/admin/language',
        name: 'admin-language',
        component: Language,
        meta: {
          title: 'Language',
        },
      },
      {
        path: '/admin/theme',
        name: 'admin-theme',
        component: Theme,
        meta: {
          title: 'Theme',
        },
      },
      {
        path: '/admin/access',
        name: 'admin-access',
        component: Access,
        meta: {
          title: 'Access',
        },
      },
      {
        path: '/admin/settings',
        name: 'admin-settings',
        component: Settings,
        meta: {
          title: 'General',
        },
      },
      {
        path: '/admin/reporting',
        name: 'admin-reporting',
        component: Reporting,
        meta: {
          title: 'Reporting',
        },
      },
      {
        path: '/admin/api',
        name: 'admin-api',
        component: Api,
        meta: {
          title: 'API',
        },
      },
      {
        path: '/admin/collections',
        name: 'admin-collections',
        component: Collections,
        meta: {
          title: 'Collections',
        },
      },
    ],
  },
]

export default routes
