<template>
  <div id="admin-settings" class="float-container">
    <v-row>
      <v-col class="mb-6">
        <div v-if="emailAccessRecords" id="admin-email-access">
          <div>
            <v-card class="pa-2">
              <v-card-title>Email List</v-card-title>
              <v-card-subtitle class="description">Click the box to view or edit Email details.</v-card-subtitle>
              <v-data-table
                v-model="selectedEmails"
                :headers="emailsTableHeaders"
                :items="emailAccessRecords"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                hide-default-footer
                class="elevation-1"
                show-select
                single-select
                @page-count="pageCount = $event"
              >
              </v-data-table>
              <div class="text-center pa-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                <v-text-field
                  :value="itemsPerPage"
                  label="Items per page"
                  type="number"
                  min="-1"
                  max="15"
                  @input="setItemsPerPage"
                ></v-text-field>
              </div>
            </v-card>
            <v-card id="task-details" v-if="currentEmail" class="mt-8">
              <v-card-title class="padding-bottom-15">{{ currentEmail.email }}</v-card-title>
              <v-container :fluid="true" class="pa-0">
                <v-row>
                  <v-col md="6">
                    <v-card elevation="0" class="pa-0">
                      <v-card-text>
                        <v-form v-model="currentAdminEmailValid" ref="currentEmailForm">
                          <v-jsf v-model="currentEmail" :schema="adminEmailSchema" :options="emailFormOptions" />
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="pa-4">
                        <v-layout align-content-space-between>
                          <v-btn color="error" @click="removeEmailAccess">
                            <v-icon>mdi-delete</v-icon> Remove Email
                          </v-btn>
                        </v-layout>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card class="pa-2" v-else>
              <v-card-title>Add New Email</v-card-title>
              <v-card-subtitle class="description">Add a specific email to this of admins.</v-card-subtitle>
              <v-card-text>
                <v-form v-model="newAdminEmailValid" ref="newEmailForm">
                  <v-jsf v-model="newAdminEmailAccess" :schema="adminEmailSchema" :options="emailFormOptions" />
                </v-form>
              </v-card-text>

              <v-card-actions class="pa-4">
                <v-btn @click="addEmailAccess">Add Email</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminEmailAccess from '../interfaces/AdminEmailAccess'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminAccess extends Vue {
  @Action('admin/fetchAdminEmailAccess')
  private fetchAdminEmailAccess: any

  private newAdminEmailValid: any = false
  private currentAdminEmailValid: any = false

  $refs: {
    newEmailForm: HTMLFormElement
  }

  // Life Cycle
  public created() {
    this.getEmailAccessRecords()
  }

  // Watchers
  @Watch('selectedEmails')
  onSelectedEmailsChanged(value: AdminEmailAccess[] | null) {
    if (value && value.length > 0) {
      const firstValue = value[0]
      this.currentEmail = {
        ...firstValue,
      }
    } else {
      this.currentEmail = null
    }
  }

  // Data Elements
  private itemsPerPage: Number = 10
  private page: Number = 1
  private pageCount: Number = 0
  private emailAccessRecords: null = null
  private selectedEmails: AdminEmailAccess[] = []
  private currentEmail: AdminEmailAccess | null = null

  private newAdminEmailAccess: AdminEmailAccess | null = {
    id: 0,
    email: '',
    createdDateUtc: new Date(),
    modifiedDateUtc: new Date(),
    isDeleted: false,
  }

  private emailFormOptions = {
    initialValidation: 'none',
  }

  private adminEmailSchema = {
    required: ['email'],
    properties: {
      id: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      email: {
        title: 'Email',
        type: 'string',
        default: '',
      },
    },
  }

  // Computed Values
  get emailsTableHeaders() {
    return [
      { text: 'Email', value: 'email' },
      { text: '', value: '' },
    ]
  }

  // Methods
  public async getEmailAccessRecords() {
    this.emailAccessRecords = await this.fetchAdminEmailAccess()
  }

  private async addEmailAccess() {
    this.$refs.newEmailForm.validate()

    if (this.newAdminEmailValid) {
      await AdminService.AddAdminEmailAccess(this.newAdminEmailAccess)
      await this.getEmailAccessRecords()
      this.$refs.newEmailForm.reset()
    }
  }

  private async removeEmailAccess() {
    if (this.selectedEmails.length > 0) {
      const firstValue = this.selectedEmails[0]
      await AdminService.DeleteAdminEmailAccess(firstValue?.id)
      await this.getEmailAccessRecords()
      this.currentEmail = null
    }
  }

  private async setItemsPerPage(event) {
    // Default items per page to 10 if a non-integer was entered
    this.itemsPerPage =
      !isNaN(event) && Number(event) == event && !isNaN(parseInt(event, 10)) ? parseInt(event, 10) : 10
  }

  public viewEmailDetails(email: AdminEmailAccess) {
    this.currentEmail = email
  }
}
</script>

<style lang="scss" scoped>
.v-btn + .v-btn {
  margin-left: 2rem;
}
</style>
