import Activity from '../interfaces/Activity'
import type ActivityContact from '../interfaces/ActivityContact'
import { ActivityState } from './types'
import ActivityTasks from '../interfaces/ActivityTasks'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<ActivityState> = {
  addActivityTasks(state, newTasks: ActivityTasks) {
    // tslint:disable-next-line:max-line-length
    const exists = state.activityTasks
      ? state.activityTasks.find((at) => at.activity.id == newTasks.activity.id)
      : false
    if (!exists) {
      const oldTasks = state.activityTasks || []
      const newActivityTasks = [...oldTasks, newTasks]
      state.activityTasks = newActivityTasks
    }
  },
  setActivityContacts(state, actContacts: ActivityContact[]) {
    state.activityContacts = actContacts
  },
  setActivities(state, activities: Activity[]) {
    state.activities = activities
  },
  resetActivityContacts(state) {
    state.activityContacts = null
  },
}
