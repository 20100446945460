import Location from '../interfaces/location'
import store from '@/Common/store'
import LocationActivity from '../interfaces/LocationActivity'
import ArrivalService from '@/Common/services/ArrivalService'

class LocationService extends ArrivalService{
  public static async FetchLocations(): Promise<Location[] | null> {
    let response: Location[] | any
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        url: `${baseUrl}/api/locations`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        response = data
      }
      return response
    } catch (error) {
      response = [
        {
          error: error.response as object,
          flag: false as boolean,
          generalError: 'An error happened' as string,
        },
      ]
      return response
    }
  }
  public static async AddLocation(location: Location) {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'post',
        url: `${baseUrl}/api/locations`,
        data: location,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })
    } catch (error) {
      // ...
    }
  }
  public static async UpdateLocation(location: Location) {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/locations/${location.id}`,
        data: location,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })
    } catch (error) {
      // ...
    }
  }
  public static async AddLocationActivity(locationActivity: LocationActivity) {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'post',
        url: `${baseUrl}/api/locations/${locationActivity.locationId}/activities`,
        data: locationActivity,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })
    } catch (error) {
      // ...
    }
  }

  public static async RemoveLocationActivity(locationId: number, locationActivityId: number) {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/locations/${locationId}/activities/${locationActivityId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })
    } catch (error) {
      // ...
    }
  }

  public static async RemoveLocation(locationId: number) {
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/locations/${locationId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })
    } catch (error) {
      // ...
    }
  }
}

export default LocationService
