import ETicketDelivery from './ETicketDelivery'
import ETicketDownload from './ETicketDownload'
import ETicketStatus from '../enums/ETicketStatus'

export default class ETicketSummary {
  constructor(
    public id: string | null,
    public delivery: ETicketDelivery,
    public download: ETicketDownload,
    public status: ETicketStatus = ETicketStatus.Unknown
  ) {}
}
