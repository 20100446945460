<template>
  <v-app :class="appClasses">
    <div class="app-background" :style="appPageStyle" v-if="!$route.meta.hidePageBackground" />
    <app-header v-if="$route.meta.showHeader" v-cloak />
    <div class="page-wrapper">
      <transition name="fade-in">
        <router-view />
      </transition>
      <app-footer v-if="$route.meta.showFooter" v-cloak />
    </div>
    <app-loader v-if="pageLoading" />
  </v-app>
</template>

<script lang="ts">
import Navigation from './Common/components/Navigation.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { mapActions } from 'vuex'
import AppHeader from './Common/components/AppHeader.vue'
import AppFooter from './Common/components/AppFooter.vue'
import AppLoader from './Common/components/AppLoader.vue'
import store from './Common/store/index'

@Component({
  components: {
    Navigation,
    AppHeader,
    AppFooter,
    AppLoader,
  },
})
export default class App extends Vue {
  @Action('location/fetchCurrentLocation') fetchCurrentLocation
  @Getter('settings/settings') appSettings
  @Getter('settings/themeSettings') themeSettings
  @Getter('settings/languageSettings') languageSettings
  @Getter('settings/theme') theme
  @Getter('browserInfo') browserInfo
  @State('pageLoading') pageLoading

  created() {
    store.dispatch('oidcStore/getOidcUser')
  }

  get appClasses() {
    return this.appSettings.limitToMobile ? 'limit-to-mobile' : null
  }

  get appPageStyle() {
    const styles: string[] = []
    if (
      this.theme &&
      this.theme.general &&
      this.theme.general.backgroundImageUrl &&
      this.theme.general.overlayBackgroundWithGradient
    ) {
      let linearGradient = ``
      if (this.theme.general.overlayBackgroundWithGradient === 'Dark') {
        linearGradient = `linear-gradient(180deg,#000000 0%,rgba(0, 0, 0, 0.4) 100%,rgba(0, 0, 0, 0.35) 70.31%,rgba(0, 0, 0, 0) 100%),`
      } else if (this.theme.general.overlayBackgroundWithGradient === 'Light') {
        linearGradient = `linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%),`
      }
      const background = `background: ${linearGradient}url('${this.theme.general.backgroundImageUrl}'), #222;`
      const backgroundRepeat = `background-repeat: no-repeat;`
      const backgroundSize = `background-size:cover;`
      const backgroundPosition = `background-position: center`
      styles.push(background)
      styles.push(backgroundRepeat)
      styles.push(backgroundSize)
      styles.push(backgroundPosition)
    }

    return styles.join('')
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,600;0,700;1,400&display=swap');

@font-face {
  font-family: 'outageregular';
  src: url('@public/fonts/outage-webfont.woff2') format('woff2'), url('@public/fonts/outage-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: Barlow, sans-serif;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
[v-cloak] {
  display: none;
}
body {
  background: #000;

  &.admin {
    background: white;

    .v-application--wrap {
      background: none;
    }
  }
}

.page-wrapper {
  position: relative;
  z-index: 100;
}

#app {
  position: relative;
  .app-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.limit-to-mobile {
    max-width: 375px;
    margin: 0 auto;
    position: relative;

    #checkIn {
      > .button-wrapper {
        max-width: 375px;
      }
    }
  }

  .admin & {
    background: white;

    .app-background {
      display: none;
    }

    &.limit-to-mobile {
      max-width: none !important;
      margin: auto;
      position: relative;
    }
  }
}

h1 {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: 1rem;

  color: #000000;
}
p.instruction {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: rgba(34, 34, 34, 0.8);
  margin-bottom: 1rem;
}
.app-header {
  grid-area: header;
  z-index: 100;
}
.app-footer {
  z-index: 100;
}
.content {
  grid-area: content;
}
textarea {
  font-family: inherit;
}
.vti__input {
  font-size: inherit;
}
.v-btn:not(.v-btn--outlined).primary,
.v-btn:not(.v-btn--outlined).secondary,
.v-btn:not(.v-btn--outlined).accent,
.v-btn:not(.v-btn--outlined).success,
.v-btn:not(.v-btn--outlined).error,
.v-btn:not(.v-btn--outlined).warning,
.v-btn:not(.v-btn--outlined).info {
  color: var(--v-primary-btn-txt-color, #ffffff) !important;
}
.v-btn:not(.v-btn--icon) {
  .v-btn__content .v-icon {
    margin-left: 0.5rem;
  }
}
.units-conversion {
  .v-input--selection-controls {
    padding-top: 0px;
    line-height: 0;
  }

  .v-messages {
    display: none;
  }

  .v-input {
    margin-top: 0;
    .v-input__slot {
      margin-bottom: 10px;
    }
    label {
      font-size: 0.75rem;
    }
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  opacity: 0;
  transition: opacity 50ms ease-out;
  transition-delay: 50ms;
}

.fade-leave-active {
  transition: opacity 50ms ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.15s;
}
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
</style>
