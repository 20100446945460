import ApiKey from '../interfaces/ApiKey'
import ArrivalService from '@/Common/services/ArrivalService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import store from '@/Common/store'

class ApiService extends ArrivalService {
  public static async FetchApiKeys(): Promise<ApiKey[]> {
    let result: ApiKey[] | any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/admin/apikey/list`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async CreateApiKey(apiKey: ApiKey): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/admin/apikey`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: apiKey,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async RemoveApiKey(id: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/admin/apikey/${id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }
}

export default ApiService
