<template>
  <svg
    class="app-loader"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; display: block; shape-rendering: auto"
    width="191px"
    height="191px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      class="outer-circle"
      cx="50"
      cy="50"
      r="20"
      stroke-width="2"
      stroke="#93dbe9"
      stroke-dasharray="31.41592653589793 31.41592653589793"
      fill="none"
      stroke-linecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1.2195121951219512s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle
      class="inner-circle"
      cx="50"
      cy="50"
      r="17"
      stroke-width="2"
      stroke="#689cc5"
      stroke-dasharray="26.703537555513243 26.703537555513243"
      stroke-dashoffset="26.703537555513243"
      fill="none"
      stroke-linecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1.2195121951219512s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;-360 50 50"
      ></animateTransform>
    </circle>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000;

  .outer-circle {
    stroke: var(--loader-primary-color, #689cc5);
  }

  .inner-circle {
    stroke: var(--loader-secondary-color, #93dbe9);
  }
}
</style>
