import { Module } from 'vuex'
import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SettingsState } from './types'
import { RootState } from '@/Common/store/types'

const namespaced: boolean = true

export const settings: Module<SettingsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
