<template>
  <v-sheet id="rental-profile-task" elevation="0" class="pa-4">
    <p>
      Complete a rental profile for each family member. If you’ve completed one in the past, review and make changes
      before continuing.
    </p>
    <rental-profile-form
      :contactTask="contactTask"
      :profile="contactTask.relatedObject"
      :isHidden="currentContactTask != null && currentContactTask.id != contactTask.id"
      :isReadOnly="contactTask.isReadOnly"
      :isUpdating="updating"
      :key="contactTask.id"
      :enableShoeSize="currentTask.taskType.enableShoeSize"
      :enableStance="currentTask.taskType.enableStance"
      @onCancelEdit="reset"
      @onCompleted="setNextContactTask"
      @onEdit="setCurrentContactTask"
      @onSkipped="skipContactTask"
      v-for="contactTask in contactTasks"
    ></rental-profile-form>
  </v-sheet>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import TaskBase from './TaskBase'
import RentalProfileForm from './RentalProfileForm.vue'
import ContactTask from '../interfaces/ContactTask'
import GTMEvent from '@/Common/interfaces/GTMEvent'

Vue.component('rental-profile-form', RentalProfileForm)

@Component({ components: { RentalProfileForm } })
class RentalProfileTask extends TaskBase {
  @Action('trackEvent') trackEvent
  @Getter('task/currentTask') currentTask

  private currentContactTask: ContactTask | null = null
  private updating: boolean = false

  public created() {
    this.next()
  }

  private updateRentalProfileEvent(waiverTask: ContactTask) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Rental Profile',
      noninteraction: false,
      label: `${waiverTask.task.name}`,
      value: `update`,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  private async setNextContactTask(contactTask: ContactTask | null) {
    if (contactTask) {
      try {
        await this.completeContactTask(contactTask)
        this.updateRentalProfileEvent(contactTask)
        contactTask.isComplete = true
        contactTask.isReadOnly = true
        await this.next()
      } catch (error) {
        alert('ERROR CALLING API')
      }
    }
  }

  private async setCurrentContactTask(contactTask: ContactTask) {
    contactTask.isReadOnly = !contactTask.isReadOnly
    this.currentContactTask = contactTask
  }

  public reset(contactTask: ContactTask) {
    contactTask.isReadOnly = !contactTask.isReadOnly
    this.currentContactTask = null
  }

  private async skipContactTask(contactTask: ContactTask) {
    await this.completeContactTask(contactTask, true)
    await this.next()
  }

  private async next() {
    const incompleteTasks = this.contactTasks.filter((t) => t.isComplete == false)
    if (incompleteTasks.length > 0) {
      this.currentContactTask = incompleteTasks[0]
      if (this.currentContactTask) {
        this.currentContactTask.isReadOnly = !this.currentContactTask.isReadOnly
      }
    } else if (this.contactTasks.length == 0) {
      // No contacts qualify for this task.  Move to the next
      this.$emit('complete-task')
    } else {
      // We have contact tasks but they are all complete.
      // Reset current contact task to indicate we are not editting any of them.
      this.currentContactTask = null
    }
  }
}

export default RentalProfileTask
</script>
<style lang="scss" scoped>
// #rental-profile-task {
//   padding: 1rem;
//   width: 100%;
// }
</style>
