import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'

@Component
export default class ContactTaskBase extends Vue {
  @Prop({ required: true, default: null }) contactTask: ContactTask | any

  public prepare(contactTask: ContactTask) {
    // Specific implemented in task classes
    return contactTask
  }
}
