import { ActionTree } from 'vuex'
import Activity from '../interfaces/Activity'
import ActivityService from '@/Activity/services/ActivityService'
import { ActivityState } from './types'
import { RootState } from '@/Common/store/types'
import moment from 'moment'

export const actions: ActionTree<ActivityState, RootState> = {
  async fetchActivityContacts({ commit }): Promise<any> {
    const locationId = 1

    const activityContacts = await ActivityService.FetchActivityContacts(locationId)

    activityContacts.forEach((ac: any) => {
      commit('addActivityContact', ac)
    })
  },
  async fetchActivities({ commit }): Promise<Activity[] | null> {
    const activitiesResponse = await ActivityService.FetchActivities()
    const allActivities = activitiesResponse || null
    const modifiedActivities = allActivities.map((activity) => {
      return {
        ...activity,
        modifiedDate: moment(activity.modifiedDateUtc).format('MM/DD/YY hh:MM a'),
        hasTasks: activity.activityTasks && activity.activityTasks.length > 0 ? true : false,
      }
    })

    commit('setActivities', modifiedActivities)

    return modifiedActivities
  },
  resetActivityContacts({ commit }): any {
    commit('resetActivityContacts')
  },
}
