<template>
  <div class="loading-page">
    <img src="/img/double-ring-loader.svg" />
  </div>
</template>
<script>
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'
import SettingsService from '@/Settings/services/SettingsService'

export default {
  name: 'OidcSilentRenew',
  mounted() {
    SettingsService.FetchSettings().then((settings) => {
      const oidcSettings = settings.frontEndSettings.oidcSettings
      console.log('oidcSettings', oidcSettings)
      vuexOidcProcessSilentSignInCallback(oidcSettings)
    })
  },
}
</script>
