import RentalProfileListItem from '../interfaces/RentalProfileListItem'

export default class RentalProfile {
  ageRefs: RentalProfileListItem[]
  abilityRefs: RentalProfileListItem[]
  heightRefs: RentalProfileListItem[]
  weightRefs: RentalProfileListItem[]
  shoeSizeRefs: RentalProfileListItem[]
  shoeSize: string
  selectedAge: string
  selectedAbility: string
  selectedHeight: string
  selectedWeight: string
  selectedShoeSize: string
  selectedGoofy: string
  updateDate: Date | null

  constructor(profile: any) {
    this.ageRefs = profile.ageRefs
    this.abilityRefs = profile.abilityRefs
    this.heightRefs = profile.heightRefs
    this.weightRefs = profile.weightRefs
    this.shoeSizeRefs = profile.shoeSizeRefs
    this.shoeSize = profile.shoeSize
    this.selectedAge = profile.selectedAge
    this.selectedAbility = profile.selectedAbility
    this.selectedHeight = profile.selectedHeight
    this.selectedWeight = profile.selectedWeight
    this.selectedShoeSize = profile.selectedShoeSize
    this.selectedGoofy = profile.selectedGoofy
    this.updateDate = profile.updateDate
  }
}
