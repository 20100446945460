<template>
  <div id="notFound">
    <v-container max-width="600">
      <v-row>
        <v-spacer />
        <v-col md="8">
          <img src="/img/not-found.svg" alt="404 Not Found" />
          <h1><span class="large">Ooops</span><span class="small">404 - Page Not Found</span></h1>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NotFound extends Vue {}
</script>
<style lang="scss" scoped>
#notFound {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
h1 {
  color: white;
  text-align: center;
  display: flex;
  vertical-align: center;
  flex-wrap: wrap;
  text-align: center;

  .large {
    font-size: 3em;
    font-weight: 200;
    border-right: 1px solid white;
    padding-right: 50px;
    line-height: 150px;
    flex-basis: 50%;
  }

  .small {
    padding-left: 50px;
    display: table-cell;
    vertical-align: center;
    white-space: nowrap;
    line-height: 150px;
    flex-basis: 50%;
  }

  @media screen and (max-width: 960px) {
    .large {
      margin-bottom: 30px;
    }
    .large,
    .small {
      border: none;
      line-height: 1.1em;
      padding: 0;
      flex-basis: 100%;
    }
  }
}
img,
.v-icon {
  margin: 3rem 0;
  height: 100px;
  width: auto;
}
</style>
