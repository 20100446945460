const schema = {
  properties: {
    bleConnect: {
      title: 'BLE Connect',
      type: 'object',
      required: [
        'pageTitle',
        'assigningMediaMessage',
        'mediaConnectedMessage',
        'noBLEMediaMessage',
        'refreshButtonText',
        'refreshButtonText',
        'sendButtonText',
        'sendEmailButtonText',
        'sendSMSButtonText',
      ],
      properties: {
        pageTitle: {
          title: 'BLE Connect Page Title',
          description: 'The page title of the BLE Connect page',
          type: 'string',
        },
        pageInstructions: {
          title: 'BLE Connect Page Instructions',
          description: 'Use "{unactivatdCount}" to substitute the number of family members requiring BLE activation.',
          type: 'string',
        },
        assigningMediaMessage: {
          title: 'BLE Connect Page Assigning Media Message',
          description: 'The message on the BLE Connect page when a media is in process of being assigned',
          type: 'string',
        },
        mediaConnectedMessage: {
          title: 'BLE Connect Page Media Connected Message',
          description: 'The message on the BLE Connect page when a media is connected to a device',
          type: 'string',
        },
        activateMediaMessage: {
          title: 'BLE Connect Page Activate Media Message',
          description: 'The message on the BLE Connect page when a media requires activation',
          type: 'string',
        },
        bleHomepageButton: {
          title: 'BLE Homepage Button',
          description: 'The BLE button label on the homepage',
          type: 'string',
        },
        noBLEMediaMessage: {
          title: 'BLE Connect Page No BLE Media',
          description: 'The message on the BLE Connect page when a family member has no BLE media to connect',
          type: 'string',
        },
        noFamilyMessage: {
          title: 'BLE Connect Page No Family Members Found Message',
          description: 'The message on the BLE Connect page when no family members are found',
          type: 'string',
        },
        mobileShareTitle: {
          title: 'BLE Connect Mobile Share Link Title',
          description: 'The title shown on the share sheet when sending a mobile pass via mobile device',
          type: 'string',
        },
        mobileShareMessage: {
          title: 'BLE Connect Mobile Share Link Message',
          description:
            'The message of the text send when sharing an activation link via mobile device. Use {FirstName} as a token for the activation member&#39;s name',
          type: 'string',
        },
        resendLinkText: {
          title: 'BLE Connect Page Resend Link Text',
          description: 'The text that appears as a link to resend the BLE media.',
          type: 'string',
        },
        refreshButtonText: {
          title: 'BLE Connect Page Refresh Button Text',
          description: 'The text that appears on the button to refresh the BLE Connect page.',
          type: 'string',
        },
        sendButtonText: {
          title: 'BLE Connect Page Send Button Text',
          description: 'The text that appears on the button to send the BLE media link.',
          type: 'string',
        },
        sendEmailButtonText: {
          title: 'BLE Connect Page Send Email Button Text',
          description: 'The text that appears on the button to choose to send an email.',
          type: 'string',
        },
        sendSMSButtonText: {
          title: 'BLE Connect Page Send SMS Button Text',
          description: 'The text that appears on the button to choose to send an SMS.',
          type: 'string',
        },
        changeSendTypeText: {
          title: 'BLE Connect Page Change Send Type Text',
          description: 'The text that appears as a link to change the send type.',
          type: 'string',
        },
        addMobilePassStepTitle: {
          title: 'Add mobile pass step title',
          description: 'The title at the top of the add mobile pass step',
          type: 'string',
        },
        addMobilePassStepInstructions: {
          title: 'Add mobile pass step instructions',
          description: 'The instructions on the add mobile pass step',
          type: 'string',
        },
        addMobilePassStepButton: {
          title: 'Add mobile pass step button',
          description: 'The button label on the add mobile pass step',
          type: 'string',
        },
        cancelAddMobilePassStepButton: {
          title: 'Cancel add mobile pass step button',
          description: 'The button label on the cancel add mobile pass step',
          type: 'string',
        },
        addingPassMessage: {
          title: 'Adding pass message',
          description: 'The message shown while adding a mobile pass',
          type: 'string',
        },
        activatePhoneStepTitle: {
          title: 'Activate phone step title',
          description: 'The title at the top of the activate phone step',
          type: 'string',
        },
        activatePhoneStepInstructions: {
          title: 'Activate phone step instructions',
          description: 'The instructions on the activate phone step',
          type: 'string',
        },
        activatePhoneConfirmation: {
          title: 'Activate phone confirmation text',
          description: 'Confirmation message that the user is on the activation phone',
          type: 'string',
        },
        activatePhoneSubText: {
          title: 'Activate phone subtext',
          description: 'Subtext at the bottom of the activate phone screen',
          type: 'string',
        },
        activatePhoneAction: {
          title: 'Activate phone action',
          description: 'The message displayed for activating phone and button label',
          type: 'string',
        },
        activatePhoneInsteadButton: {
          title: 'Activate phone instead button',
          description: 'The button label on the activate this phone instead button',
          type: 'string',
        },
        sendMemberLinkButton: {
          title: 'Send member a link button',
          description: 'The button label on the send link action',
          type: 'string',
        },
        sendLinkInsteadButton: {
          title: 'Send link instead button',
          description: 'The button label on the send link instead button',
          type: 'string',
        },
        bleActivationFootnote: {
          title: 'BLE activation footnote',
          description: 'The small footnote text at the end of activation',
          type: 'string',
        },
      },
    },
  },
}

export default schema
