<template>
  <header class="app-header">
    <div class="logo"></div>
    <div class="content">
      <h2>{{ $route.meta.title }}</h2>
      <p>{{ $route.meta.description }}</p>
    </div>
  </header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AppHeader extends Vue {
  // ...
}
</script>

<style lang="scss" scoped>
$header-padding: 14px;

header {
  padding: 1rem;
  border: none;
  height: 100px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  border-bottom: 2px solid var(--footer-border-color, #fff);

  .logo {
    height: calc(100px - 2 * #{$header-padding});
    width: 140px;
    background: var(--logo-url, url('@public/img/logo-bigsky--white.png'));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 100;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    background-color: #ffffff;
    background-image: var(--footer-bkg-url, url('@public/img/bg--footer--sample.jpg'));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--footer-text-color, '#FEFEFE');
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 4px;
    padding: $header-padding;
    border-width: 0px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    @include breakpoint('tablet') {
      border-radius: 0;
      margin-top: 0;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.3;
      letter-spacing: 0.04em;
    }

    h2,
    p {
      max-width: 180px;
      text-align: right;
    }
  }
}
</style>
