<template>
  <div id="waiver-container" class="waiver" ref="waiver" v-show="!signingWaiver">
    <div id="waiver-header" class="waiver__header">
      <div id="waiver-title" class="waiver__title">
        <h1 id="waiver-title-header">{{ currentTask.name }}</h1>
      </div>
      <div id="affirmation-statement" v-html="affirmationHtml"></div>
    </div>

    <template v-if="waiver">
      <p id="waiver-instructions" class="waiver__instructions">Scroll through text below to read waiver</p>
      <div id="waiver-content" class="waiver__content" v-html="waiver.rolText"></div>
    </template>
    <div id="waiver-no-content" class="waiver__no-content" v-else>
      <h2>NO WAIVER</h2>
    </div>

    <div id="waiver-footer" class="waiver__footer">
      <div id="waiver-signature-pad" class="signature-pad">
        <div id="agree" v-if="enableAgreeCheckbox">
          <v-checkbox
            id="agree-checkbox"
            type="checkbox"
            v-model="agree"
            :label="`I agree for ${allContactNames('and')}`"
          />
        </div>
        <div v-if="enableWetSignature">
          <div id="signature-canvas-container" class="signature-canvas signature-canvas__wrapper">
            <div id="canvas-overlay" class="canvas-overlay">
              <div id="signature-line" class="signature-line">
                <icon id="x-icon" type="icon-arrow-x" color="black" />
              </div>
            </div>
            <canvas id="signature-canvas" class="signature-canvas" ref="canvas"></canvas>
          </div>
          <div id="signature-actions" class="signature-actions">
            <div id="signature-affirmation" class="signature-affirmation">
              I state that I can sign for {{ allContactNames('and') }}
            </div>
            <v-btn @click="clearSignature" color="secondary" outlined rounded>Clear</v-btn>
          </div>
        </div>
        <div id="type-name" v-if="enableTextSignature">
          <v-text-field
            v-model="signerName"
            :label="signNamePlaceholder"
            color="$color-label"
            filled
            outlined
            single-line
          ></v-text-field>
        </div>
      </div>
      <div id="signature-buttons" class="signature-buttons">
        <v-btn @click="skipWaiver" color="secondary" rounded v-if="currentTask.isOptional">Skip Waiver</v-btn>
        <v-btn @click="saveSignature" :color="this.agree && this.signerName.length > 0 ? 'success' : '$color-label'" rounded>Accept</v-btn>
      </div>
    </div>
    <div id="waiver-snapshot-container"></div>
  </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'
import WaiverReleaseOfReliability from '../interfaces/WaiverReleaseOfReliability'
import SigningData from '../interfaces/SigningData'
import SignaturePad from 'signature_pad'
import Icon from '@/Common/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
class SignWaiver extends Vue {
  @Prop({ required: true, default: null }) waiver: WaiverReleaseOfReliability | null
  @Prop({ required: true, default: null }) contactTasks: ContactTask[] | null
  @Prop({ required: true, default: true }) enableWetSignature: boolean
  @Prop({ required: true, default: true }) enableAgreeCheckbox: boolean
  @Prop({ required: true, default: true }) enableTextSignature: boolean
  @Prop({ required: false, default: null }) affirmationStatement: string | null
  @Prop({ required: false, default: null }) signingWaiver: boolean

  @Getter('oidcStore/oidcUser') user: any

  // Data elements
  public showPad: boolean = false
  public needToScroll: boolean = true
  public newWaiver: boolean = false
  public canvas: any = null
  public signaturePad: any = null
  public signerName: string | null = null
  public agree: boolean = false
  public waiverHtmlDoc: any = null

  // Computed Data Elements
  get affirmationHtml() {
    if (this.affirmationStatement) {
      return this.affirmationStatement
    } else {
      let text = `I affirm that I am <span class="contact-names">${this.allContactNames(
        'or'
      )}</span>, a parent or legal guardian, or otherwise legally authorized to sign on their behalf.`
      text += `&nbsp;&nbsp;If you are not authorized to sign for this person, skip this waiver.`
      return text
    }
  }

  private allContactNames(conjunction: string) {
    let names: string = ''
    let index = 0

    if (this.contactTasks) {
      this.contactTasks.forEach((ct) => {
        if (this.contactTasks) {
          if (index == this.contactTasks.length - 1 && this.contactTasks.length > 1) {
            names += ` ${conjunction} `
          } else if (index > 0) {
            names += ', '
          }
        }
        names += `${ct.contact.firstName} ${ct.contact.lastName}`
        index++
      })
    }

    return names
  }

  get currentTask() {
    if (this.contactTasks && this.contactTasks.length > 0) {
      return this.contactTasks[0].task
    }
  }

  get signNamePlaceholder() {
    if (this.contactTasks && this.contactTasks.length > 1) {
      // Signing for multiple contacts, use the logged in user's name
      return `Type: ${this.user.name}`
    } else if (this.contactTasks && this.contactTasks.length == 1) {
      if (this.contactTasks && this.contactTasks[0].contact.age < 18) {
        // Signing for a single minor, use logged in user's name
        return `Type: ${this.user.name}`
      } else {
        // Signing for single adult, use the participant's name
        return `Type: ${this.contactTasks[0].contact.firstName} ${this.contactTasks[0].contact.lastName}`
      }
    }
  }

  // Life Cycle
  public mounted() {
    if (this.enableWetSignature) {
      this.canvas = this.$refs.canvas
      this.signaturePad = new SignaturePad(this.canvas)
      this.resizeCanvas()
    }
  }

  // Methods
  public skipWaiver() {
    this.clearSignature()
    this.signerName = null
    this.agree = false
    this.$emit('skip')
  }

  public saveSignature() {
    if (this.enableWetSignature && this.signaturePad.isEmpty()) {
      alert('Please Sign.')
      return
    }

    if (this.enableAgreeCheckbox && !this.agree) {
      alert('Please check to agree.')
      return
    }

    if (this.enableTextSignature) {
      if (this.signerName == null) {
        alert(`Please type your name in the provided box.`)
        return
      } else if (this.signerName.trim() != this.signNamePlaceholder!.replace('Type: ', '')) {
        alert(`Please sign ${this.signNamePlaceholder} exactly.`)
        return
      }
    }

    // Get the wet signature
    let newSignature = ''
    if (this.enableWetSignature) {
      newSignature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '')
    }

    // We need to create a new HTML doc with the waiver page exactly as it appears to the signer and store this in RTP
    // Get a reference to the top <div> element
    let waiverHtmlText: string | null = null
    const waiverElement = document.getElementById('waiver-container')

    if (waiverElement) {
      // Create a new HTML document object
      this.waiverHtmlDoc = document.implementation.createHTMLDocument('Waiver')
      // Insert all the HTML from the waiver page into the new document
      const waiverDiv = this.waiverHtmlDoc.createElement('div')
      waiverDiv.innerHTML = waiverElement.innerHTML

      try {
        this.waiverHtmlDoc.body.appendChild(waiverDiv)
      } catch (e) {
        console.log(e)
      }

      // Recursively walk through the waiver HTML and copy styles from each element
      // to the corresponding element in the new document object
      this.walkTheDOM(waiverDiv, this.copyStyles)

      // Once the styles have been copied, serialize the HTML and add to the signing data
      // The parent component will store this as the ROL Text to be stored in RTP
      waiverHtmlText = new XMLSerializer().serializeToString(this.waiverHtmlDoc)
    }

    const signingData: SigningData = {
      signature: newSignature,
      agree: this.agree,
      signerName: this.signerName,
      waiverHTML: waiverHtmlText,
    }

    this.$emit('save-signature', signingData)

    this.clearSignature()
  }

  public clearSignature() {
    if (this.enableWetSignature && this.signaturePad) {
      this.signaturePad.clear()
    }

    if (this.enableAgreeCheckbox) {
      this.agree = false
    }

    if (this.enableTextSignature) {
      this.signerName = null
    }
  }

  public resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    this.canvas.width = this.canvas.offsetWidth * ratio
    this.canvas.height = this.canvas.offsetHeight * ratio
    this.canvas.getContext('2d').scale(ratio, ratio)
    this.signaturePad.clear() // otherwise isEmpty() might return incorrect value
  }

  // This function grabs all the styles for an HTML element and builds a CSS style declaration string for them
  public dumpCSSText(id) {
    let cssText = ''
    const orginalElement = document.getElementById(id) as HTMLElement
    if (orginalElement) {
      const elementStyles = getComputedStyle(orginalElement, '')
      const elementKeys = Object.keys(elementStyles)

      for (const key in elementKeys) {
        if (elementStyles[key]) {
          const propertyValue = elementStyles.getPropertyValue(elementStyles[key])
          if (propertyValue) {
            cssText += elementStyles[key] + ':' + elementStyles.getPropertyValue(elementStyles[key]) + ';'
          }
        }
      }
    }

    return cssText
  }

  // This function recursively walks through an HTML structure and calls a function for each
  public walkTheDOM(node, func) {
    func(node)
    node = node.firstChild
    while (node) {
      this.walkTheDOM(node, func)
      node = node.nextSibling
    }
  }

  // This method copies styles from a node in the DOM to the curresponding element of the new documnt object
  public copyStyles(node) {
    if (node.id && node.id != 'waiver-snapshot') {
      const foundElement = this.waiverHtmlDoc.getElementById(node.id)
      if (foundElement) {
        const styles = this.dumpCSSText(node.id)
        if (styles) {
          foundElement.style.cssText = styles
        }
      }
    }
  }
}

export default SignWaiver
</script>

<style lang="scss">
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.45) !important;
}
</style>
<style lang="scss" scoped>
h1 {
  margin-bottom: 0;
}

.waiver {
  background-color: white;
  z-index: 200;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 100%;
}

.waiver__instructions {
  margin: 1rem 1rem 0.5rem 1rem;
  font-size: 0.875rem;
  font-style: italic;
}

.waiver__content {
  margin: 0px 15px 0 15px;
  padding: 1rem;
  border: 2px solid #ccc;
  font-weight: 700;
  color: black;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  height: calc(100vh - 540px);
  min-height: 330px;
  p {
    color: black !important;
  }
}

.waiver__header {
  font-weight: 700;
  opacity: 1;
  background: #f7f7fa;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 4px 4px 2px rgba(255, 255, 255, 0.95);
  height: auto;
}

.waiver__title {
  font-size: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.waiver__footer {
  font-weight: 700;
  opacity: 1;
  background: white;
  height: auto;
  min-height: 150px;
  font-size: 1.5rem;
  margin: 15px 15px 0px 15px;
}

.signature-pad {
  background: lightgrey;
  padding: 1rem;
  border-radius: 5px;
}

#agree {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
}

#affirmation-statement {
  background-color: goldenrod;
  border-bottom: 2px solid;
  border-top: 2px solid;
  border-color: darkorange;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.5;
  .contact-names {
    text-decoration: underline;
    font-weight: 700;
  }
}

div + #type-name {
  margin-top: 1rem;
}

.canvas-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  pointer-events: none;
}

.signature-line {
  width: 100%;
  border-bottom: 2px solid black;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.signature-canvas {
  position: relative;
  height: 80px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  touch-action: none;
}

.signature-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .signature-affirmation {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    font-weight: 600;
  }

  .ax--btn {
    margin: 5px 0 0 10px;
  }
}

.signature-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
</style>
