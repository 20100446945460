import SetLocation from './components/SetLocation.vue'

const routes: any[] = [
  {
    path: '/l/:locationName',
    name: 'location',
    component: SetLocation,
  },
]

export default routes
