<template>
  <div id="admin-activities">
    <div id="activity-list" v-if="activities">
      <v-card class="pa-2">
        <v-card-title class="pt-2">Activity List</v-card-title>
        <v-card-subtitle class="description">Click the box to view or edit activity details.</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="activitiesTableHeaders"
            :items="activities"
            :items-per-page="10"
            @item-selected="onActivitySelected"
            show-select
            single-select
          >
            <template v-slot:item.imageUrl="{ item }">
              <div class="activity-image" :style="`background-image: url('${item.imageUrl}')`"></div>
            </template>
            <template v-slot:item.hasTasks="{ item }">
              <div class="status">
                <v-icon v-if="!item.hasTasks" color="red" title="Missing tasks">mdi-alert</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card id="task-details" v-if="currentActivity" class="mt-8">
        <v-card-title class="pb-0">{{ currentActivity.name }} Activity</v-card-title>
        <v-card-text>
          <v-container :fluid="true" class="pa-0">
            <v-row>
              <v-col cols="12" md="6" class="pa-0">
                <v-card elevation="0" class="pa-0">
                  <v-card-text>
                    <v-form>
                      <v-jsf v-model="currentActivity" :schema="activitySchema" />
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pt-2 pl-4 pr-4">
                    <v-btn color="primary" @click="updateActivity" :loading="updatingActivity">
                      Update Activity <v-icon>mdi-upload</v-icon>
                    </v-btn>
                    <v-btn color="error" @click="removeActivity" :loading="deletingActivity"
                      >Delete Activity <v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <v-card elevation="0" class="pa-0">
                  <v-card-title class="pb-0">Assign Tasks</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Task</th>
                            <th class="text-right">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="activityTask in currentActivity.activityTasks" :key="activityTask.id">
                            <td>{{ activityTask.task.name }}</td>
                            <td class="text-right">
                              <v-btn
                                @click="removeActivityTask(activityTask.activityId, activityTask.id)"
                                :loading="deletingTask == activityTask.id"
                                text
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions class="pt-4 pl-4 pr-4">
                    <h3>Link Tasks</h3>
                    <div class="link-wrapper">
                      <v-select
                        v-model="newActivityTask.taskId"
                        :items="tasks"
                        item-text="name"
                        item-value="id"
                        label="Choose a Task"
                      />
                      <v-btn class="link-task-btn" @click="addActivityTask" :loading="linkingTask">Link Task</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card class="pa-2 mt-8" v-if="!currentActivity">
      <v-card-title class="pt-2">Add New Activity</v-card-title>
      <v-card-subtitle>Complete the following information to add a new activity.</v-card-subtitle>
      <v-card-text>
        <v-form v-model="newActivityValid" ref="newActivityForm">
          <v-jsf v-model="newActivity" :schema="activitySchema" :options="newActivityOptions" />
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn @click="addActivity" color="primary" large>Add Activity <v-icon>mdi-upload</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import ActivityProduct from '../../Activity/interfaces/ActivityProduct'
import ActivityTask from '../../Activity/interfaces/ActivityTask'
import ActivityService from '../../Activity/services/ActivityService'
import { activity } from '../../Activity/store'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'
import Task from '../../Task/interfaces/Task'

@Component
export default class AdminActivities extends Vue {
  @Action('activity/fetchActivities')
  private fetchActivities: any
  @Getter('activity/activities')
  private activities?: []

  @Action('task/fetchTasks')
  private fetchTasks: any
  @Getter('task/tasks')
  private tasks?: Task[]

  private currentActivity: Activity | null = null
  public currentActivityAvailableTasks: Task[] = []
  private newActivityValid: any = false
  private updatingActivity: boolean = false
  private deletingActivity: boolean = false
  private deletingTask: number = -1
  private linkingTask: boolean = false

  $refs: {
    newActivityForm: HTMLFormElement
  }

  onActivitySelected({ item: selActivity, value: selected }) {
    if (selActivity && selected) {
      const editActivity = {
        ...selActivity,
      }
      Vue.nextTick(() => {
        this.currentActivity = editActivity
      })
    } else {
      this.currentActivity = null
    }
  }

  private newActivityOptions = {
    initialValidation: 'none',
  }

  private activitySchema = {
    required: ['name'],
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
        default: '',
      },
      ageMin: {
        title: 'Age Min',
        type: 'number',
      },
      ageMax: {
        title: 'Age Max',
        type: 'number',
      },
      imageUrl: {
        title: 'Image Url',
        type: 'string',
      },
      activityTasks: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
        items: {},
      },
      activityProducts: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
        items: {},
      },
    },
  }

  public newActivity: Activity = {
    id: 0,
    name: '',
    ageMin: null,
    ageMax: null,
    imageUrl: '',
    isLocationDefaultActivity: false,
    activityTasks: [],
    activityProducts: [],
  }

  public clearedActivity = { ...this.newActivity }
  public newActivityTask: ActivityTask = {
    id: 0,
    activityId: 0,
    taskId: 0,
  }

  public newActivityProduct: ActivityProduct = {
    id: 0,
    activityId: 0,
    productId: '',
  }

  public created() {
    if (!this.activities) {
      this.getActivities()
    }

    if (!this.tasks) {
      this.getTasks()
    }
  }

  public async getActivities() {
    await this.fetchActivities()
    if (this.currentActivity && this.activities) {
      const thisActivity = this.activities.find((l: Location) => l.id == this.currentActivity!.id)
      if (thisActivity) {
        this.currentActivity = thisActivity
      }
    }
  }

  public getTasks() {
    this.fetchTasks()
  }

  public viewActivityDetails(activity) {
    this.currentActivity = activity
    if (this.tasks) {
      this.currentActivityAvailableTasks = this.tasks.filter((elem) =>
        activity.activityTasks.find(({ taskId }) => elem.id === taskId)
      )
    }
  }

  private async addActivity() {
    this.$refs.newActivityForm.validate()

    if (this.newActivityValid) {
      await ActivityService.AddActivity(this.newActivity)
      this.getActivities()
      this.newActivity = { ...this.clearedActivity }
      this.$refs.newActivityForm.reset()
    }
  }

  private async updateActivity() {
    if (this.currentActivity) {
      this.updatingActivity = true
      await ActivityService.UpdateActivity(this.currentActivity)
      this.getActivities()
      this.updatingActivity = false
    }
  }

  private async removeActivityTask(activityId: number, activityTaskId: number) {
    this.deletingTask = activityTaskId
    await ActivityService.RemoveActivityTask(activityId, activityTaskId)
    this.getActivities()
    this.deletingTask = -1
  }

  private async removeActivity() {
    if (this.currentActivity) {
      this.deletingActivity = true
      await ActivityService.RemoveActivity(this.currentActivity.id)
      this.getActivities()
      this.deletingActivity = false
      this.currentActivity = null
    }
  }

  private async addActivityTask() {
    if (this.currentActivity) {
      this.linkingTask = true
      this.newActivityTask.activityId = this.currentActivity.id
      await ActivityService.AddActivityTask(this.newActivityTask)
      this.getActivities()
      this.linkingTask = false
    }
  }

  get activitiesTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: 'Age Min', value: 'ageMin', align: 'center' },
      { text: 'Age Max', value: 'ageMax', align: 'center' },
      { text: 'Image', value: 'imageUrl', align: 'center', sortable: false },
      { text: 'Modified Date', value: 'modifiedDate' },
      { text: 'Status', value: 'hasTasks', align: 'center', sortable: false },
    ]
  }
}
</script>
<style lang="scss" scoped>
.activity-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 100px;
  border: 1px solid white;
}
.v-btn + .v-btn {
  margin-left: 2rem;
}
.status {
  display: flex;
  justify-content: center;
}
.link-task-btn {
  margin-left: 1rem;
}
.link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .v-input {
    flex-basis: 75%;
  }
}
</style>
