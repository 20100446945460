export default class ChildRegProfile {
  specialCondition: string
  medication: string
  foodAllergy: string
  drugAllergy: string
  alternateEmergencyPhone1: any
  alternateEmergencyContact1: any
  primaryEmergencyPhone: any
  primaryEmergencyContact: any
  dateOfBirth: Date
  contactId: string
  firstName: string
  lastName: string
  age: number
  imageUrl: string
  isHeadOfHouseHold: boolean
  isSelected: boolean
  parentContactId: string

  constructor(profile: any) {
    this.specialCondition = profile.specialCondition
    this.medication = profile.medication
    this.foodAllergy = profile.foodAllergy
    this.drugAllergy = profile.drugAllergy
    this.alternateEmergencyPhone1 = profile.alternateEmergencyPhone1
    this.alternateEmergencyContact1 = profile.alternateEmergencyContact1
    this.primaryEmergencyPhone = profile.primaryEmergencyPhone
    this.primaryEmergencyContact = profile.primaryEmergencyContact
  }
}
