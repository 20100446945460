<template>
  <h1>About Arrival</h1>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

export default {
  name: 'system',
  components: {},
}
</script>