import store from '@/Common/store'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import AdminSetting from '../interfaces/AdminSetting'
import AdminEmailAccess from '../interfaces/AdminEmailAccess'
import ArrivalService from '@/Common/services/ArrivalService'

class AdminService extends ArrivalService {
  public static async FetchAdminSettings(): Promise<AdminSetting> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/admin/settings`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateAdminSettings(setting: AdminSetting): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/admin/settings`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: setting,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchAdminEmailAccess(): Promise<AdminEmailAccess[]> {
    let result: any = []
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/admin/access/email`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async AddAdminEmailAccess(adminEmailAccess: AdminEmailAccess | null): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/admin/access/email`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: adminEmailAccess,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateAdminEmailAccess(adminEmailAccess: AdminEmailAccess): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/admin/access/email`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: adminEmailAccess,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async DeleteAdminEmailAccess(id: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/admin/access/email/${id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async IsAdminAuthenticated(): Promise<boolean> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/admin/authenticated`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }
}

export default AdminService
