<template>
  <div id="child-registration-tasks">
    <h1>Complete Child Registration Profiles</h1>
    <div v-if="firstCompletedContactTask !== undefined">
      <child-registration-form
        :childRegProfile="contactTask.relatedObject"
        :contactTask="contactTask"
        :copyFromContactTask="firstCompletedContactTask"
        :isHidden="currentContactTask != null && currentContactTask.id != contactTask.id"
        :isReadOnly="contactTask.isReadOnly"
        :isUpdating="updating"
        :key="contactTask.id"
        @onCancelEdit="reset"
        @onCompleted="setNextContactTask"
        @onEdit="setCurrentContactTask"
        @onSkipped="skipContactTask"
        v-for="contactTask in contactTasks"
      ></child-registration-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Action } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import TaskBase from './TaskBase'
import ContactTask from '../interfaces/ContactTask'
import ChildRegistrationForm from './ChildRegistrationForm.vue'
import ContactTaskGrouping from '../interfaces/ContactTaskGrouping'
import GTMEvent from '@/Common/interfaces/GTMEvent'

Vue.component('child-registration-form', ChildRegistrationForm)

@Component({ components: { ChildRegistrationForm } })
class ChildRegistrationTask extends TaskBase {
  @Action('trackEvent') trackEvent

  public currentContactTask: ContactTask | null = null

  public created() {
    this.next()
  }

  private updating: boolean = false

  private updateChildRegistrationEvent(waiverTask: ContactTask) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Child Registration',
      noninteraction: false,
      label: `${waiverTask.task.name}`,
      value: `update`,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  private async setNextContactTask(contactTask: ContactTask | null) {
    if (contactTask) {
      try {
        this.updating = true
        await this.completeContactTask(contactTask)
        this.updateChildRegistrationEvent(contactTask)
        contactTask.isComplete = true
        contactTask.isReadOnly = true
        window.scrollTo(0, 0)
        await this.next()
        this.updating = false
      } catch (error) {
        alert('ERROR CALLING API')
      }
    }
  }

  private async setCurrentContactTask(contactTask: ContactTask) {
    contactTask.isReadOnly = !contactTask.isReadOnly
    this.currentContactTask = contactTask
  }

  public reset(contactTask: ContactTask) {
    contactTask.isReadOnly = !contactTask.isReadOnly
    this.currentContactTask = null
  }

  public get firstCompletedContactTask() {
    const completeContactTasks = this.contactTasks.filter((t) => t.isComplete == true)

    if (completeContactTasks.length > 0) {
      return completeContactTasks[0]
    }

    return null
  }

  private async skipContactTask(contactTask: ContactTask) {
    await this.completeContactTask(contactTask, true)
    await this.next()
  }

  private async next() {
    const incompleteTasks = this.contactTasks.filter((t) => t.isComplete == false)
    if (incompleteTasks.length > 0) {
      this.currentContactTask = incompleteTasks[0]
      if (this.currentContactTask) {
        this.currentContactTask.isReadOnly = !this.currentContactTask.isReadOnly
      }
    } else if (this.contactTasks.length == 0) {
      // No contacts qualify for this task.  Move to the next
      this.$emit('complete-task')
    } else {
      // We have contact tasks but they are all complete.
      // Reset current contact task to indicate we are not editting any of them.
      this.currentContactTask = null
    }
  }
}

export default ChildRegistrationTask
</script>
<style lang="scss" scoped>
#child-registration-tasks {
  padding: 1rem 0.5rem;
  width: 100%;
  > p {
    color: white;
  }
}
</style>
