const schema = {
  properties: {
    passChecker: {
      title: 'Pass Checker',
      type: 'object',
      required: [],
      properties: {
        passCheckerTitle: {
          title: 'Pass Checker Title',
          description: 'The full title of pass checker',
          type: 'string',
        },
        PassCheckerButtonText: {
          title: 'Pass Checker Button Text',
          description: 'The button text on the pass checker form',
          type: 'string',
        },
        passValidTitle: {
          title: 'Pass Checker Valid Title',
          description: 'Title that appears when a pass is valid',
          type: 'string',
        },
        passValidText: {
          title: 'Pass Checker Valid Text',
          description: 'Text that appears when a pass is valid',
          type: 'string',
        },
        passInvalidTitle: {
          title: 'Pass Checker Invalid Title',
          description: 'Text that appears when a pass is valid',
          type: 'string',
        },
        passInvalidText: {
          title: 'Pass Checker Invalid Text',
          description: 'Text that appears when a pass is invalid',
          type: 'string',
        },
        noProductText: {
          title: 'No Product Text',
          description: 'Text that appears when there is a no product linked to a pass',
          type: 'string',
        },
        invalidRequestErrorTitle: {
          title: 'Invalid Request Error Title',
          description: 'Title that appears when a media check is an invalid request',
          type: 'string',
        },
        invalidRequestErrorText: {
          title: 'Invalid Request Error Text',
          description: 'Text that appears when a media check is an invalid request',
          type: 'string',
        },
        inactiveMediaErrorTitle: {
          title: 'Invalid Media Error Title',
          description: 'Title that appears when a media check has invalid media',
          type: 'string',
        },
        inactiveMediaErrorText: {
          title: 'Invalid Media Error Text',
          description: 'Text that appears when a media check has invalid media',
          type: 'string',
        },
        hostlistErrorTitle: {
          title: 'Hotlist Error Title',
          description: 'Title that appears when a customer is hotlisted',
          type: 'string',
        },
        hostlistErrorText: {
          title: 'Hostlist Error Text',
          description: 'Text that appears when a customer is hotlisted',
          type: 'string',
        },
        invalidProductErrorTitle: {
          title: 'Invalid Product Error Title',
          description: 'Title that appears when a media check has an invalid product',
          type: 'string',
        },
        invalidProductErrorText: {
          title: 'Invalid Product Error Text',
          description: 'Text that appears when a media check has an invalid product',
          type: 'string',
        },
      },
    },
  },
}

export default schema
