import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ContactTask from '../interfaces/ContactTask'
import Task from '../interfaces/Task'
import TaskService from '../services/TaskService'

@Component
export default class TaskBase extends Vue {
  @Prop({ required: true, default: null })
  contactTasks: ContactTask[] | any

  public async completeContactTask(contactTask: ContactTask, isSkipped: boolean = false) {
    try {
      if (!isSkipped) {
        await TaskService.CompleteContactTask(contactTask)
      }
      const newContactTask: ContactTask = {
        ...contactTask,
        isComplete: true,
        isSkipped,
        isReadOnly: true,
      }
      contactTask = newContactTask
    } catch (error) {
      throw error
    }
  }
}
