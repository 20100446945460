<template>
  <div id="admin-locations">
    <div id="location-list" class="mb-8" v-if="locations">
      <v-card class="pa-2">
        <v-card-title class="pt-2">Location List</v-card-title>
        <v-card-subtitle class="description">Click the box to view or edit location details.</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="locationsTableHeaders"
            :items="locations"
            :items-per-page="10"
            v-model="selectedLocations"
            show-select
            single-select
          >
            <template v-slot:item.id="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:item.name="{ item }"
              ><div class="location-link">
                <span>{{ item.name }}</span
                ><a :href="`/l/${item.id}`" title="View location" target="_blank"
                  ><v-icon color="blue" :title="`Open ${item.name}`" class="ml-2">mdi-open-in-new</v-icon></a
                >
              </div></template
            >
            <template v-slot:item.isEnabled="{ item }">
              <i class="fas fa-check" v-if="item.isEnabled"></i>
            </template>
            <template v-slot:item.isDefault="{ item }">
              <i class="fas fa-check" v-if="item.isDefault"></i>
            </template>
            <template v-slot:item.isEditProfileLocation="{ item }">
              <i class="fas fa-check" v-if="item.isEditProfileLocation"></i>
            </template>
            <template v-slot:item.usePosTransactionsAndOrders="{ item }">
              <i class="fas fa-check" v-if="item.usePosTransactionsAndOrders"></i>
            </template>
            <template v-slot:item.hasDefaultActivity="{ item }">
              <div class="status">
                <v-icon v-if="!item.hasDefaultActivity" color="red" title="Missing default activity">mdi-alert</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-card id="task-details" v-if="currentLocation" class="pa-2 mt-8 mb-8">
      <v-card-title class="pb-0 pt-2">{{ currentLocation.name }} Location</v-card-title>
      <v-container :fluid="true" class="pa-0">
        <v-row>
          <v-col md="6" class="pa-0">
            <v-card elevation="0" class="pa-0">
              <v-card-title>Location Details</v-card-title>
              <v-card-text>
                <v-form>
                  <v-jsf v-model="currentLocation" :schema="locationSchema" />
                </v-form>
                <h2>Default Activity</h2>
                <v-select
                  v-model="currentLocation.defaultActivityId"
                  :items="availableActivities"
                  item-text="name"
                  item-value="id"
                  label="Default Activity"
                />
              </v-card-text>
              <v-card-actions class="row">
                <v-layout align-content-space-between>
                  <v-btn color="primary" @click="updateLocation">
                    <v-icon>mdi-content-save</v-icon> Update Location
                  </v-btn>
                  <v-btn color="error" @click="removeLocation"> <v-icon>mdi-delete</v-icon> Delete Location </v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col md="6" class="pa-0">
            <v-card elevation="0" class="pa-0">
              <v-card-title>Assign Activities</v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th class="text-right">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="locationActivity in currentLocation.activityLocations" :key="locationActivity.id">
                        <td>{{ locationActivity.activity.name }}</td>
                        <td class="text-right">
                          <v-btn @click="removeLocationActivity(locationActivity.locationId, locationActivity.id)" text>
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions class="pa-4">
                <h3>Link Activities</h3>
                <v-select
                  v-model="locationActivity.activityId"
                  :items="activities"
                  item-text="name"
                  item-value="id"
                  label="Choose an Activity"
                />
                <v-btn class="add-btn" @click="addLocationActivity">Link Activity <v-icon>mdi-upload</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="add-location pa-2" v-else>
      <v-card-title class="pt-2">Add New Location</v-card-title>
      <v-card-subtitle>Complete the following information to add a new location.</v-card-subtitle>
      <v-card-text>
        <v-form>
          <v-jsf v-model="newLocation" :schema="locationSchema" />
        </v-form>
        <v-select
          v-model="newLocation.defaultActivityId"
          :items="availableActivities"
          item-text="name"
          item-value="id"
          label="Default Activity"
        />
      </v-card-text>

      <v-card-actions class="pl-4 pr-4 pb-4">
        <v-btn color="primary" @click="addLocation" large>Add Locations <v-icon>mdi-upload</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'

@Component
export default class AdminLocations extends Vue {
  @Action('location/fetchLocations')
  private fetchLocations: any
  @Getter('location/locations')
  private locations?: []

  @Action('activity/fetchActivities')
  private fetchActivities: any
  @Getter('activity/activities') activities

  private selectedLocations: [] = []
  private currentLocation: Location | null = null
  private currentLocationAvailableActivities: Activity[] = []
  private newLocationDefaultActivityname: string = ''

  @Watch('selectedLocations')
  onSelectedLocationsChanged(value: Location[] | null) {
    if (value && value.length > 0) {      
      const firstValue = value[0]
      this.currentLocation = {
        ...firstValue,
      }
    } else {
      this.currentLocation = null
    }
  }

  private locationSchema = {
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      isEnabled: {
        title: 'Is Enabled',
        type: 'boolean',
        'x-display': 'switch',
      },
      isDefault: {
        title: 'Is Default',
        type: 'boolean',
        'x-display': 'switch',
      },
      isEditProfileLocation: {
        title: 'Is Edit Profile Location',
        type: 'boolean',
        'x-display': 'switch',
      },
      usePosTransactionsAndOrders: {
        title: 'Use POS Transactions (RTP)',
        type: 'boolean',
        'x-display': 'switch',
      },
      activityLocations: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
      },
      defaultActivityId: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      checkInCompleteText: {
        title: 'Check In Complete Text',
        type: 'string',
        'x-display': 'textarea',
      },
    },
  }

  get availableActivities() {
    return this.activities
  }

  public newLocation: Location = {
    id: 0,
    name: '',
    description: '',
    defaultActivityId: 0,
    isEnabled: true,
    activityLocations: null,
    isDefault: false,
    usePosTransactionsAndOrders: false,
    checkInCompleteText: '',
    isEditProfileLocation: false,
  }

  public clearedLocation = { ...this.newLocation }

  public locationActivity: LocationActivity = {
    id: 0,
    activityId: 0,
    locationId: 0,
  }

  public created() {
    if (!this.locations) {
      this.getLocations()
    }

    if (!this.activities) {
      this.getActivities()
    }
  }

  public getActivities() {
    this.fetchActivities()
  }

  public async getLocations() {
    await this.fetchLocations()
    if (this.currentLocation && this.locations) {
      const thisLocation = this.locations.find((l: Location) => l.id == this.currentLocation!.id)
      if (thisLocation) {
        this.currentLocation = thisLocation
      }
    }
  }

  public viewLocationDetails(location: Location) {
    this.currentLocation = location
    this.currentLocationAvailableActivities = this.activities.filter((elem) => {
      if (location && location.activityLocations) {
        location.activityLocations.find(({ activityId }) => elem.id === activityId)
      }
    })
  }

  public async addLocation() {
    await LocationService.AddLocation(this.newLocation)
    this.getLocations()
    this.newLocation = { ...this.clearedLocation }
  }

  public async updateLocation() {
    if (this.currentLocation) {
      await LocationService.UpdateLocation(this.currentLocation)
      this.getLocations()
    }
  }

  public async addLocationActivity() {
    if (this.currentLocation) {
      this.locationActivity.locationId = this.currentLocation.id as number
      await LocationService.AddLocationActivity(this.locationActivity)
      this.getLocations()
    }
  }

  public async removeLocation() {
    if (this.currentLocation) {
      await LocationService.RemoveLocation(this.currentLocation.id)
      this.getLocations()
      this.selectedLocations = []
    }
  }

  public async removeLocationActivity(locationId: number, locationActivityId: number) {
    await LocationService.RemoveLocationActivity(locationId, locationActivityId)
    this.getLocations()
  }

  get locationsTableHeaders() {
    return [
      { text: 'Id', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Is Enabled', value: 'isEnabled', align: 'center', sortable: false },
      { text: 'Is Default', value: 'isDefault', align: 'center', sortable: false },
      { text: 'Is Edit Profile Location', value: 'isEditProfileLocation', align: 'center', sortable: false },
      { text: 'Use POS', value: 'usePosTransactionsAndOrders', align: 'center', sortable: false },
      { text: 'Modified Date', value: 'modifiedDate', align: 'center' },
      { text: 'Status', value: 'hasDefaultActivity', sortable: false, align: 'center' },
    ]
  }
}
</script>

<style lang="scss" scoped>
.v-btn + .v-btn {
  margin-left: 2rem;
}

.selected-row {
  background-color: khaki !important;
}

.add-btn {
  margin-left: 1rem;
}

.delete-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 12pt;
  font-weight: bold;
  color: white;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-color: red;
  border-color: red;
  margin-right: 10px;
}

.float-right {
  float: right;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

#activity-list {
  border-right: 4px solid navy;
}

.red {
  color: red;
}
.btn {
  cursor: pointer;
}

.float-container {
  border: 3px solid #fff;
  padding: 20px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  //text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.location-link {
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  text-decoration: none;
  .v-icon {
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
}

input[type='text'],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type='submit'] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type='submit']:hover {
  background-color: #45a049;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type='submit'] {
    width: 100%;
    margin-top: 0;
  }
}
</style>
