import { ActionTree } from 'vuex'
import Location from '../interfaces/location'
import LocationService from '@/Location/services/LocationService'
import { LocationState } from './types'
import { RootState } from '@/Common/store/types'
import moment from 'moment'

export const actions: ActionTree<LocationState, RootState> = {
  setLocationByName({ commit, state }, name: string): any {
    const locName = decodeURIComponent(name)
    const newLocation = state.locations?.filter(
      (l: any) => l.name.replace(' ', '-').toLowerCase() === locName.replace(' ', '-').toLowerCase()
    )
    if (newLocation && newLocation!.length > 0) {
      commit('setCurrentLocation', newLocation![0])
    } else if (state.locations) {
      const defaultLocation = state.locations?.filter((l: any) => l.isDefault === true)
      const editLocation = state.locations?.filter((l: any) => l.isEditProfileLocation === true)
      if (defaultLocation!.length > 0) {
        commit('setCurrentLocation', defaultLocation![0])
      }
      if (editLocation!.length > 0) {
        commit('setEditLocation', editLocation![0])
      }
    }
  },
  setLocationById({ commit, state }, id: number): any {
    const newLocation = state.locations?.filter((l: any) => l.id == id)
    const editLocation = state.locations?.filter((l: any) => l.isEditProfileLocation === true)
    if (newLocation) {
      commit('setCurrentLocation', newLocation[0])
    }
    if (editLocation!.length > 0) {
      commit('setEditLocation', editLocation![0])
    }
  },
  clearLocation({ commit, state }): any {
    localStorage.removeItem('location-key')
    localStorage.removeItem('location-name')
    commit('clearLocation')
  },
  async fetchLocations({ commit }): Promise<Location[] | null> {
    const locationsResponse = await LocationService.FetchLocations()
    const allLocations = locationsResponse || null
    if (allLocations) {
      const modifiedLocations = allLocations.map((location) => {
        return {
          ...location,
          modifiedDate: moment(location.modifiedDateUtc).format('MM/DD/YY hh:MM a'),
          hasDefaultActivity: location.defaultActivityId && location.defaultActivityId > 0 ? true : false,
        }
      })
      commit('setLocations', modifiedLocations)
    }

    return allLocations
  },
  async fetchCurrentLocation({ commit, getters, dispatch }): Promise<Location | null> {
    if (!getters.currentLocation) {
      const locations = getters.locations || (await dispatch('fetchLocations'))
      const storedKey = localStorage.getItem('location-key')
      if (storedKey) {
        const currentLocation = locations.find((l) => l.id == parseInt(storedKey, 10))
        if (currentLocation) {
          commit('setCurrentLocation', currentLocation)
          return currentLocation
        }
      } else {
        const defaultLocation = locations.filter((l) => l.isDefault == true)[0]
        if (defaultLocation) {
          commit('setCurrentLocation', defaultLocation)
          return defaultLocation
        }
      }
    }
    return null
  },
  async fetchEditLocation({ commit, getters, dispatch }): Promise<Location | null> {
    if (!getters.editLocation) {
      const locations = getters.locations || (await dispatch('fetchLocations'))
      const storedKey = localStorage.getItem('location-key')
      const editLocation = locations.filter((l) => l.isEditProfileLocation == true)[0]
      if (editLocation) {
        commit('setEditLocation', editLocation)
        return editLocation
      }
    }
    return null
  },
  async fetchDefaultLocation({ commit, getters, dispatch }): Promise<Location | null> {
    if (!getters.editLocation) {
      const locations = getters.locations || (await dispatch('fetchLocations'))
      const storedKey = localStorage.getItem('location-key')
      const defaultLocation = locations.filter((l) => l.isDefault == true)[0]
      if (defaultLocation) {
        commit('setDefaultLocation', defaultLocation)
        return defaultLocation
      }
    }
    return null
  },
}
